import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { keyframes } from 'styled-components'
// import uiActions from '../../features/ui/actions'
import { selectVideoStatus } from '../../features/displayContent/selectors'
import { pxToRem } from '../../libs/style'
import { AnyPrescriptionType } from '../../model/prescription'
import theme from '../../styleguide/theme'
import { scrollIntoView } from '../../libs/utils'

import {
	EyeTableBox,
	FirstLine,
	SecondLine,
	TitleWrapper,
	Wrapper,
} from './CommonComponents'
import { InstrumentRemoteMedia } from '../../model/model'
import {
	selectCoreMediaRxAddons,
	selectRxTypeTitle,
} from '../../features/coremedia/selectors'
import { ConnectRxAddon } from '../../model/coremedia'

const videoTransitionTime = theme.transitions.duration.complex * 3
const videoAnimationTime = 10

const clearLens = '/assets/images/Clear.webp'
const blueLens = '/assets/images/Sun.webp'

const StyledTitleWrapper = styled(TitleWrapper)`
	flex: 0;
`

const ImageWrapper = styled.div`
	position: relative;
	flex: 1;
	max-width: 80vw;
	display: flex;
	justify-content: center;
	align-items: center;
`

const Image = styled.img`
	height: min(40vw, 40vh);
	width: min(40vw, 40vh);
`

const BottomSection = styled.div`
	flex: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
	min-height: 28vh;
	max-height: 35vh;
`

const StyledWrapper = styled(Wrapper)`
	flex-wrap: wrap;
	& > div {
		max-width: 100%;
	}
`

const LensTypeWrapper = styled.div<{ fullWidth: boolean }>`
	margin: ${pxToRem(9)}rem ${pxToRem(12)}rem 0 0;
	padding: ${pxToRem(theme.spacing(2))}rem;
	border-radius: ${pxToRem(theme.spacing(2))}rem;
	backdrop-filter: blur(${pxToRem(6)}rem);
	border: solid 1px rgba(255, 255, 255, 0.4);
	background-image: linear-gradient(
		to bottom,
		rgba(238, 232, 227, 0.3),
		rgba(97, 164, 255, 0.2) 106%
	);
	flex: ${props => (props.fullWidth ? 1 : 0.65)};
	max-width: ${props =>
		props.fullWidth
			? pxToRem(window.innerWidth * 0.7)
			: pxToRem(window.innerWidth * 0.35)}rem;
	min-height: ${pxToRem(150)}rem;
	&.active {
		backdrop-filter: none;
		.selected {
			backdrop-filter: blur(${pxToRem(15)}rem);
			z-index: 2;
		}
	}

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		max-width: 65%;
	}
`

const UseInstructionsWrapper = styled(EyeTableBox)`
	flex: 1;
	max-width: 35%;
	min-height: ${pxToRem(144)}rem;
`

const BottomTitle = styled.div`
	text-transform: uppercase;
	color: ${theme.palette.secondary.light};
`

const LensTypePreviews = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow-x: auto;
	& {
		scrollbar-width: thin; /* Firefox */
	}
	&::-webkit-scrollbar {
		width: ${pxToRem(4)}rem; /* width of the entire scrollbar */
		height: ${pxToRem(4)}rem;
	}

	&::-webkit-scrollbar-track {
		background: ${theme.palette.secondary
		.light}; /* color of the tracking area */
		border-radius: ${pxToRem(20)}rem;
	}

	&::-webkit-scrollbar-thumb {
		background-color: ${theme.palette.secondary
		.main}; /* color of the scroll thumb */
		border-radius: ${pxToRem(20)}rem; /* roundness of the scroll thumb */
		border-color: transparent; /* creates padding around scroll thumb */
	}
`

const UseInstructions = styled.ul`
	li::marker {
		color: ${theme.palette.secondary.light};
	}
	padding: 0 ${pxToRem(theme.spacing(2))}rem;
	padding-top: ${pxToRem(theme.spacing(2))}rem;
	font-size: ${theme.typography.fontSizes.S};
	color: ${theme.palette.common.white};
`

const VideoPreviewWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${pxToRem(theme.spacing(1))}rem;
	font-size: ${theme.typography.fontSizes.S};
`

const EmptyWrapper = styled(VideoPreviewWrapper)``

const EmptyBox = styled.div`
	background-color: ${theme.palette.secondary.main};
	flex: 1;
	width: ${pxToRem(theme.spacing(25))}rem;
	min-height: ${pxToRem(110)}rem;
	color: ${theme.palette.common.white};
	text-transform: uppercase;
	border-radius: ${pxToRem(theme.spacing(2))}rem;
	margin-bottom: ${pxToRem(theme.spacing(1))}rem;
	display: flex;
	justify-content: center;
	align-items: center;
`

const VideoBox = styled.video`
	max-height: 100%;
	width: ${pxToRem(theme.spacing(25))}rem;
	border-radius: ${pxToRem(theme.spacing(2))}rem;
	margin-bottom: ${pxToRem(theme.spacing(2))}rem;
`
const getBadgeKeyFrames = ({ top, right }: { top: string; right: string }) => {
	return keyframes`
    0%{opacity:0;transform:scale(0); top: calc(50% - ${pxToRem(
		theme.spacing(8) / 2,
	)}rem); right: calc(50% - ${pxToRem(theme.spacing(8) / 2)}rem)}
    70%{transform:scale(1.2)}
    100%{opacity:1;transform:scale(1); top: ${top}; right: ${right};}
   `
}

const Badge = styled.div<{ top: string; right: string }>`
	border: solid 1px rgba(255, 255, 255, 0.4);
	background-color: rgba(255, 255, 255, 0.4);
	padding: ${pxToRem(theme.spacing(1))}rem;
	border-radius: 50%;
	width: ${pxToRem(theme.spacing(8))}rem;
	height: ${pxToRem(theme.spacing(8))}rem;
	position: absolute;
	transition: top ${theme.transitions.duration.standard}ms,
		right ${theme.transitions.duration.standard}ms;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	animation: ${props => getBadgeKeyFrames(props)};
	animation-duration: ${theme.transitions.duration.complex}ms;
	animation-fill-mode: forwards;
	&.selected {
		z-index: 2;
		& > div {
			z-index: 2;
		}
	}
`

const BlurredBg = styled.div<{ active: boolean }>`
	${props =>
		props.active
			? `
    z-index:1;
    opacity: 1;
`
			: `
z-index: -1;
opacity: 0;
`}
	transition: opacity 50ms;
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 100%;
	backdrop-filter: blur(${pxToRem(15)}rem);
`

const BadgeText = styled.div<{ anchorRight: boolean }>`
	position: absolute;
	${props =>
		props.anchorRight
			? `
left: 0;
transform: translateX( calc(-100% - ${pxToRem(theme.spacing(1.65))}rem ));
`
			: `
  right: 0;
  transform: translateX( calc(100% + ${pxToRem(theme.spacing(1.65))}rem ));
`}
	white-space: nowrap;
	font-size: ${theme.typography.fontSizes.L};
	text-decoration: 2px ${theme.palette.secondary.light} solid underline;
	text-underline-position: under;
`

const InnerCircle = styled.div`
	background-color: ${theme.palette.secondary.main};
	border-radius: 50%;
	padding: ${pxToRem(theme.spacing(0.5))}rem;
	img {
		height: 100%;
		width: 100%;
	}
`

const ImageAndBadgeWrapper = styled.div`
	position: absolute;
	height: min(40vw, 40vh);
	width: min(40vw, 40vh);
	display: flex;
	align-items: center;
	justify-content: center;
	//this preserves the animation and the relative position of the badges
	${theme.breakpoints.down(1400)} {
		zoom: 0.9;
		${theme.breakpoints.down(1200)} {
			zoom: 0.75;
		}
	}
`

const mapLensReccomendationToAsset = (assetName: string) => {
	const base = '/assets/images/badges/'
	switch (assetName) {
		case 'HighIndex':
			return base + 'i-pad-elements-ui-misc-icon-high-index@3x.webp'
		case 'Polycarbonate':
			return base + 'i-pad-elements-ui-misc-icon-polycarbonate@3x.webp'
		case 'AntiReflective':
			return base + 'i-pad-elements-ui-misc-icon-anti-reflective@3x.webp'
		case 'Photochromic':
			return base + 'i-pad-elements-ui-misc-icon-photochromic@3x.webp'
		case 'BlueLight':
			return base + 'i-pad-elements-ui-misc-icon-blue-light@3x.webp'
		case 'Polarized':
			return base + 'i-pad-elements-ui-misc-icon-polarized@3x.webp'
		case 'BacksideAntiReflective':
			return (
				base + 'i-pad-elements-ui-misc-icon-backside-anti-reflective@3x.webp'
			)
		case 'ImpactResistant':
			return base + 'impact-resistant.svg'
	}
}

const VideoContainer = styled.div`
	position: fixed;
	&.visible {
		transition: left ${videoAnimationTime}ms ease-in ${videoTransitionTime}ms,
			right ${videoAnimationTime}ms ease-in ${videoTransitionTime}ms,
			bottom ${videoAnimationTime}ms ease-in ${videoTransitionTime}ms,
			top ${videoAnimationTime}ms ease-in ${videoTransitionTime}ms,
			opacity ${videoAnimationTime}ms ease-in ${videoTransitionTime}ms;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
	}
	&.invisible {
		z-index: 0;
		top: 50vh;
		left: 50vw;
		right: 50vw;
		bottom: 50vh;
		opacity: 0;
	}

	background-color: ${theme.palette.common.black};
`

const FullScreenVideo = styled.video`
	width: 100%;
	height: 100%;
`

const VideoDetail = styled.div`
	background-color: ${theme.palette.common.white};
	border-radius: ${pxToRem(theme.spacing(3))}rem;
	margin: ${pxToRem(theme.spacing(3))}rem;
	min-width: 15vw;
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
`

const VideoIcon = styled.div`
	width: ${pxToRem(theme.spacing(6))}rem;
	height: ${pxToRem(theme.spacing(6))}rem;
`

const VideoTitleWrapper = styled.div`
	padding: ${pxToRem(theme.spacing(2))}rem;
	display: flex;
	flex-direction: column;
	background-color: ${theme.palette.grey[200]};
`
const VideoSubtitleWrapper = styled.div`
	padding: ${pxToRem(theme.spacing(2))}rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	& > div {
		margin-right: ${pxToRem(theme.spacing(4))}rem;
	}
	& > span {
		text-transform: uppercase;
		color: ${theme.palette.secondary.light};
		font-weight: ${theme.typography.fontWeightMedium};
	}
`

const VideoPrescriptionType = styled.span`
	font-family: 'Ivar Display';
	font-style: italic;
	font-size: ${theme.typography.fontSizes.XXL};
	padding-top: ${pxToRem(theme.spacing(2))}rem;
`

const VideoType = styled.span``

const VideoWrapper: React.FC<{
	media?: InstrumentRemoteMedia
	rxTypeTitle: string
	visible: boolean
}> = ({ media, rxTypeTitle, visible }) => {
	const { t } = useTranslation()
	const videoStatus = useSelector(selectVideoStatus)
	const videoRef = useRef<HTMLVideoElement>(null)
	const playing = videoStatus?.playing
	const currentTime = videoStatus?.currentTime
	const startTime = videoStatus?.startTime
	const endTime = videoStatus?.endTime
	useEffect(() => {
		if (!videoRef.current) {
			return
		}

		if (currentTime !== undefined) {
			videoRef.current.currentTime = currentTime
		}
		if (playing) {
			videoRef.current.play()
		} else {
			videoRef.current.pause()
		}
	}, [playing, currentTime])

	const loopTimeVideo = (currentTime: number) => {
		const video = videoRef.current
		if (!video) {
			return
		}
		const { duration } = video
		const shouldVideoLoop =
			(!!endTime && currentTime > endTime) ||
			(!!startTime && currentTime >= duration)

		if (shouldVideoLoop) {
			resetVideoTimeToStartTime()
		}
	}

	const resetVideoTimeToStartTime = () => {
		const video = videoRef.current!
		video.pause()
		video.currentTime = startTime || 0
		setTimeout(() => {
			video.play()
		}, 500)
	}

	const category = media?.note?.includes('LensType')
		? { label: 'Lens Type' }
		: { label: 'Lens Recommendation' }

	return (
		<VideoContainer className={visible ? 'visible' : 'invisible'}>
			<VideoDetail>
				<VideoTitleWrapper>
					<VideoType>
						{category
							? category.label === 'Lens Type'
								? t('app.lensType')
								: t('app.lensRecommendationType')
							: null}
					</VideoType>
					<VideoPrescriptionType>{rxTypeTitle}</VideoPrescriptionType>
				</VideoTitleWrapper>
				<VideoSubtitleWrapper>
					{!category ||
						!media?.name ||
						category?.label === 'Lens Type' ? null : (
						<VideoIcon>
							<InnerCircle>
								<img
									src={mapLensReccomendationToAsset(media.name)}
									alt={t('content.lensOtherData.' + media.name)}
								/>
							</InnerCircle>
						</VideoIcon>
					)}
					<span>
						{media?.name ? t('content.lensOtherData.' + media.name) : null}
					</span>
				</VideoSubtitleWrapper>
			</VideoDetail>
			{media && media.path ? (
				<FullScreenVideo
					onTimeUpdate={ev => loopTimeVideo(ev.currentTarget.currentTime)}
					src={media?.path}
					ref={videoRef}
					muted
				/>
			) : null}
		</VideoContainer>
	)
}

const RenderLensTypeItem: React.FC<{
	field: string
	assets?: ConnectRxAddon
	className: string
	id?: string
}> = ({ field, assets, className, id }) => {
	const fieldAsset = assets && assets[field].media.videos[0]
	const { t } = useTranslation()

	if (!fieldAsset) {
		return (
			<EmptyWrapper id={id} className={className}>
				<EmptyBox>
					<span>{t('content.noMedia')}</span>
				</EmptyBox>{' '}
				<div>{t('content.lensOtherData.' + field)}</div>
			</EmptyWrapper>
		)
	} else {
		return (
			<VideoPreviewWrapper id={id} className={className} key={field}>
				<VideoBox src={fieldAsset} />
				<div>{t('content.lensOtherData.' + field)}</div>
			</VideoPreviewWrapper>
		)
	}
}

const positioningMap: {
	[key: number]: { top: string; right: string; anchorRight: boolean }[]
} = {
	1: [{ top: 'min(12vw, 12vh)', right: 'min(34vw, 34vh)', anchorRight: true }],
	2: [
		{ top: 'min(11vw, 11vh)', right: 'min(35vw, 35vh)', anchorRight: true },
		{ top: 'min(30vw, 30vh)', right: 'min(1vw, 1vh)', anchorRight: false },
	],
	3: [
		{ top: 'min(10vw, 10vh)', right: 'min(34vw, 34vh)', anchorRight: true },
		{ top: 'min(35vw, 35vh)', right: 'min(4vw, 4vh)', anchorRight: false },
		{ top: 'min(4vw, 4vh)', right: 'min(4vw,4vh)', anchorRight: false },
	],
	4: [
		{ top: 'min(5vw, 5vh)', right: 'min(35vw, 35vh)', anchorRight: true },
		{ top: 'min(25vw, 25vh)', right: 'min(27vw,27vh)', anchorRight: true },
		{ top: 'min(8vw,8vh)', right: 'min(0vw, 0vh)', anchorRight: false },
		{ top: 'min(33vw,33vh)', right: 'min(3vw,3vh)', anchorRight: false },
	],
	5: [
		{ top: 'min(-1vw, -1vh)', right: 'min(32vw, 32vh)', anchorRight: true },
		{ top: 'min(18vw, 18vh)', right: 'min(30vw, 30vh)', anchorRight: true },
		{ top: 'min(2vw,2vh)', right: 'min(7vw, 7vh)', anchorRight: false },
		{ top: 'min(19vw,19vh)', right: 'min(-1.5vw,-1.5vh)', anchorRight: false },
		{ top: 'min(36vw,36vh)', right: 'min(11vw,11vh)', anchorRight: false },
	],
	6: [
		{ top: 'min(-1vw, -1vh)', right: 'min(32vw, 32vh)', anchorRight: true },
		{ top: 'min(14.5vw, 14.5vh)', right: 'min(33vw,33vh)', anchorRight: true },
		{ top: 'min(2vw,2vh)', right: 'min(7vw, 7vh)', anchorRight: false },
		{ top: 'min(16vw,16vh)', right: 'min(-1.5vw,-1.5vh)', anchorRight: false },
		{ top: 'min(28vw,28vh)', right: 'min(25vw,25vh)', anchorRight: true },
		{ top: 'min(34vw,34vh)', right: 'min(4vw,4vh)', anchorRight: false },
	],
}

const PrescriptionOtherData: React.FC<{
	prescriptionType: string
	data: AnyPrescriptionType
}> = ({ prescriptionType, data }) => {
	const [pendingScroll, setPendingScroll] = useState<boolean>(false)
	const scrollRef = useRef<any>()
	const [video, setVideo] = useState<InstrumentRemoteMedia | undefined>()
	const content = useSelector(selectCoreMediaRxAddons)
	const { t } = useTranslation()
	const currentVideo = data?.both
	const rxTypeTitle = useSelector(selectRxTypeTitle(prescriptionType))

	const lensTypesAssets = content[prescriptionType].types['LensType'].content

	let useInstructions: [string, boolean][] = []
	try {
		useInstructions = Object.entries(data.UseInstructions).filter(
			([, value]) => value,
		)
	} catch (error) {
		useInstructions = []
		console.error(error)
	}

	const lensReccomendationBadges = Object.entries(
		data.LensRecommendations,
	).filter(([, value]) => value)

	const lensTypes = Object.entries(data.LensType).filter(([, value]) => value)

	const positioning = positioningMap[lensReccomendationBadges.length]

	const sharingTypesActive = !!lensTypes.find(([key]) => video?.name === key)

	useEffect(() => {
		if (!pendingScroll) {
			if (!currentVideo && video) {
				setVideo(undefined)
			} else if (currentVideo && currentVideo.name !== video?.name) {
				if (
					lensTypes.find(([key, value]) => currentVideo.name === key && value)
				) {
					setPendingScroll(true)

					if (video) {
						setVideo(currentVideo)
						scrollIntoView(
							scrollRef.current,
							document.getElementById(currentVideo.name),
						) // no need to wait for it since it is in the background and covered by the video already in display
						setPendingScroll(false)
					}

					scrollIntoView(
						scrollRef.current,
						document.getElementById(currentVideo.name),
					).then(() => {
						setVideo(currentVideo)
						setPendingScroll(false)
					})
				} else {
					setVideo(currentVideo)
				}
			}
		}
	}, [currentVideo, video, lensTypes, pendingScroll])

	if (!data) {
		return null
	}

	const showBlueLens = ['Sport', 'Sunglasses', 'Computer'].includes(
		prescriptionType,
	)

	return (
		<StyledWrapper>
			<StyledTitleWrapper>
				<FirstLine>{t('app.lensRecommendationType')}</FirstLine>
				<SecondLine>{rxTypeTitle}</SecondLine>
			</StyledTitleWrapper>
			<ImageWrapper>
				<ImageAndBadgeWrapper>
					<Image src={showBlueLens ? blueLens : clearLens} />
					{lensReccomendationBadges.map(([key], index) => (
						<Badge
							key={key}
							className={
								key + ' ' + (video?.name === key ? 'selected' : 'unselected')
							}
							top={positioning[index].top}
							right={positioning[index].right}
						>
							<InnerCircle>
								<img
									src={mapLensReccomendationToAsset(key)}
									alt={t('content.lensOtherData.' + key)}
								/>
							</InnerCircle>
							<BadgeText anchorRight={positioning[index].anchorRight}>
								{t('content.lensOtherData.' + key)}
							</BadgeText>
						</Badge>
					))}
				</ImageAndBadgeWrapper>
			</ImageWrapper>
			<BottomSection>
				{lensTypes.length ? (
					<LensTypeWrapper
						className={sharingTypesActive ? 'active' : ''}
						fullWidth={!useInstructions.length}
					>
						<BottomTitle>{t('app.lensType')}</BottomTitle>
						<LensTypePreviews ref={scrollRef}>
							{lensTypes.map(([key]) => (
								<RenderLensTypeItem
									id={key}
									className={video?.name === key ? 'selected' : ''}
									field={key}
									assets={lensTypesAssets}
								/>
							))}
						</LensTypePreviews>
					</LensTypeWrapper>
				) : (
					<div></div>
				)}
				{useInstructions.length ? (
					<UseInstructionsWrapper>
						<BottomTitle>{t('app.useInstructions')}</BottomTitle>
						<UseInstructions>
							{useInstructions.map(([key]) => (
								<li key={key}>{t('content.lensOtherData.' + key)}</li>
							))}
						</UseInstructions>
					</UseInstructionsWrapper>
				) : (
					<div></div>
				)}
			</BottomSection>
			<VideoWrapper visible={!!video} rxTypeTitle={rxTypeTitle} media={video} />
			<BlurredBg active={!!video} />
		</StyledWrapper>
	)
}

export default PrescriptionOtherData
