import config from '../config'

export const getAssetSrc = (path?: string, data?: string): string => {
	if (path) {
		// for testing purposes
		if (path.includes('placeholder.com')) {
			return path
		}
		const segments = path.split('/')
		const name = segments[segments.length - 1]

		return `${config.mediaUrl}/assets/${name}`
	} else {
		return `data:image/png;base64,${data}`
	}
}

const scrollDuration = 500

const waitFor = (time: number) => {
	return new Promise<void>(res => {
		setTimeout(() => {
			res()
		}, time)
	})
}

// This is the main function where which pass two ref parameters of Parent element & Child element
export const scrollIntoView = async (
	parent: HTMLElement | null,
	child: HTMLElement | null,
) => {
	if (!parent || !child) {
		return
	}

	const parentBounding = parent.getBoundingClientRect(),
		clientBounding = child.getBoundingClientRect()

	const parentRight = parentBounding.right,
		parentLeft = parentBounding.left,
		clientRight = clientBounding.right,
		clientLeft = clientBounding.left

	if (parentLeft >= clientLeft) {
		await scrollTo(parent, -(parentLeft - clientLeft), scrollDuration)
		await waitFor(200)
	} else if (clientRight > parentRight) {
		await scrollTo(parent, clientRight - parentRight, scrollDuration)
		await waitFor(200)
	}
}

// Function for smooth scroll animation with the time duration
export function easeInOutQuad(
	time: number,
	startPos: number,
	endPos: number,
	duration: number,
) {
	time /= duration / 2

	if (time < 1) {
		return (endPos / 2) * time * time + startPos
	} else {
		time--
		return (-endPos / 2) * (time * (time - 2) - 1) + startPos
	}
}

export async function scrollTo(
	element: HTMLElement,
	to: number,
	duration: number,
) {
	const start = element.scrollLeft
	let currentTime = 0
	const increment = 15

	const animateScroll = async function () {
		currentTime += increment

		const val = easeInOutQuad(currentTime, start, to, duration)
		element.scrollLeft = val

		if (currentTime < duration) {
			await waitFor(increment)
			await animateScroll()
		}
	}

	return await animateScroll()
}

type PlusSignedValueArgs = {
	value?: string
	defaultValue?: string
	omitPlusSign?: boolean
	applyDecimalValues?: boolean
}

export const returnPlusSignedValue = ({
	value,
	defaultValue,
	omitPlusSign = false,
	applyDecimalValues = true,
}: PlusSignedValueArgs) => {
	if (value === '') {
		return ''
	}

	if (!value) {
		return defaultValue || ''
	}

	const fixedValue = parseFloat(value)

	if (Number.isNaN(fixedValue)) {
		return defaultValue || value
	}

	if (
		fixedValue === 0 &&
		defaultValue !== undefined &&
		defaultValue.length > 0
	) {
		return defaultValue
	}

	const formattedValue =
		applyDecimalValues === true ? fixedValue.toFixed(2) : fixedValue

	if (fixedValue < 0) {
		return formattedValue
	}

	if (omitPlusSign === true) {
		return formattedValue
	}

	return `+${formattedValue}`
}

export const returnFormattedValueForAxis = (value: string): string => {
	const num = Number(value)
	// format 0 value
	if (String(num).padStart(3, '0') === '000') {
		return ''
	}
	// format other values
	if (!num) {
		return value
	} else {
		return String(num).padStart(3, '0')
	}
}
