import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import appConfig from '../config'
import { pxToRem } from '../libs/style'
import Button from '../styleguide/buttons/Button'
import theme from '../styleguide/theme'
import SettingsMenu from './SettingsMenu'
import { useCall } from '@azure/communication-react'
import VideoCallState from './VideoCallState'

const StyledHeader = styled.header`
	padding-bottom: ${pxToRem(theme.spacing(2))}rem;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: calc(100% - ${pxToRem(theme.spacing(theme.shape.pageMargin)) * 2}rem);
	position: absolute;
	z-index: 1501;
`

const Logo = styled.img<{ visionx: boolean }>`
	width: ${props =>
		props.visionx === true ? `${pxToRem(100)}rem` : `${pxToRem(270)}rem`};
	height: auto;
	display: block;
	grid-column: 2;
	place-self: center center;
`

const RightCol = styled.div`
	align-items: center;
	grid-column: 3;
	place-self: center end;
	display: grid;
	gap: ${pxToRem(theme.spacing(2))}rem;
	grid-template-columns: auto auto;
	place-content: center end;
`

const SettingsButtonAndMenu = styled.div`
	position: relative;
	grid-column: 3;
	place-self: center end;
	font-size: 1.8vh;
`

const SettingsButton = styled(Button)``

type Props = {
	logoSrc?: string
	showLogo?: boolean
}

const Header: React.FC<Props> = ({
	logoSrc = appConfig.region === 'GEMINI'
		? '/assets/visionx_logo.svg'
		: '/assets/Logo_Connect_Black.svg',
	showLogo = true,
}) => {
	const { t } = useTranslation()
	const [settingsMenuOpen, setSettingsMenuOpen] = React.useState(false)
	const call = useCall()

	return (
		<StyledHeader>
			{showLogo && (
				<Logo
					alt="Luxottica Patient Consultation logo"
					src={logoSrc}
					visionx={appConfig.region === 'GEMINI'}
				/>
			)}
			<RightCol>
				<VideoCallState />

				<SettingsButtonAndMenu>
					<>
						<SettingsButton
							disabled={false}
							data-testid="header-settings-button"
							variant="text"
							onClick={() => setSettingsMenuOpen(prev => !prev)}
						>
							{t('settings.settings')}
						</SettingsButton>
						<SettingsMenu
							open={settingsMenuOpen}
							callActive={Boolean(call?.state)}
							setSettingsMenuOpen={setSettingsMenuOpen}
						/>
					</>
				</SettingsButtonAndMenu>
			</RightCol>
		</StyledHeader>
	)
}

export default Header
