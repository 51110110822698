import {
	CallEndReason,
	RemoteParticipant,
	RemoteVideoStream,
} from '@azure/communication-calling'
import React from 'react'
import { logAzureCallApi } from '../../apiCalls'
import { AzureLogger as AzureLoggerModel } from '../../model/azureCommunication'
import { Id } from '../../model/model'
import {
	CallEndReasonCode,
	CALL_END_REASON_CODES,
} from './utils/callEndReasonCodes'

export const isIOS =
	(/iPad|iPhone|iPod/.test(navigator.platform) ||
		(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
	!window.MSStream

export const isSafari = /^((?!chrome|android).)*safari/i.test(
	navigator.userAgent,
)

export type CALL_AGENT_INIT_STATE = 'IDLE' | 'START' | 'END' | 'ERROR'
export type ParticipantStreamTuple = {
	stream: RemoteVideoStream
	participant: RemoteParticipant
	streamRendererComponentRef: React.RefObject<HTMLDivElement>
}

type LogArgs = {
	action: string
	callId?: string
	displayName: string
	examId?: Id
	error?: Error
	store?: string
}

type DysplayCallEndReasonArgs = {
	callEndReason: CallEndReason
	setCallKnownError: (error?: CallEndReasonCode) => void
}

export const logAzure = ({
	callId,
	examId,
	displayName,
	action,
	error,
}: LogArgs) => {
	const log: AzureLoggerModel = {
		callId: callId || '',
		examId: examId || '',
		username: displayName,
		userAgent: navigator.userAgent,
		action,
		log: window.acsLogBuffer || [],
		error: error ? error.message : '',
		platform: 'cockpit',
	}

	logAzureCallApi(log).then(() => {
		window.acsLogBuffer = []
	})
}

export const displayCallEndReason = ({
	callEndReason,
	setCallKnownError,
}: DysplayCallEndReasonArgs) => {
	if (callEndReason.code !== 0 || callEndReason.subCode !== 0) {
		const issue = CALL_END_REASON_CODES.find(e => e.code === callEndReason.code)
		if (issue) {
			setCallKnownError(issue)
		}
	}
}
