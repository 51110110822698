import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { delIntersessionStoreIdAndRoomId, getIntersessionStoreIdAndRoomId, setIntersessionStoreIdAndRoomId } from '../../libs/intersession'
import { SettingsState } from '../../model/settings'

const intersessionData = getIntersessionStoreIdAndRoomId()
const initialState: SettingsState = {
  selectedStoreId: intersessionData?.storeId || '',
  selectedRoomId: intersessionData?.roomId || '',
  language: 'en-US',
}

export const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setStoreAndRoomId: (
      state,
      { payload }: PayloadAction<{ storeId: string, roomId: string }>,
    ) => {
      state.selectedStoreId = payload.storeId
      state.selectedRoomId = payload.roomId
      setIntersessionStoreIdAndRoomId(payload.storeId, payload.roomId)
    },
    setStoreId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.selectedStoreId = payload
    },
    resetRoomAndStoreId: (state) => {
      delIntersessionStoreIdAndRoomId()
      state.selectedStoreId = ''
      state.selectedRoomId = ''
    },
  },
})

export default slice.reducer
