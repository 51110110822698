const PX_TO_REM_RATIO = 16

export const pxToRem = (px: number): number => px / PX_TO_REM_RATIO

export const hexToRgba = (hex: string, alpha: number) => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
	if (result) {
		const rgb = {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
		}
		const res = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`
		return res
	}

	return null
}
