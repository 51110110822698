import styled from 'styled-components'
import { EyePanel } from './EyePanel'
import { ClinicalContentSummaryData } from './types'
import { pxToRem } from '../../../libs/style'

const EyesRoot = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${pxToRem(15)}rem;
	justify-content: center;
	align-items: flex-end;
`

type ClinicalContentSummaryProps = {
	data: ClinicalContentSummaryData
}

export function ClinicalContentSummary({
	data: { OD: od, OS: os },
}: ClinicalContentSummaryProps): JSX.Element {
	return (
		<EyesRoot>
			<EyePanel
				eye="OS"
				add={os.add}
				axis={os.axis}
				cylinder={os.cylinder}
				hPrism={os.hPrism}
				hOrient={os.hOrient}
				vPrism={os.vPrism}
				vOrient={os.vOrient}
				sphere={os.sphere}
				retinalImagingImgUrl={os.retinalImagingImgUrl}
				slitLampImgUrl={os.slitLampImgUrl}
				topographyImgUrl={os.topographyImgUrl}
			/>
			<EyePanel
				eye="OD"
				add={od.add}
				axis={od.axis}
				cylinder={od.cylinder}
				hPrism={od.hPrism}
				hOrient={od.hOrient}
				vPrism={od.vPrism}
				vOrient={od.vOrient}
				sphere={od.sphere}
				retinalImagingImgUrl={od.retinalImagingImgUrl}
				slitLampImgUrl={od.slitLampImgUrl}
				topographyImgUrl={od.topographyImgUrl}
			/>
		</EyesRoot>
	)
}
