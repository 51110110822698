import { memo, SVGProps } from 'react'

const GraphCardClipPath = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" {...props}>
      <clipPath id="clip" clipPathUnits="objectBoundingBox">
        <path d="M 0 0 L 0.900 0 C 0.915 0 0.925 0.011 0.928 0.014 L 0.984 0.072 C 0.987 0.075 0.998 0.085 0.998 0.100 L 1 1 L 0 1 z"></path>
      </clipPath>
    </svg>
  )
}

export default memo(GraphCardClipPath)