import { AddNotificationPayload } from '../../model/model'
import {
	AddNotificationFn,
	FindNotificationFn,
	RemoveNotificationFn,
} from '../teloSocketTypes'

const NOTIFICATION_ID = 'realtimeConnectionError'
const MAX_DONWTIME_MS = 20_000
const TIMEOUT_ERROR_NOTIFICATION: AddNotificationPayload = {
	id: NOTIFICATION_ID,
	type: 'error',
	message: 'errors.realtimeConnectionError',
	autoClose: false,
}

let connectionTimeoutHandle = 0

type OnConnectErrorArgs = {
	findNotification: FindNotificationFn
	addNotification: AddNotificationFn
	isSocketConnected: () => boolean
}

export const onConnectError = ({
	findNotification,
	addNotification,
	isSocketConnected,
}: OnConnectErrorArgs): void => {
	const errorNotificationPresent = findNotification(NOTIFICATION_ID)
	if (!connectionTimeoutHandle && !errorNotificationPresent) {
		connectionTimeoutHandle = window.setTimeout(() => {
			if (!isSocketConnected()) {
				addNotification(TIMEOUT_ERROR_NOTIFICATION)
			}
			connectionTimeoutHandle = 0
		}, MAX_DONWTIME_MS)
	}
}

export const onConnect = (removeNotification: RemoveNotificationFn): void => {
	clearTimeout(connectionTimeoutHandle)
	removeNotification(NOTIFICATION_ID)
	connectionTimeoutHandle = 0
}
