import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { pxToRem } from '../libs/style'
import Button from './buttons/Button'
import theme from './theme'

const Backdrop = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	transition: opacity ${theme.transitions.duration.standard}ms;
	pointer-events: none;
	visibility: hidden;
	overflow: hidden;

	&.is-open {
		opacity: 1;
		pointer-events: initial;
		width: 100%;
		height: 100%;
		visibility: visible;
	}
`

const Box = styled.div`
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	background-color: ${theme.palette.background.default};
	padding: ${pxToRem(theme.spacing(2))}rem ${pxToRem(theme.spacing(3))}rem;
`

const Title = styled.h2`
	color: ${theme.palette.secondary.light};
	text-transform: uppercase;
	font-size: ${theme.typography.fontSizes.S};
	font-weight: bold;
`

const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: ${pxToRem(theme.spacing(4))}rem;
`

type Props = {
	open: boolean
	Content: JSX.Element
	title?: string
	cancelLabel?: string
	confirmLabel?: string
	onConfirm?: () => void
	onClose?: () => void
}

const createHostElement = () => {
	const el = document.createElement('div')
	el.dataset.testid = 'modal'
	return el
}

const Modal: React.FC<Props> = ({
	open,
	onClose,
	title,
	Content,
	cancelLabel,
	confirmLabel,
	onConfirm,
}) => {
	const elRef = useRef(createHostElement())

	useEffect(() => {
		document.body.appendChild(elRef.current)

		return () => {
			document.body.appendChild(elRef.current)
		}
	}, [])

	return (
		elRef.current &&
		ReactDOM.createPortal(
			<Backdrop className={open ? 'is-open' : ''} onClick={onClose}>
				<Box onClick={ev => ev.stopPropagation()}>
					{!!title && <Title>{title}</Title>}
					{Content}
					{(cancelLabel || confirmLabel) && (
						<Buttons>
							{!!cancelLabel && (
								<Button variant="text" onClick={onClose}>
									{cancelLabel}
								</Button>
							)}
							{!!confirmLabel && (
								<Button variant="text" onClick={onConfirm}>
									{confirmLabel}
								</Button>
							)}
						</Buttons>
					)}
				</Box>
			</Backdrop>,
			elRef.current,
		)
	)
}

export default Modal
