import styled from 'styled-components'
import { SummaryEye } from './types'

const EyeLogoRoot = styled.div`
	background-color: white;
	align-items: center;
	justify-content: center;
`

const EyeLogoCircle = styled.div`
	width: 75%;
	aspect-ratio: 1 / 1;
	border-radius: 50%;
	color: white;
	background-color: #20233d;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	font-weight: bold;
	font-family: 'Ivar Display';
`

const EyeLogoLabel = styled.span`
	background-color: transparent;
`

type EyeLogoProps = {
	className?: string
	text: SummaryEye
}

export function EyeLogo({ className, text }: EyeLogoProps): JSX.Element {
	return (
		<EyeLogoRoot className={className}>
			<EyeLogoCircle>
				<EyeLogoLabel>{text}</EyeLogoLabel>
			</EyeLogoCircle>
		</EyeLogoRoot>
	)
}
