import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { useSelector } from 'react-redux'

import {
	selectCursorKey,
	selectCursorPosition,
	selectCursorState,
} from './selectors'
import { pxToRem } from '../../libs/style'
import { createPortal } from 'react-dom'

const circleAnimation = keyframes`
 	0% { opacity: 0; }
 	1% { opacity: 1; }
 	100% { opacity: 0; }
`

const AnimatedCircle = styled.div<{
	marginTop: number
	marginLeft: number
	visible: boolean
}>`
	position: absolute;
	height: ${pxToRem(45)}rem;
	width: ${pxToRem(45)}rem;
	border-radius: 50%;
	background-color: #feff73;
	top: calc(${props => props.marginTop}% - ${pxToRem(22.5)}rem);
	left: calc(${props => props.marginLeft}% - ${pxToRem(22.5)}rem);
	animation-name: ${circleAnimation};
	animation-duration: 10s;
	animation-fill-mode: forwards;
	${props => (props.visible ? 'display: none;' : 'display: block;')}
`

const useCursor = () => {
	const cursor = useSelector(selectCursorState)
	const cursorKey = useSelector(selectCursorKey)
	const normalizedPosition = useSelector(selectCursorPosition)
	const position = {
		x: normalizedPosition.x * 100,
		y: normalizedPosition.y * 100,
	}
	const [isCursorVisible, setIsCursorVisible] = useState<boolean>(false)

	useEffect(() => {
		setIsCursorVisible(false)
		if (cursorKey) {
			const timeout = setTimeout(() => {
				setIsCursorVisible(true)
			}, 10000)
			return () => {
				clearTimeout(timeout)
			}
		}
	}, [cursorKey])

	return cursor
		? createPortal(
				<AnimatedCircle
					key={'cursor_' + cursorKey}
					marginLeft={position.x}
					marginTop={position.y}
					visible={isCursorVisible}
				/>,
				document.body,
		  )
		: null
}

export default useCursor
