import React from 'react'
import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'
import { Eye } from '../../../model/model'
import theme, { GRAPH_PALETE } from '../../../styleguide/theme'
import {
	LegendItemColor,
	LegendItemWrapper,
	LegendText,
	LegendWrapper,
} from '../graphs/graph.style'
import { Legends } from '../graphs/interfaces'

const LegendTitle = styled.div`
	font-size: ${pxToRem(16)}rem;
	display: flex;
`

const LegendName = styled.h3`
	font-family: 'Ivar Display', sans-serif;
	font-style: italic;
	font-weight: 300;
`

export const LegendType = styled.span`
	font-family: 'SpaceGrotesk';
	font-style: normal;
`

const LegendBox = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`

const LegendWrapperStyled = styled(LegendWrapper)`
	margin-top: 0;
`

const LegendItemWrapperStyled = styled(LegendItemWrapper)`
	padding-left: ${pxToRem(theme.spacing(1))}rem;
`

const LegendItemColorStyled = styled(LegendItemColor)`
	height: ${pxToRem(theme.spacing(2))}rem;
	width: ${pxToRem(theme.spacing(2))}rem;
	margin-right: ${pxToRem(theme.spacing(0.375))}rem;
`

const LegendTextStyled = styled(LegendText)`
	font-size: ${pxToRem(theme.spacing(2))}rem;
`

type Prop = {
	legends: Legends[]
}

const Legend: React.FC<Prop> = ({ legends }) => (
	<LegendWrapperStyled>
		{legends.map((legend: any, index: number) => (
			<LegendItemWrapperStyled key={index}>
				<LegendItemColorStyled {...legend} />
				<LegendTextStyled>{legend.value}</LegendTextStyled>
			</LegendItemWrapperStyled>
		))}
	</LegendWrapperStyled>
)

const TOTGraphLegend: React.FC<{
	title?: string
	titleType?: string
	eye?: Eye
}> = ({ title, titleType }) => {
	const graphLegends = [
		{
			key: 'OS',
			value: 'OS',
			color: GRAPH_PALETE.GREEN,
		},
		{
			key: 'OD',
			value: 'OD',
			color: GRAPH_PALETE.PURPLE,
		},
	]

	return (
		<LegendBox>
			{title && (
				<LegendTitle>
					<LegendName>
						{title}
						{titleType && <LegendType>{'\xa0(' + titleType + ')'}</LegendType>}
					</LegendName>
				</LegendTitle>
			)}
			<Legend legends={graphLegends} />
		</LegendBox>
	)
}

export default TOTGraphLegend
