import { FC } from 'react'
import AxialLengthGraphMono from '../graphs/AxialLengthGraph/AxialLengthGraphMono'
import {
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
	MyopiaSummaryZoomType,
} from '../graphs/interfaces'
import SphereEquivalentGraphMono from '../graphs/SphereEquivalentGraph/SphereEquivalentGraphMono'
import MyopiaSummaryGraphCard from './MyopiaSumaryGraphCard'
import { createPortal } from 'react-dom'
import { MyopiaZoomGraphContainer, MyopiaZoomPortal } from './styles'
import { useTranslation } from 'react-i18next'
import { useMyopiaGraphData } from '../../../hooks/useMyopiaGraphData'

type Props = {
	data: MyopiaSummaryZoomType
}
const REFRACTION_TRANSLATION_ROUTE = 'content.myopia.refractionAnalysis'
const AXIAL_TRANSLATION_ROUTE = 'content.myopia.axialLengthAnalysis'

const MyopiaSummaryZoom: FC<Props> = ({ data }) => {
	const { t } = useTranslation()
	const graphsData = useMyopiaGraphData()
	const eye = data.eye === 'L' ? 'left' : 'right'
	const dataType =
		data.type === 'BI' ? 'calculatedAxialLength' : 'calculatedSphereEquivalent'

	const zoomGraphData = graphsData ? graphsData[dataType]![eye] : undefined
	const title = t(
		`${
			dataType === 'calculatedAxialLength'
				? AXIAL_TRANSLATION_ROUTE
				: REFRACTION_TRANSLATION_ROUTE
		}.title`,
	)
	const titlePrefix = t(
		`${
			dataType === 'calculatedAxialLength'
				? AXIAL_TRANSLATION_ROUTE
				: REFRACTION_TRANSLATION_ROUTE
		}.${eye === 'left' ? 'OS' : 'OD'}`,
	)

	return createPortal(
		<MyopiaZoomPortal>
			<MyopiaZoomGraphContainer>
				<MyopiaSummaryGraphCard title={title} titlePrefix={titlePrefix} $zoom>
					{dataType === 'calculatedAxialLength' ? (
						<AxialLengthGraphMono
							zoomGraph
							calculatedAxialLengthData={
								zoomGraphData as CalculatedAxialLengthData
							}
							scaleY={undefined}
							gender={graphsData?.calculatedAxialLength?.gender}
							ethnicity={graphsData?.calculatedAxialLength?.ethnicity}
						/>
					) : (
						<SphereEquivalentGraphMono
							zoomGraph
							calculatedSphereEquivalentData={
								zoomGraphData as CalculatedSphereEqData
							}
							scaleY={undefined}
						/>
					)}
				</MyopiaSummaryGraphCard>
			</MyopiaZoomGraphContainer>
		</MyopiaZoomPortal>,
		document.body,
	)
}

export default MyopiaSummaryZoom
