import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'
import Modal from '../../../styleguide/Modal'
import theme from '../../../styleguide/theme'
import {
	CameraButton,
	FluentThemeProvider,
	MicrophoneButton,
	usePropsFor,
} from '@azure/communication-react'
import {
	MicOffOutlined,
	MicOutlined,
	VideocamOffOutlined,
	VideocamOutlined,
} from '@material-ui/icons'

const Message = styled.p`
	font-family: 'Ivar Display';
	font-style: italic;
	font-size: ${theme.typography.fontSizes.L};
`
const DeviceSelector = styled.div`
	margin: ${pxToRem(theme.spacing(3))}rem 0;
	display: flex;
	padding: ${pxToRem(theme.spacing(2))}rem;
	gap: ${pxToRem(theme.spacing(2))}rem;
	align-items: center;
`

const SettingsContainer = styled.div`
	width: ${pxToRem(450)}rem;
`

type Props = {
	setShowSettings: (show: boolean) => void
	setSettingsMenuOpen: (show: boolean) => void
	open: boolean
}

const AzureDeviceSelector: React.FC<Props> = ({
	setShowSettings,
	setSettingsMenuOpen,
	open,
}) => {
	const { t } = useTranslation()

	const cameraProps = usePropsFor(CameraButton)
	const microphoneProps = usePropsFor(MicrophoneButton)

	const closePopup = () => {
		setShowSettings(false)
		setSettingsMenuOpen(false)
	}

	return (
		<>
			<FluentThemeProvider>
				<Modal
					open={open}
					onConfirm={closePopup}
					title={t('settings.modalTitle')}
					confirmLabel={t('app.confirm')}
					Content={
						<SettingsContainer>
							<DeviceSelector>
								<Message>{t('settings.cameras')}</Message>
								{cameraProps && (
									<CameraButton
										{...cameraProps}
										disabled={cameraProps.disabled}
										enableDeviceSelectionMenu
										onRenderIcon={props => {
											return props?.checked ? (
												<VideocamOutlined />
											) : (
												<VideocamOffOutlined />
											)
										}}
									/>
								)}
							</DeviceSelector>

							<DeviceSelector>
								<Message>{t('settings.microphones')}</Message>
								{microphoneProps && (
									<MicrophoneButton
										{...microphoneProps}
										disabled={microphoneProps.disabled}
										enableDeviceSelectionMenu
										onRenderIcon={props => {
											return props?.checked ? (
												<MicOutlined />
											) : (
												<MicOffOutlined />
											)
										}}
									/>
								)}
							</DeviceSelector>
						</SettingsContainer>
					}
				/>
			</FluentThemeProvider>
		</>
	)
}

export default AzureDeviceSelector
