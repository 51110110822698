import {
	ConnectClinicalData,
	ConnectDiseaseAndTreatment,
	ConnectRxAddOns,
} from '../../model/coremedia'
import { RootState } from '../../model/model'
import { createSelector } from '@reduxjs/toolkit'

export const selectCoreMediaClinicalData = (
	state: RootState,
): ConnectClinicalData => state.coremedia.clinicalData
export const selectCoreMediaRxAddons = (state: RootState): ConnectRxAddOns =>
	state.coremedia.rxAddOns
export const selectCoreMediaDiseaseAndTreatment = (
	state: RootState,
): ConnectDiseaseAndTreatment => state.coremedia.diseaseAndTreatment

export const selectRxTypeTitle = (rxType: string) =>
	createSelector(selectCoreMediaRxAddons, rxAddOns =>
		rxAddOns[rxType].value.split(/(?=[A-Z])/).join(' '),
	)
