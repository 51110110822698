import React from 'react'

import { SvgProps } from '../../model/model'

const ThickIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 4.962 3.618"
			height="13.333"
			width="17.778"
			fill="currentColor"
			{...props}
		>
			<path d="M4.5.074L1.688 2.927.464 1.601C.358 1.487.256 1.58.14 1.685c-.114.105-.198.184-.093.299l1.428 1.543a.283.283 0 00.408.008L4.9.47c.109-.11.055-.253-.027-.334-.139-.168-.267-.17-.375-.062z" />
		</svg>
	)
}

export default ThickIcon
