import { FC } from 'react'
import TrendsOverTimeGraphCard from './TrendsOverTimeGraphCard'
import { createPortal } from 'react-dom'
import {
	GraphWrapperStyled,
	ZoomPortal,
	ZoomGraphContainer,
	RecapContainerStyled,
	ZoomLegendLabel,
	ZoomEyeLabel,
} from './styles'
import { useTranslation } from 'react-i18next'
import { TrendsOverTimeGraphData } from '../../../model/model'
import { Grid } from '@material-ui/core'
import InstrumentGraph, { getInstrumentData } from './InstrumentGraph'
import TOTGraphZoomLegend from './TOTGraphZoomLegend'

type Props = {
	data: TrendsOverTimeGraphData
}

const TrendsOverTimeGraphZoom: FC<Props> = ({ data }) => {
	const { t } = useTranslation()
	const { instrument, tot } = data
	const title = t(`tot.graphs.legends.${data.instrument || ''}`)
	const suffix = data.instrument
		? t(`tot.graphs.acronyms.${data.instrument}`)
		: ''

	if (!instrument) return <></>

	return createPortal(
		<ZoomPortal>
			<ZoomGraphContainer>
				<TrendsOverTimeGraphCard title={title} titleSuffix={suffix} $zoom>
					<Grid container id="graph-grid">
						<Grid item xs={6}>
							<GraphWrapperStyled>
								<ZoomEyeLabel>OS</ZoomEyeLabel>
								<InstrumentGraph
									instrumentData={tot}
									instrumentType={instrument}
									eye="OS"
								/>
							</GraphWrapperStyled>
						</Grid>
						<Grid item xs={6}>
							<GraphWrapperStyled>
								<ZoomEyeLabel>OD</ZoomEyeLabel>
								<InstrumentGraph
									instrumentData={tot}
									instrumentType={instrument}
									eye="OD"
								/>
							</GraphWrapperStyled>
						</Grid>
					</Grid>
					<RecapContainerStyled>
						<TOTGraphZoomLegend
							eye="OS"
							instrumentType={instrument}
							label={t('tot.graphs.zoomLegendOS')}
							values={getInstrumentData(tot, instrument, 'OS').map(e => e.OS)}
						/>
						<ZoomLegendLabel>{t('tot.graphs.zoomLegend')}</ZoomLegendLabel>
						<TOTGraphZoomLegend
							eye="OD"
							instrumentType={instrument}
							label={t('tot.graphs.zoomLegendOD')}
							values={getInstrumentData(tot, instrument, 'OD').map(e => e.OD)}
						/>
					</RecapContainerStyled>
				</TrendsOverTimeGraphCard>
			</ZoomGraphContainer>
		</ZoomPortal>,
		document.body,
	)
}

export default TrendsOverTimeGraphZoom
