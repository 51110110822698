import authActions from '../features/auth/actions'
import { selectAuthToken, selectSessionId } from '../features/auth/selectors'
import notificationsActions from '../features/notifications/actions'
import { selectAppLang, selectStoreId } from '../features/settings/selectors'
import i18n from '../i18n'
import store from '../store'

const SUCCESS_CODES = [200, 201]

export const TOKEN_EXPIRED_ERROR = 'Token expired'
const VALIDATION_ERROR = 'Error during token verify'
const NOT_AUTHENTICATED = 'Not authenticated'

const tokenErrors = [TOKEN_EXPIRED_ERROR, VALIDATION_ERROR, NOT_AUTHENTICATED]

export const decodeRes = async <T>(
	res: Response,
	typeGuard?: (x: any) => x is T,
): Promise<T> =>
	res
		.json()
		.catch(async () => {
			const dataText = await res.text()
			// This error is not traslated since we exect it to be  rare and we need to include the response as text
			throw new Error(
				`${res.url} - The response is not a valid JSON: ${dataText}`,
			)
		})
		.then(data => {
			if (typeGuard && !typeGuard(data)) {
				// This error is not traslated since we exect it to be  rare and we need to include the response
				throw new Error(
					`${
						res.url
					} - The response does not match type definition: ${JSON.stringify(
						data,
					)}`,
				)
			}

			if (res.status === 403 || res.status === 401) {
				let message
				if (tokenErrors.includes(data.message)) {
					message = data.message
				} else {
					message = i18n.t('errors.unauthorized')
				}
				throw new Error(message)
			}

			// TODO: add other special status handling as needed

			if (!SUCCESS_CODES.includes(res.status)) {
				throw new Error(`${res.url} - ${res.status} - ${res.statusText}`)
			}

			return data
		})

export const getCommonHeaders = ({
	addContentType = false,
}: {
	addContentType?: boolean
}) => {
	const state = store.getState()
	const token = selectAuthToken(state)
	const sessionId = selectSessionId(state)
	const lang = selectAppLang(state)
	const storeId = selectStoreId(state)

	const headers: Record<string, string> = {
		accept: 'application/json',
		authorization: `Bearer ${token}`,
		lang: lang || 'en-US',
		'x-store': storeId || '0',
		'session-id': sessionId,
	}

	if (addContentType === true) {
		headers['Content-type'] = 'application/json'
	}

	return headers
}

export const fetchJson = <T>(
	input: RequestInfo,
	init?: RequestInit,
	// if nonBlockingError is passed then errors trigger notifications instead of blocking errors
	nonBlockingError?: {
		messageKey: string
		autoClose?: boolean
		closable?: boolean
	},
	typeGuard?: (x: any) => x is T,
): Promise<T | void> => {
	const addContentType = init && init.method !== 'GET' ? true : false
	const headers = getCommonHeaders({ addContentType })

	return window
		.fetch(
			input,
			Object.assign(
				{
					headers,
				},
				init || {},
			),
		)
		.then(res => decodeRes<T>(res, typeGuard))
		.catch(err => {
			if (tokenErrors.includes(err.message)) {
				store.dispatch(authActions.logout())
			} else if (nonBlockingError) {
				store.dispatch(
					notificationsActions.addNotification({
						type: 'error',
						message: `${i18n.t(nonBlockingError.messageKey)}${
							err.message ? ` - ${err.message}` : ''
						}`,
						autoClose: nonBlockingError.autoClose === false ? false : true,
						closable: nonBlockingError.closable === false ? false : true,
						errorType: 'http',
					}),
				)
			} else {
				throw err
			}
		})
}
