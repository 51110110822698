import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { selectAppRoom } from '../features/auth/selectors'
import settingsActions from '../features/settings/actions'
import { selectRoomId, selectStoreId } from '../features/settings/selectors'
import { pxToRem } from '../libs/style'
import theme from '../styleguide/theme'
import LogoutModal from './LogoutModal'
import AzureDeviceSelector from './azureCommunication/components/AzureDeviceSelector'
import { useCall, useCallAgent } from '@azure/communication-react'

const Wrapper = styled.div`
	position: absolute;
	right: 0;
	margin-top: ${pxToRem(theme.spacing(1))}rem;
	transform: translateY(-300%);
	transition: transform ${theme.transitions.duration.standard}ms;
	background-color: ${theme.palette.background.default};
	padding: ${pxToRem(theme.spacing(1))}rem ${pxToRem(theme.spacing(0.5))}rem;
	min-width: ${pxToRem(164)}rem;
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem 0
		${pxToRem(theme.shape.borderRadius)}rem 0;
	visibility: hidden;

	&.is-open {
		visibility: visible;
		transform: translateY(0);
		z-index: 1200;
	}
`

const MenuItem = styled.button`
	display: block;
	width: 100%;
	padding: ${pxToRem(theme.spacing(1))}rem;
	text-align: right;
`

const Divider = styled.hr`
	border-top: solid ${pxToRem(theme.shape.borderThickness)}rem currentColor;
`

const SettingsMenu: React.FC<{
	open: boolean
	callActive: boolean
	setSettingsMenuOpen: (show: boolean) => void
}> = ({ open, callActive, setSettingsMenuOpen }) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [showLogoutModal, setShowLogoutModal] = useState(false)
	const roomId = useSelector(selectStoreId)
	const storeId = useSelector(selectRoomId)
	const [showSettings, setShowSettings] = useState<boolean>(false)
	const room = useSelector(selectAppRoom)
	const call = useCall()
	const callAgent = useCallAgent()

	return (
		<Wrapper className={open ? 'is-open' : ''}>
			{room && (
				<>
					<MenuItem disabled={true} data-testid="ddm-room-name-button">
						{room.name}
					</MenuItem>
				</>
			)}
			{roomId && storeId && (
				<>
					<MenuItem
						data-testid="ddm-select-location-button"
						disabled={callActive === true}
						onClick={() => {
							dispatch(settingsActions.resetRoomAndStoreId())
							if (callAgent) {
								callAgent.dispose()
							}
						}}
					>
						{t('settings.selectLocation')}
					</MenuItem>
					<Divider />
				</>
			)}
			<>
				<MenuItem
					disabled={callActive === false}
					onClick={() => setShowSettings(!showSettings)}
					data-testid="ddm-devices-button"
				>
					{t('settings.devices')}
				</MenuItem>
				<Divider />
			</>
			<MenuItem
				disabled={callActive === true}
				onClick={() => setShowLogoutModal(!showLogoutModal)}
				data-testid="ddm-sign-out-button"
			>
				{t('login.signOut')}
			</MenuItem>

			{call && (
				<AzureDeviceSelector
					open={showSettings}
					setShowSettings={setShowSettings}
					setSettingsMenuOpen={setSettingsMenuOpen}
				/>
			)}
			<LogoutModal
				open={showLogoutModal}
				onClose={() => setShowLogoutModal(false)}
			/>
		</Wrapper>
	)
}

export default SettingsMenu
