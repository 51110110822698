import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectUsername } from '../features/auth/selectors'
import {
	connectToSocketKeepAlive,
	disconnectFromSocketKeepAlive,
} from '../socket/keepAlive/keepAliveSocket'

export const useKeepAliveUser = () => {
	const username = useSelector(selectUsername)

	useEffect(() => {
		connectToSocketKeepAlive({
			username,
		})

		return () => disconnectFromSocketKeepAlive()
	}, [username])
}
