import { Socket, io } from 'socket.io-client'
import appConfig from '../../config'
import { newTeloSocketConnector } from '../teloSocketConnector'
import { disconnectTeloSocket } from '../teloSocketRegistry'
import { TeloSocket } from '../teloSocketTypes'
import { basicDisconnectSocket, isSocketConnected } from '../teloSocketUtils'

type KeepAliveSocket = TeloSocket

type KeepAliveSocketArgs = {
	username: string
}

const newSocket = ({ username }: KeepAliveSocketArgs): KeepAliveSocket => {
	let socket: Socket | null = null

	const connect = () => {
		if (isSocketConnected(socket)) {
			return
		}
		socket = io(appConfig.socketUrl, {
			query: { keepalive: 'set', username, platform: 'cockpit' },
			transports: ['websocket'],
		})
	}

	const disconnect = () => {
		basicDisconnectSocket(socket)
		socket = null
	}

	return { connect, disconnect }
}

const SOCKET_KEY = 'keepalive'

const socketConnector = newTeloSocketConnector()

export function connectToSocketKeepAlive(args: KeepAliveSocketArgs): void {
	socketConnector.connectSocket({
		socketKey: SOCKET_KEY,
		newSocket: () => newSocket(args),
	})
}

export function disconnectFromSocketKeepAlive(): void {
	disconnectTeloSocket(SOCKET_KEY)
}
