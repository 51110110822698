import { isUndefined } from 'lodash'
import { useState, useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
	getHistoricalExamsDataByType,
	retrieveAxialLength,
	retrieveSphereEquivalent,
} from '../apiCalls'
import {
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
	Scale,
} from '../components/content/graphs/interfaces'
import { Side, calculateYScale } from '../components/content/graphs/utils'
import { selectExamData, selectExamDif } from '../features/exam/selectors'
import {
	formatExamDataWithAge,
	retrieveAxialLengthValueAndAge,
	retrieveFinalRefractionValueAndAge,
} from '../libs/myopia'
import { InternalPatient } from '../model/exam'
import { BiometerDataApi, PhoropterDataApi } from '../model/instruments'
import { MyopiaGraphType } from '../model/model'

type UseMyopiaChartsProps = {
	examId: string
	internalPatient?: InternalPatient
}

type UseSphereCalculatedGraphProps = Omit<UseMyopiaChartsProps, 'examType'>
type UseAxialLengthGraphProps = Omit<
	UseMyopiaChartsProps,
	'examType' | 'examId'
> & {
	gender?: string
	ethnicity?: string
}

export const useMyopiaGraphData = (): MyopiaGraphType | undefined => {
	const exam = useSelector(selectExamData)
	const examDif = useSelector(selectExamDif)

	if (!exam) {
		return
	}

	const ethnicity = examDif?.personalInfo?.ethnicity
	const gender = examDif?.personalInfo?.gender
	const internalPatient = exam.internalPatient
	const examId = exam._id

	const { calculatedSphereEquivalent, scaleY: sphereEqScaleY } =
		useSphereCalculatedGraph({ examId, internalPatient })
	const { calculatedAxialLength, scaleY: axialScaleY } =
		useAxialLengthCalculatedGraph({ internalPatient, ethnicity, gender })

	return {
		calculatedSphereEquivalent: {
			...calculatedSphereEquivalent,
			scaleY: sphereEqScaleY,
		},
		calculatedAxialLength: {
			...calculatedAxialLength,
			scaleY: axialScaleY,
			ethnicity,
			gender,
		},
	}
}

export const useSphereCalculatedGraph = ({
	examId,
	internalPatient,
}: UseSphereCalculatedGraphProps) => {
	const [calculatedSphereEquivalent, setCalculatedSphereEquivalent] = useState<{
		right: CalculatedSphereEqData
		left: CalculatedSphereEqData
	}>()

	const [finalRefractionByAge, setFinalRefractionByAge] = useState<{
		right: { age: number; value: number }[]
		left: { age: number; value: number }[]
	}>()
	const [scaleY, setScaleY] = useState<Scale>()

	const getHistoricalExamsDataByTypeCallback = useCallback(
		(internalPatient, examType) =>
			getHistoricalExamsDataByType(internalPatient, examType),
		[],
	)
	const retrieveSphereEquivalentCallback = useCallback(
		values => retrieveSphereEquivalent(values),
		[],
	)

	useEffect(() => {
		const getExamsDataPH = async () => {
			const examsDataPHResult = await getHistoricalExamsDataByTypeCallback(
				internalPatient!._id,
				'PH',
			)
			const formattedExamDataWithAge = formatExamDataWithAge<PhoropterDataApi>(
				internalPatient!,
				examsDataPHResult!.data,
				'PH',
				examId,
			)
			const retrievedFinalRefractionRight = retrieveFinalRefractionValueAndAge(
				Side.R,
				formattedExamDataWithAge,
			)
			const retrievedFinalRefractionLeft = retrieveFinalRefractionValueAndAge(
				Side.L,
				formattedExamDataWithAge,
			)

			setFinalRefractionByAge({
				right: retrievedFinalRefractionRight,
				left: retrievedFinalRefractionLeft,
			})
		}

		if (internalPatient) {
			getExamsDataPH()
		}
	}, [internalPatient, getHistoricalExamsDataByTypeCallback, examId])

	useEffect(() => {
		const retrieveRightAndLeftCalculatedValues = async () => {
			const [calculatedSphereEquivalentRight, calculatedSphereEquivalentLeft] =
				await Promise.all([
					retrieveSphereEquivalentCallback(finalRefractionByAge!.right),
					retrieveSphereEquivalentCallback(finalRefractionByAge!.left),
				])
			setCalculatedSphereEquivalent({
				left: calculatedSphereEquivalentLeft!.data,
				right: calculatedSphereEquivalentRight!.data,
			})
		}

		if (
			isUndefined(calculatedSphereEquivalent) &&
			!isUndefined(finalRefractionByAge)
		) {
			retrieveRightAndLeftCalculatedValues()
		}
	}, [
		setCalculatedSphereEquivalent,
		finalRefractionByAge,
		retrieveSphereEquivalentCallback,
		calculatedSphereEquivalent,
	])

	useEffect(() => {
		if (!isUndefined(calculatedSphereEquivalent)) {
			setScaleY(
				calculateYScale(
					calculatedSphereEquivalent?.left.values,
					calculatedSphereEquivalent?.right.values,
				),
			)
		}
	}, [calculatedSphereEquivalent])

	return { scaleY, calculatedSphereEquivalent }
}

export const useAxialLengthCalculatedGraph = ({
	internalPatient,
	gender,
	ethnicity,
}: UseAxialLengthGraphProps) => {
	const [calculatedAxialLength, setCalculatedAxialLength] = useState<{
		right: CalculatedAxialLengthData
		left: CalculatedAxialLengthData
	}>()

	const [axialLengthByAge, setAxialLengthByAge] = useState<{
		right: { age: number; value: number }[]
		left: { age: number; value: number }[]
	}>()
	const [scaleY, setScaleY] = useState<Scale>()

	const getHistoricalExamsDataByTypeCallback = useCallback(
		(internalPatient, examType) =>
			getHistoricalExamsDataByType(internalPatient, examType),
		[],
	)
	const retrieveAxialLengthCallback = useCallback(
		({ values, gender, ethnicity }) =>
			retrieveAxialLength({ values, gender, ethnicity }),
		[],
	)

	useEffect(() => {
		const getExamsDataBI = async () => {
			const examsDataBIResult = await getHistoricalExamsDataByTypeCallback(
				internalPatient!._id,
				'BI',
			)
			const formattedExamDataWithAge = formatExamDataWithAge<BiometerDataApi>(
				internalPatient!,
				examsDataBIResult!.data,
				'BI',
			)
			const retrievedFinalRefractionRight = retrieveAxialLengthValueAndAge(
				Side.R,
				formattedExamDataWithAge,
			)
			const retrievedFinalRefractionLeft = retrieveAxialLengthValueAndAge(
				Side.L,
				formattedExamDataWithAge,
			)

			setAxialLengthByAge({
				right: retrievedFinalRefractionRight,
				left: retrievedFinalRefractionLeft,
			})
		}

		if (internalPatient) {
			getExamsDataBI()
		}
	}, [internalPatient, getHistoricalExamsDataByTypeCallback])

	useEffect(() => {
		const retrieveRightAndLeftCalculatedValues = async () => {
			const [calculatedAxialLengthRight, calculatedAxialLengthLeft] =
				await Promise.all([
					retrieveAxialLengthCallback({
						values: axialLengthByAge!.right,
						gender,
						ethnicity,
					}),
					retrieveAxialLengthCallback({
						values: axialLengthByAge!.left,
						gender,
						ethnicity,
					}),
				])
			setCalculatedAxialLength({
				left: calculatedAxialLengthLeft!.data,
				right: calculatedAxialLengthRight!.data,
			})
		}

		if (isUndefined(calculatedAxialLength) && !isUndefined(axialLengthByAge)) {
			retrieveRightAndLeftCalculatedValues()
		}
	}, [
		axialLengthByAge,
		retrieveAxialLengthCallback,
		gender,
		ethnicity,
		calculatedAxialLength,
	])

	useEffect(() => {
		if (!isUndefined(calculatedAxialLength)) {
			setScaleY(
				calculateYScale(
					calculatedAxialLength?.left.values,
					calculatedAxialLength?.right.values,
				),
			)
		}
	}, [calculatedAxialLength])
	return { scaleY, calculatedAxialLength }
}
