import React from 'react'
import {
	ResponsiveContainer,
	ComposedChart,
	XAxis,
	YAxis,
	CartesianGrid,
	Legend as RechartsLegend,
} from 'recharts'
import { ContentType } from 'recharts/types/component/DefaultLegendContent'

type GraphWithAxesProps<T> = {
	children: React.ReactNode[]
	graphData?: T[]
	scaleY?: MinMax
	yUnit?: number
	legends?: ContentType
	labels: {
		x: string
		y: string
	}
}

type MinMax = {
	min: number
	max: number
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const GraphWithAxes = <T extends object>({
	children,
	graphData,
	scaleY,
	legends,
	yUnit = 1,
	labels,
}: GraphWithAxesProps<T>) => {
	return (
		<ResponsiveContainer>
			<ComposedChart
				data={graphData}
				margin={{ top: 15, right: 30, left: 20, bottom: 15 }}
			>
				<XAxis
					type="category"
					interval="preserveStartEnd"
					dataKey="age"
					label={{ value: labels.x, position: 'insideBottom', dy: 10 }}
				/>
				<YAxis
					type="number"
					dataKey="value"
					domain={[scaleY?.min, scaleY?.max] as number[]}
					tickCount={
						((scaleY?.max as number) - (scaleY?.min as number)) / yUnit + 1
					}
					minTickGap={1}
					interval={0}
					label={{
						value: labels.y,
						angle: -90,
						position: 'insideLeft',
						dy: 40,
					}}
				/>
				{children}
				<CartesianGrid strokeDasharray="3 3" />
				{legends && <RechartsLegend content={legends} />}
			</ComposedChart>
		</ResponsiveContainer>
	)
}
