import React, { MouseEventHandler } from 'react'

type Props = {
  'aria-label'?: string
  size?: 'small' | 'medium' | 'large'
  className?: string
  disabled?: boolean
  onClick: MouseEventHandler<HTMLButtonElement>
}

const IconButton: React.FC<Props> = ({ children, ...props }) => <button {...props}>{children}</button>

export default IconButton
