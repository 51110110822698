import { getCoreMediaContentAPI } from '../../apiCalls'
import { AppThunkPromise } from '../../model/model'
import { slice } from './slice'

const fetchCoreMediaContent = (): AppThunkPromise => dispatch  =>
	getCoreMediaContentAPI()
		.then(content => {
			content && dispatch(slice.actions.setContent(content))
		})

const coreMediaActions = {
	...slice.actions,
	fetchCoreMediaContent,
}

export default coreMediaActions
