import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InstrumentType } from '../../model/instruments'

import { DisplayableContentState, TonometryContent, VideoStatus } from '../../model/model'
import { isTonometrySection } from './utils'

const initialState: DisplayableContentState = {}

export const slice = createSlice({
	name: 'displayContent',
	initialState,
	reducers: {
		setDisplayContent: (
			state,
			{ payload }: PayloadAction<DisplayableContentState>,
		) => {
			let mergedData = null
			if (payload.data && state.data && state.type === payload.type) // do not delete additional data in the content
			{
				mergedData = {
					...state.data,
					...payload.data
				}

				if (state.data.content && payload.data.content) {
					mergedData.content = {
						...state.data.content,
						...payload.data.content
					}
				}
			}
			const newState: DisplayableContentState = {
				...state,
				...payload,
				videoStatus: {}
			}
			if (mergedData)
				newState.data = mergedData

			return newState
		},
		setPlaying: (state, { payload }: PayloadAction<VideoStatus>) => {
			state.videoStatus = payload
		},
		setFullscreen: (state, { payload }: PayloadAction<boolean>) => {
			if (state.videoStatus) {
				state.videoStatus = {
					...state.videoStatus,
					isFullscreen: payload
				}
			} else {
				state.videoStatus = {
					isFullscreen: payload
				}
			}
		},
		clearDisplayContent: () => {
			return {

			}
		},
		setContent: (state, { payload }: PayloadAction<any>) => {
			if (!state.data)
				state.data = { content: payload, section: state.type as any }
			else
				state.data.content = { ...state.data.content, ...payload }
		},
		setAdditionalDataForInstrument: (state, { payload }: PayloadAction<{ instrument: InstrumentType, data: any }>) => {
			switch (payload.instrument) {
				case 'NT':
					if (isTonometrySection(state)) {
						return {
							...state,
							data: {
								...state.data,
								section: 'tonometer',
								data: payload.data,
								content: (state.data as TonometryContent)?.content || {
									OD: null,
									OS: null
								}
							}
						}
					}
					console.error('attempted to update instrument ' + payload.instrument + ' while another component was mounted')
					return state
				default:
					console.error('unsupported instrument update requested: ' + payload.instrument)
					return state
			}
		},
		setLoopTimes: (state, { payload }: PayloadAction<{startTime?: number, endTime?: number}>) => {
			if (state.videoStatus) {
				state.videoStatus = {
					...state.videoStatus,
					startTime: payload.startTime,
					endTime: payload.endTime,
				}
			} else {
				state.videoStatus = {
					startTime: payload.startTime,
					endTime: payload.endTime
				}
			}
		}
	},
})

export default slice.reducer
