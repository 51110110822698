import { memo, useEffect, useMemo, useState } from 'react'
import { Line, ReferenceArea } from 'recharts'
import {
	CalculatedSphereEqData,
	MinMax,
	SphereEqGraphData,
	SphereEqGraphProps,
} from '../interfaces'
import { GraphWithAxes } from '../GraphWithAxes'
import { Colors } from './constant'
import {
	createChartDataFromSphereEquivalent,
	getMinMaxAxis,
	setMyopiaY,
	setPreMyopiaY,
} from './utils'
import { useTranslation } from 'react-i18next'
import { isUndefined } from 'lodash'
import SphereEquivalentLegend from './SphereEquivalentLegend'

export const SphereEqGraph = ({
	yUnit = 0.25,
	showLegends,
	scaleY,
	calculatedValues,
}: SphereEqGraphProps) => {
	const [dataToBuildGraph, setDataToBuildGraph] = useState<
		CalculatedSphereEqData | undefined
	>(undefined)

	const [graphData, setGraphData] = useState<SphereEqGraphData[]>()
	const [internalScaleY, setInternalScaleY] = useState<MinMax | undefined>(
		scaleY,
	)
	const [scaleX, setScaleX] = useState<MinMax>()
	const [myopiaPresentZoneY, setMyopiaPresentZoneY] = useState<MinMax>()
	const [preMyopiaPresentZoneY, setPreMyopiaPresentZoneY] = useState<MinMax>()
	const { t } = useTranslation()

	const labels = useMemo(
		() => ({
			x: t('content.myopia.refractionAnalysis.graph.label.x'),
			y: t('content.myopia.refractionAnalysis.graph.label.y'),
		}),
		[t],
	)

	useEffect(() => {
    if (!isUndefined(calculatedValues)) {
      setDataToBuildGraph(calculatedValues as CalculatedSphereEqData)
    }
  }, [calculatedValues])

	useEffect(() => {
		if (!isUndefined(dataToBuildGraph)) {
			if (!scaleY) {
				setInternalScaleY(
					getMinMaxAxis(
						dataToBuildGraph.values,
						dataToBuildGraph.standard,
						'y',
					),
				)
			} else {
				setInternalScaleY(
					getMinMaxAxis(
						[{ y: scaleY.min }, { y: scaleY.max }],
						dataToBuildGraph.standard,
						'y',
					),
				)
			}
			setScaleX(
				getMinMaxAxis(dataToBuildGraph.values, dataToBuildGraph.standard, 'x'),
			)
			setPreMyopiaPresentZoneY(setPreMyopiaY(dataToBuildGraph.zones))
			setGraphData(createChartDataFromSphereEquivalent(dataToBuildGraph))
		}
	}, [
		dataToBuildGraph,
		scaleY,
	])

	useEffect(() => {
		if (!isUndefined(internalScaleY) && !isUndefined(dataToBuildGraph)) {
			setMyopiaPresentZoneY(
				setMyopiaY(dataToBuildGraph.zones, internalScaleY.min),
			)
		}
	}, [dataToBuildGraph, internalScaleY])
	const showStandard = graphData?.some(data => data.value !== undefined)

	return (
		<>
			{internalScaleY && graphData && (
				<GraphWithAxes
					graphData={graphData}
					yUnit={yUnit}
					scaleY={internalScaleY}
					legends={showLegends ? <SphereEquivalentLegend /> : undefined}
					labels={labels}
				>
					{showStandard && (
						<>
							<Line
								type="stepAfter"
								strokeWidth={6}
								stroke={Colors.MYOPIA_ON_SET_TRESHOLD}
								fill={Colors.MYOPIA_ON_SET_TRESHOLD}
								dataKey="standard"
								dot={false}
							/>
							<Line
								type="monotone"
								stroke={Colors.HORIZONTAL_ZERO}
								dot={false}
								fill={Colors.HORIZONTAL_ZERO}
								strokeWidth={4}
								dataKey="horizontalZero"
								legendType="none"
							/>
						</>
					)}
					<Line
						type="monotone"
						connectNulls
						stroke={Colors.VALUE}
						dataKey="value"
						fill={Colors.VALUE}
						strokeWidth={6}
						dot={true}
						legendType="none"
					/>

					<ReferenceArea
						id="noMyopia"
						x1={scaleX?.min}
						x2={scaleX?.max}
						y1={preMyopiaPresentZoneY?.max}
						y2={scaleY?.max}
						stroke={Colors.NO_MYOPIA_PRESENT}
						fill={Colors.NO_MYOPIA_PRESENT}
					/>
					<ReferenceArea
						id="preMyopia"
						x1={scaleX?.min}
						x2={scaleX?.max}
						y1={preMyopiaPresentZoneY?.max}
						y2={preMyopiaPresentZoneY?.min}
						stroke={Colors.PRE_MYOPIA_POSSIBLE}
						fill={Colors.PRE_MYOPIA_POSSIBLE}
					/>
					<ReferenceArea
						id="myopia"
						x1={scaleX?.min}
						x2={scaleX?.max}
						y1={myopiaPresentZoneY?.min}
						y2={myopiaPresentZoneY?.max}
						stroke={Colors.MYOPIA_PRESENT}
						fill={Colors.MYOPIA_PRESENT}
					/>
				</GraphWithAxes>
			)}
		</>
	)
}

export default memo(SphereEqGraph)
