import React from 'react'
import styled from 'styled-components'

import { hexToRgba, pxToRem } from '../libs/style'
import theme from './theme'
import { useCall } from '@azure/communication-react'

const Wrapper = styled.div`
	padding: ${pxToRem(2)}rem ${pxToRem(theme.spacing(1))}rem;
	border-radius: 2rem;
	backdrop-filter: blur(${pxToRem(20)}rem);
	background-color: ${hexToRgba(theme.palette.secondary.light, 0.13)};
	color: ${theme.palette.secondary.light};
	display: flex;
	align-items: center;
	gap: ${pxToRem(theme.spacing(1))}rem;
	text-transform: uppercase;
	font-size: 1.8vh;

	&.success {
		background-color: ${hexToRgba(theme.palette.success.main, 0.13)};
		color: ${theme.palette.success.main};
	}
`

type Props = {
	label: string
	color?: 'primary' | 'secondary' | 'success' | 'error'
	icon?: JSX.Element
}

const VideoCallStateChip: React.FC<Props> = ({ label, color, icon }) => {
	const call = useCall()

	return (
		<Wrapper
			data-testid={
				call?.state === 'Connected'
					? 'video-call-state-live'
					: 'video-call-state-onHold'
			}
			className={color ? color : ''}
		>
			{icon}
			{label}
		</Wrapper>
	)
}

export default VideoCallStateChip
