import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Features } from '../../model/feature'

const initialState: Features = { }

export const slice = createSlice({
	name: 'features',
	initialState,
	reducers: {
		setFeatures: (state, { payload }: PayloadAction<Features>) => {
			return { ...payload }
		},
		clearExamData:() => {
			return { }
		}
	},
})

export default slice.reducer
