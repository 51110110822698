import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectInDisplayContent } from '../../features/displayContent/selectors'
import { VisualFieldsContent } from '../../model/model'
import {
	FirstLine,
	ImagesBox,
	SecondLine,
	ThirdLine,
	TitleWrapper,
	Wrapper,
} from './CommonComponents'
import MediaBox from './MediaBox'

const isVisualFieldsContent = (content?: any): content is VisualFieldsContent =>
	content?.section === 'visualFields'

export const VisualFields: React.FC = () => {
	const { t } = useTranslation()
	const displayContent = useSelector(selectInDisplayContent)

	const data = displayContent?.data
	return (
		<Wrapper>
			<TitleWrapper>
				<FirstLine>{t('content.clinicalData')}</FirstLine>
				<SecondLine>{t('content.visualFields')}</SecondLine>
				<ThirdLine>{t('content.visualFieldsInfo')}</ThirdLine>
			</TitleWrapper>
			{isVisualFieldsContent(data) && (
				<ImagesBox className="vertical">
					<MediaBox media={data.content.OD} />
					<MediaBox media={data.content.OS} />
				</ImagesBox>
			)}
		</Wrapper>
	)
}

export default VisualFields
