import { Exam } from '../model/exam'

export const isNotEmpty = (obj: Record<string, unknown>): boolean => {
	// debugger
	const keys = Object.keys(obj)
	return Object.values(obj).some((value, index) => {
		const key = keys && keys[index]
		// debugger
		const notEmpty =
			key && ['accuracy'].includes(key)
				? false
				: Array.isArray(value)
				? value.some(item => isNotEmpty(item))
				: value === null
				? false
				: typeof value === 'object'
				? isNotEmpty(value as Record<string, unknown>)
				: typeof value === 'string'
				? value.length !== 0
				: Boolean(value)
		return notEmpty
	})
}

export const getCompletedSteps = (exam?: Exam) => {
	if (!exam?.preTest?.instruments) {
		return []
	}

	const possibleExamStep = [
		'autorefraction',
		'tonometer',
		'visualFields',
		'slitLamp',
		'retinalImaging',
	]
	const instrumentsExamSteps = Object.entries(exam.preTest.instruments)

	if (instrumentsExamSteps) {
		const filteredinstrumentsExamSteps = instrumentsExamSteps
			.filter(
				([key, data]) => isNotEmpty(data) && possibleExamStep.includes(key),
			)
			.map(([key]) => key)

		return filteredinstrumentsExamSteps
	}

	return []
}
