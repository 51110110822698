import {
	AgeAndValue,
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
} from './components/content/graphs/interfaces'
import config from './config'
import { fetchJson, getCommonHeaders } from './libs/fetchJson'
import { LoginResponse } from './model/auth'
import { AzureLogger } from './model/azureCommunication'
import { ConnectCoreMediaContent } from './model/coremedia'
import { Exam, ExamApi, ExamDif, WithExamId } from './model/exam'
import { GetFeaturesResponseApi } from './model/feature'
import {
	GetInstrumentDataResponseApi,
	InstrumentType,
} from './model/instruments'
import { Id } from './model/model'
import { CockpitContent } from './model/prescription'
import { Store } from './model/store'
import { ApiUser, ProfilePhotoApi } from './model/user'
import store from './store'

const routes = {
	exams: 'exams',
	features: 'features',
	log: 'log',
	login: 'login',
	logout: 'logout',
	stores: 'stores',
	users: 'users',
	coremedia: 'coremedia',
	myopia: 'myopia',
}

export const logToApi = (
	error: Error,
	errorInfo?: { componentStack: string },
) => {
	const state = store.getState()
	const currentUrl = window.location.href

	return fetch(`${config.apiUrl}/log`, {
		method: 'POST',
		headers: getCommonHeaders({ addContentType: true }),
		body: JSON.stringify({
			errorMessage: error.toString(),
			errorStack: error.stack?.toString(),
			errorInfo: errorInfo?.componentStack,
			currentUrl,
			state,
		}),
	})
}

export const getLoginTokenApi = (code: string) =>
	fetchJson<LoginResponse>(
		`${config.apiUrl}/${routes.login}`,
		{
			credentials: 'include',
			method: 'POST',
			headers: {
				accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({ code, redirectUri: config.login.redirectUri, cockpit: true }),
		},
		{
			messageKey: 'errors.login',
		},
		(data: any): data is LoginResponse =>
			typeof data === 'object' && 'token' in data,
	)

export const getUserByUsernameApi = (username: string) =>
	fetchJson<ApiUser>(
		`${config.apiUrl}/${routes.users}/find/${username}`,
		undefined,
		{
			messageKey: 'errors.fetchingUser',
		},
	)

export const logoutApi = () =>
	fetchJson<void>(`${config.apiUrl}/${routes.logout}`, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({}),
	})

export const getExam = (examId: Id) => {
	return fetchJson<ExamApi>(
		`${config.apiUrl}/${routes.exams}/${examId}`,
		undefined,
	)
}

export const getExamDif = (examId: Id) => {
	return fetchJson<ExamDif>(
		`${config.apiUrlV3}/${routes.exams}/${examId}/dif`,
		undefined,
	)
}

export const getStoreApi = (id: Id) =>
	fetchJson<Store>(`${config.apiUrl}/${routes.stores}/${id}`, {})

export const getInstrumentDataApi = (
	examId: Id,
	instrumentType: InstrumentType,
) =>
	fetchJson<GetInstrumentDataResponseApi>(
		`${config.apiUrlV2}/${routes.exams}/${examId}/instruments-data/${instrumentType}`,
		{},
	)

export const getPrescriptionAssets = () =>
	fetchJson<CockpitContent[]>(`${config.apiUrlV2}/cockpit-content`, {
		method: 'GET',
	})

export const getProfilePhotoApi = (username: string) =>
	fetchJson<ProfilePhotoApi>(
		`${config.apiUrl}/${routes.users}/${username}/profile-photo`,
		{},
	)

export const logAzureCallApi = (log: AzureLogger) =>
	fetch(`${config.apiUrl}/${routes.log}/azure`, {
		headers: getCommonHeaders({ addContentType: true }),
		method: 'POST',
		body: JSON.stringify(log),
	})

export const updateExamApi = (examId: Id, payload: Partial<ExamApi>) =>
	fetch(`${config.apiUrl}/${routes.exams}/${examId}`, {
		headers: getCommonHeaders({ addContentType: true }),
		method: 'PUT',
		body: JSON.stringify(payload),
	})

export const getFeaturesApi = () =>
	fetchJson<GetFeaturesResponseApi>(
		`${config.apiUrlV2}/${routes.features}/patient`,
		{},
	)

export const getCoreMediaContentAPI = () => {
	return fetchJson<ConnectCoreMediaContent>(
		`${config.apiUrlV2}/${routes.coremedia}`,
		{},
	)
}

export const getPatientExams = (patientId: Id | undefined) => {
	return fetchJson<Exam[]>(
		`${config.apiUrl}/${routes.exams}${
			patientId ? `?internalPatientId=${patientId}` : ''
		}`,
		{},
	)
}

export const getHistoricalExamsDataByType = async (
	internalPatientId: string,
	examType: InstrumentType | 'BI',
) => {
	return fetchJson<{ data: WithExamId<GetInstrumentDataResponseApi>[] }>(
		`${config.apiUrlV2}/${routes.exams}/historical-data/${internalPatientId}/${examType}`,
		{
			method: 'GET',
		},
	)
}

export const retrieveSphereEquivalent = async (values: AgeAndValue[]) => {
	return fetchJson<{ data: CalculatedSphereEqData }>(
		`${config.apiUrlV2}/${routes.myopia}/calculate-sphere-equivalent`,
		{
			method: 'POST',
			body: JSON.stringify({ values }),
		},
	)
}

export const retrieveAxialLength = async ({
	gender: genderParam,
	ethnicity: ethnicityParam,
	values,
}: {
	values: AgeAndValue[]
	gender?: string
	ethnicity?: string
}) => {
	const gender = ['female', 'male'].includes(genderParam ?? '')
		? genderParam?.toLocaleLowerCase()
		: 'male'
	const ethnicity = ethnicityParam ? ethnicityParam : 'other'
	return fetchJson<{ data: CalculatedAxialLengthData }>(
		`${config.apiUrlV2}/${routes.myopia}/calculate-axial-length`,
		{
			method: 'POST',
			body: JSON.stringify({ ethnicity, gender, values }),
		},
	)
}
