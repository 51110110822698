import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import BackToLoginBtn from '../../components/BackToLoginBtn'
import IconButton from '../../styleguide/buttons/IconButton'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import Snackbar from '../../styleguide/Snackbar'
import notificationsActions from './actions'
import { selectNotifications } from './selectors'

const NotificationArea = () => {
	const notifications = useSelector(selectNotifications)
	const dispatch = useDispatch()
	return (
		<>
			{notifications.map(({ message, closable = true, _id, backToLoginCta }) => (
				<Snackbar
					key={_id}
					open={true}
					message={message}
					action={
						<React.Fragment>
							{closable && <IconButton
								size="small"
								aria-label="close"
								data-testid="close-button"
								onClick={() =>
									dispatch(notificationsActions.removeNotification(_id))
								}
							>
								<CloseIcon />
							</IconButton>}
							{backToLoginCta && <BackToLoginBtn variant="text" />}
						</React.Fragment>
					}
				/>
			))}
		</>
	)
}

export default NotificationArea
