import { throttle } from 'lodash'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectExamData } from '../features/exam/selectors'
import {
	connectoToScreenshare,
	sendScreenshot as sendScreenshotAction,
} from '../socket/screenshare/screenshareActions'

const useScreenshare = () => {
	const dispatch = useDispatch()
	const observerRef = useRef<MutationObserver>()
	const exam = useSelector(selectExamData)

	useEffect(() => {
		const examId = exam?._id
		if (!examId) {
			return
		}

		dispatch(connectoToScreenshare(examId))
	}, [dispatch, exam])

	const sendScreenshot = useCallback(
		throttle(() => {
			dispatch(sendScreenshotAction())
		}, 1000),
		[dispatch],
	)

	useEffect(() => {
		const targetNode = document.body
		const config = { attributes: true, childList: true, subtree: true }
		observerRef.current = new MutationObserver(sendScreenshot)
		observerRef.current.observe(targetNode, config)
	}, [sendScreenshot])
}

export default useScreenshare
