import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'
import theme from '../../../styleguide/theme'
import { Legends } from './interfaces'
import { Colors } from './SphereEquivalentGraph/constant'

export const GraphBinoWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	border: ${pxToRem(1)}rem;
	border-style: solid;
	border-color: #ffffff;
	border-radius: ${pxToRem(theme.spacing(2))}rem;
	background: linear-gradient(
		205deg,
		rgba(206, 218, 242, 0.25),
		rgba(108, 166, 250, 0.25)
	);
	padding-top: ${pxToRem(theme.spacing(2))}rem;
	padding-bottom: ${pxToRem(theme.spacing(1))}rem;
`

export const GraphCardWrapper = styled.div<{
	border: boolean
	$zoomgraph?: boolean
}>`
	height: ${({ $zoomgraph }) =>
		$zoomgraph ? '85%' : `${pxToRem(theme.spacing(60))}rem`};
	padding: ${pxToRem(theme.spacing(2))}rem;
	${({ border }) =>
		border &&
		`
    background-color: #F9FBFE;
    border-radius: ${pxToRem(theme.spacing(2))}rem;
    margin: ${pxToRem(theme.spacing(2))}rem;
    clip-path: url(#clip);
  `}

	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		height: ${({ $zoomgraph }) => $zoomgraph ? '85%' : `${pxToRem(theme.spacing(45))}rem`};
	}
`

export const GraphSummaryWrapper = styled.div<{ addPaddingTop?: boolean }>`
	overflow: hidden;
	height: 100%;
	${props => props.addPaddingTop ? `padding-top: ${pxToRem(theme.spacing(0.5))}rem;` : ''}

	> div {
		margin-top: -1px;
	}
`

export const EyeLabel = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	border-radius: 50%;
	height: ${pxToRem(theme.spacing(8))}rem;
	width: ${pxToRem(theme.spacing(8))}rem;
	background: linear-gradient(205deg, #cedaf2, #6ca6fa);
	margin: ${pxToRem(theme.spacing(1.5))}rem;
	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		height: ${pxToRem(theme.spacing(7))}rem;
		width: ${pxToRem(theme.spacing(7))}rem;
		margin: ${pxToRem(theme.spacing(1))}rem;
	}
`

export const EyeLabelFirstLine = styled.p`
	font-size: ${pxToRem(theme.spacing(2.75))}rem;
	font-style: italic;
	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		font-size: ${pxToRem(theme.spacing(2.25))}rem;
	}
`

export const EyeLabelSecondLine = styled.p`
	font-size: ${pxToRem(theme.spacing(1.125))}rem;
`

export const GraphWrapper = styled.div<{ fullHeight: boolean }>`
	height: ${props => (props.fullHeight ? '100%' : '80%')};
`

export const LegendWrapper = styled.div`
	margin-top: ${pxToRem(theme.spacing(2))}rem;
	margin-bottom: ${pxToRem(theme.spacing(2))}rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		margin-top: ${pxToRem(theme.spacing(1.5))}rem;
		margin-bottom: ${pxToRem(theme.spacing(1.5))}rem;
	}
`

export const LegendItemWrapper = styled.div`
	display: flex;
	flex-direction: row;
	padding-left: ${pxToRem(theme.spacing(6))}rem;
	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		padding-left: ${pxToRem(theme.spacing(3))}rem;
	}
`

export const LegendItemColor = styled.div<Legends>(
	({ color, isLine }) =>
		`
    background-color: ${color};
    height: ${isLine ? `${pxToRem(1)}rem` : `${pxToRem(theme.spacing(3))}rem`};
    margin-right: ${pxToRem(theme.spacing(1))}rem;
    display: block;
    width: ${pxToRem(theme.spacing(3))}rem;
    border: ${pxToRem(1)}rem;
    border-color: ${color === Colors.NO_MYOPIA_PRESENT ? 'gray' : color};
    align-self: center;
    border-style: solid;
    border-radius: ${pxToRem(theme.spacing(0.5))}rem;
  `,
)

export const LegendText = styled.div`
	font-size: ${pxToRem(theme.spacing(2))}rem;
	line-height: ${pxToRem(theme.spacing(3))}rem;
	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		font-size: ${pxToRem(theme.spacing(1.5))}rem;
		line-height: ${pxToRem(theme.spacing(2))}rem;
	}
`
