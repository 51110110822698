import notificationsActions from '../../features/notifications/actions'
import { AddNotificationPayload, Id } from '../../model/model'
import { TeloDispatch, TeloGetState } from '../../store'
import {
	AddNotificationFn,
	FindNotificationFn,
	RemoveNotificationFn,
} from '../teloSocketTypes'
import { connectToSocketRoom } from './roomSocket'
import { selectNotification } from '../../features/notifications/selectors'
import {
	CheckExamInRoomFn,
	ClearDisplayContentFn,
	FetchInstrumentDataFn,
	SetContentFn,
	SetDisplayContentFn,
	SetDisplayFullscreenFn,
	SetExamDataFn,
	SetLoopTimesFn,
	SetPlayingFn,
} from './roomSocketTypes'
import examActions from '../../features/exam/actions'
import displayContentActions from '../../features/displayContent/actions'
import { selectUsername } from '../../features/auth/selectors'
import { disconnectTeloSocket } from '../teloSocketRegistry'

type ConnectToRoomArgs = {
	roomName: string
	storeId?: string
	examId?: Id
}

export const connectToRoom =
	({ examId, roomName, storeId }: ConnectToRoomArgs) =>
	(dispatch: TeloDispatch, getState: TeloGetState) => {
		const getUserName = () => selectUsername(getState())

		const addNotification: AddNotificationFn = (
			notification: AddNotificationPayload,
		) => {
			dispatch(notificationsActions.addNotification(notification))
		}
		const findNotification: FindNotificationFn = (notificationId: string) =>
			selectNotification(notificationId)(getState()) ?? null

		const removeNotification: RemoveNotificationFn = (
			notificationId: string,
		) => {
			dispatch(notificationsActions.removeNotification(notificationId))
		}
		const checkExamInRoom: CheckExamInRoomFn = storeId => {
			dispatch(examActions.checkExamInRoom(storeId, getUserName()))
		}
		const clearDisplayContent: ClearDisplayContentFn = () => {
			dispatch(displayContentActions.clearDisplayContent())
		}
		const fetchInstrumentData: FetchInstrumentDataFn = (examId, payload) => {
			dispatch(displayContentActions.fetchInstrumentData(examId, payload))
		}

		const setContent: SetContentFn = payload => {
			dispatch(displayContentActions.setContent(payload))
		}
		const setDisplayContent: SetDisplayContentFn = ({ type, data }) => {
			dispatch(displayContentActions.setDisplayContent({ type, data }))
		}
		const setDisplayFullscreen: SetDisplayFullscreenFn = (
			fullscreen: boolean,
		) => {
			dispatch(displayContentActions.setFullscreen(fullscreen))
		}
		const setExamData: SetExamDataFn = examId => {
			dispatch(
				examActions.setExamData({
					examId,
					cockpitTechnician: getUserName(),
				}),
			)
		}
		const setLoopTimes: SetLoopTimesFn = ({ endTime, startTime }) => {
			dispatch(displayContentActions.setLoopTimes({ startTime, endTime }))
		}
		const setPlaying: SetPlayingFn = ({
			playing,
			currentTime,
			isFullscreen,
		}) => {
			dispatch(
				displayContentActions.setPlaying({
					playing,
					currentTime,
					isFullscreen,
				}),
			)
		}

		connectToSocketRoom({
			addNotification,
			checkExamInRoom,
			clearDisplayContent,
			examId,
			fetchInstrumentData,
			findNotification,
			removeNotification,
			roomName,
			setContent,
			setDisplayContent,
			setDisplayFullscreen,
			setExamData,
			setLoopTimes,
			setPlaying,
			storeId,
		})
	}

export const disconnectFromRoom = (roomName: string) => () => {
	disconnectTeloSocket(roomName)
}
