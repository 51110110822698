import React from 'react'
import { Grid } from '@material-ui/core'
import { GraphCardWrapper } from '../graph.style'
import AxialLengthGraph from '.'
import { CalculatedAxialLengthData, NormativeTable, Scale } from '../interfaces'
import AxialLengthLegend from './AxialLengthLegend'

type Props = {
	scaleY: Scale | undefined
	calculatedAxialLengthData: CalculatedAxialLengthData
	zoomGraph?: boolean
	gender?: string
	ethnicity?: NormativeTable
}

const AxialLengthGraphMono: React.FC<Props> = ({
	scaleY,
	calculatedAxialLengthData,
	zoomGraph = false,
	ethnicity,
	gender,
}) => {
	const renderGraph = (
		<GraphCardWrapper border={false} $zoomgraph={zoomGraph}>
			<AxialLengthGraph
				scaleY={scaleY}
				calculatedValues={calculatedAxialLengthData}
				ethnicity={ethnicity}
				gender={gender}
			/>
		</GraphCardWrapper>
	)

	const renderLegend = <AxialLengthLegend />

	return !zoomGraph ? (
		<>
			<Grid item xs={12}>
				{renderGraph}
			</Grid>
			<Grid item xs={12}>
				{renderLegend}
			</Grid>
		</>
	) : (
		<>
			{renderGraph}
			{renderLegend}
		</>
	)
}

export default AxialLengthGraphMono
