import { Id } from '../model/model'
import { generateUID } from './uuid'

const APP_LOGIN_TOKEN = 'tel-cockpit-login-token'

export const setIntersessionAppLoginData = (
	token: string,
) => {
	window.localStorage.setItem(APP_LOGIN_TOKEN, token)
}

export const getIntersessionToken = (): string | undefined => {
	const loginData = window.localStorage.getItem(APP_LOGIN_TOKEN)
	return loginData || undefined
}

export const delIntersessionToken = () => {
	window.localStorage.removeItem(APP_LOGIN_TOKEN)
}

const SESSION_ID = 'tel-cockpit-session-id'

export const createSessionId = () => {
	const sessionId = generateUID()
	window.sessionStorage.setItem(SESSION_ID, sessionId)
	return sessionId
}

export const getSessionId = () => {
	return window.sessionStorage.getItem(SESSION_ID)
}

const STORE_AND_ROOM_KEY = 'tel-cockpit-store-and-room'

export const setIntersessionStoreIdAndRoomId = (storeId: Id, roomId: Id) => {
	window.localStorage.setItem(STORE_AND_ROOM_KEY, JSON.stringify({ storeId, roomId }))
}

export const getIntersessionStoreIdAndRoomId = (): { storeId: Id, roomId: Id } | null => {
	const savedData = window.localStorage.getItem(STORE_AND_ROOM_KEY)
	return savedData && JSON.parse(savedData)
}

export const delIntersessionStoreIdAndRoomId = () => window.localStorage.removeItem(STORE_AND_ROOM_KEY)

export const delIntersessionInfo = () => {
	delIntersessionToken()
	delIntersessionStoreIdAndRoomId()
}
