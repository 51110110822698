import { memo, useEffect, useMemo, useState } from 'react'
import { Area, Line } from 'recharts'
import {
	CalculatedAxialLengthData,
	MinMax,
	AxialLengthGraphProps,
	AxialLengthGraphData,
} from '../interfaces'
import { GraphWithAxes } from '../GraphWithAxes'
import { Colors } from './constant'
import { createChartDataFromAxialLength, getMinMaxAxis } from './utils'
import { isUndefined } from 'lodash'
import { useTranslation } from 'react-i18next'
import AxialLengthLegend from './AxialLengthLegend'

export const AxialLengthGraph = ({
	yUnit,
	calculatedValues,
	showLegends,
	scaleY,
	ethnicity,
	gender,
}: AxialLengthGraphProps) => {
	const { t } = useTranslation()
	const [dataToBuildGraph, setDataToBuildGraph] = useState<
		CalculatedAxialLengthData | undefined
	>(undefined)

	const [graphData, setGraphData] = useState<AxialLengthGraphData[]>()
	const [internalScaleY, setInternalScaleY] = useState<MinMax | undefined>(
		scaleY,
	)

	const labels = useMemo(
		() => ({
			x: t('content.myopia.axialLengthAnalysis.graph.label.x'),
			y: t('content.myopia.axialLengthAnalysis.graph.label.y'),
		}),
		[t],
	)

	useEffect(() => {
		if (!isUndefined(calculatedValues)) {
			setDataToBuildGraph(calculatedValues as CalculatedAxialLengthData)
		}
	}, [calculatedValues])

	useEffect(() => {
		if (!isUndefined(dataToBuildGraph)) {
			if (!scaleY) {
				setInternalScaleY(
					getMinMaxAxis(
						dataToBuildGraph.values,
						dataToBuildGraph.percentiles,
						'y',
					),
				)
			} else {
				setInternalScaleY(
					getMinMaxAxis(
						[{ y: scaleY.min }, { y: scaleY.max }],
						dataToBuildGraph.percentiles,
						'y',
					),
				)
			}
			setGraphData(createChartDataFromAxialLength(dataToBuildGraph))
		}
	}, [dataToBuildGraph, setDataToBuildGraph, scaleY])

	const showGuideLines =
		!!calculatedValues?.values.length || (!!ethnicity && !!gender)

	return (
		<GraphWithAxes
			graphData={graphData}
			yUnit={yUnit}
			scaleY={internalScaleY}
			legends={showLegends ? <AxialLengthLegend /> : undefined}
			labels={labels}
		>
			<defs>
				<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
					<stop offset="1%" stopColor="#AD72E5" stopOpacity={0.4} />
					<stop
						offset="99%"
						stopColor="rgb(255, 255, 255, 255)"
						stopOpacity={0.1}
					/>
				</linearGradient>
			</defs>
			{showGuideLines && (
				<>
					<Area
						type="linear"
						connectNulls
						strokeWidth={4}
						stroke={Colors.C98}
						fill="url(#colorUv)"
						dataKey="c98"
						dot={false}
					/>

					<Line
						type="monotone"
						connectNulls
						strokeWidth={1}
						stroke={Colors.LINE}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c95"
						dot={false}
					/>
					<Line
						type="monotone"
						connectNulls
						strokeWidth={1}
						stroke={Colors.LINE}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c90"
						dot={false}
					/>
					<Line
						type="monotone"
						connectNulls
						strokeWidth={1}
						stroke={Colors.LINE}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c75"
						dot={false}
					/>

					<Line
						type="monotone"
						connectNulls
						strokeWidth={4}
						stroke={Colors.C50}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c50"
						dot={false}
					/>

					<Line
						type="monotone"
						connectNulls
						strokeWidth={1}
						stroke={Colors.LINE}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c25"
						dot={false}
					/>
					<Line
						type="monotone"
						connectNulls
						strokeWidth={1}
						stroke={Colors.LINE}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c10"
						dot={false}
					/>
					<Line
						type="monotone"
						connectNulls
						strokeWidth={1}
						stroke={Colors.LINE}
						fill="rgb(0, 0, 0, 0)"
						dataKey="c5"
						dot={false}
					/>

					<Area
						type="linear"
						connectNulls
						strokeWidth={4}
						stroke={Colors.C2}
						fill="rgb(255, 255, 255, 255)"
						dataKey="c2"
						dot={false}
					/>

					<Line
						type="monotone"
						connectNulls
						stroke={Colors.VALUE}
						dataKey="value"
						fill="rgb(0,0,0,0)"
						strokeWidth={6}
						dot={{ strokeWidth: 12, color: Colors.VALUE }}
						legendType="none"
					/>
				</>
			)}
		</GraphWithAxes>
	)
}

export default memo(AxialLengthGraph)
