import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { logToApi } from './apiCalls'
import notificationsActions from './features/notifications/actions'
import { selectNotifications } from './features/notifications/selectors'
import { Notification, RootState, TDispatch } from './model/model'
import ErrorPage from './pages/ErrorPage'

type Props = {
	notifications: Notification[]
	t: (x: string) => string
	addNotification: (notification: Notification) => void
}
class ErrorBoundary extends React.Component<Props, { errorMessage: string }> {
	constructor(props: Props) {
		super(props)
		this.state = { errorMessage: '' }
	}

	static getDerivedStateFromError(error: Error) {
		return { errorMessage: error.message }
	}

	componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
		logToApi(error, errorInfo)
	}

	render() {
		const isBlockingError = this.state.errorMessage

		return isBlockingError ? (
			<ErrorPage message={this.state.errorMessage} />
		) : (
			this.props.children
		)
	}
}

const mapStateToProps = (state: RootState) => ({
	notifications: selectNotifications(state),
})

const mapDispatchToProps = (dispatch: TDispatch) => ({
	addNotification: (notification: Notification) =>
		dispatch(notificationsActions.addNotification(notification)),
})

export default withTranslation()(
	connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary),
)
