import { createGlobalStyle } from 'styled-components'

import theme from './theme'
import { pxToRem } from '../libs/style'

const GlobalStyle = createGlobalStyle`
/* @font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-SemiBold.ttf') format('truetype'),
        url('/assets/fonts/SpaceGrotesk-SemiBold.svg#SpaceGrotesk-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'SpaceGrotesk';
    src: url('/assets/fonts/SpaceGrotesk-Medium.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Medium.ttf') format('truetype'),
        url('/assets/fonts/SpaceGrotesk-Medium.svg#SpaceGrotesk-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* @font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Light.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Light.ttf') format('truetype'),
        url('/assets/fonts/SpaceGrotesk-Light.svg#SpaceGrotesk-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
} */

@font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Bold.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Bold.ttf') format('truetype'),
        url('/assets/fonts/SpaceGrotesk-Bold.svg#SpaceGrotesk-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Space Grotesk';
    src: url('/assets/fonts/SpaceGrotesk-Regular.woff2') format('woff2'),
        url('/assets/fonts/SpaceGrotesk-Regular.ttf') format('truetype'),
        url('/assets/fonts/SpaceGrotesk-Regular.svg#SpaceGrotesk-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Ivar Display';
    src: url('/assets/fonts/IvarDisplay-Italic.woff2') format('woff2'),
        url('/assets/fonts/IvarDisplay-Italic.ttf') format('truetype'),
        url('/assets/fonts/IvarDisplay-Italic.svg#IvarDisplay-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir';
    src: url('/assets/fonts/AvenirLTPro-Medium.woff2') format('woff2'),
        url('/assets/fonts/AvenirLTPro-Medium.ttf') format('truetype'),
        url('/assets/fonts/AvenirLTPro-Medium.svg#AvenirLTPro-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
    
@font-face {
    font-family: 'Avenir';
    src: url('/assets/fonts/AvenirLTPro-Black.woff2') format('woff2'),
        url('/assets/fonts/AvenirLTPro-Black.ttf') format('truetype'),
        url('/assets/fonts/AvenirLTPro-Black.svg#AvenirLTPro-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
    
@font-face {
    font-family: 'Avenir';
    src: url('/assets/fonts/AvenirLTPro-Heavy.woff2') format('woff2'),
        url('/assets/fonts/AvenirLTPro-Heavy.ttf') format('truetype'),
        url('/assets/fonts/AvenirLTPro-Heavy.svg#AvenirLTPro-Heavy') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

html,
body,
#root {
    height: 100%;
    font-family: "Space Grotesk", Arial, sans-serif;
    color: ${theme.palette.primary.main};
  @media only screen
  and (max-device-width: 1366px)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) {
    font-size: ${pxToRem(14)}rem;
  }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: 0;
}

button {
  background-color: transparent;
  border: none;
  appearance: none;
  font: inherit;
}

strong {
  font-weight: 900;
}
`

export default GlobalStyle
