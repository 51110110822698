import { createSlice } from '@reduxjs/toolkit'

import { CursorState } from '../../model/model'

const initialState: CursorState = {
	cursor: false,
	position: { x: 0, y: 0 },
	key: 0,
	zoomPosition: { x: 0, y: 0 },
	zoomIndex: 0,
}

export const slice = createSlice({
	name: 'cursor',
	initialState,
	reducers: {
		_showCursor: (state) => {
			state.cursor = true
		},
		_setPosition: (state, { payload }) => {
			state.position = payload
		},
		_setKey: (state) => {
			state.key = state.key + 1
		},
		_setZoomPosition: (state, { payload }) => {
			state.zoomPosition = payload
		},
		_setZoomIndex: (state, { payload }) => {
			state.zoomIndex = payload
		},
		resetCursor: () => initialState,
	},
})

export default slice.reducer
