import React from 'react'
import { Grid } from '@material-ui/core'
import { GraphBinoWrapper } from '../graph.style'
import AxialLengthGraph from './'
import { CalculatedAxialLength, NormativeTable, Scale } from '../interfaces'
import { useTranslation } from 'react-i18next'
import AxialLengthLegend from './AxialLengthLegend'
import GraphCard from '../SphereEquivalentGraph/GraphCard'
import GraphCardClipPath from '../GraphCardClipPath'

type Props = {
	scaleY: Scale | undefined
	calculatedAxialLength: CalculatedAxialLength
	gender?: string
	ethnicity?: NormativeTable
}

const AxialLengthGraphBino: React.FC<Props> = ({
	scaleY,
	calculatedAxialLength,
	gender,
	ethnicity,
}) => {
	const { t } = useTranslation()

	return (
		<GraphBinoWrapper>
			<GraphCardClipPath />
			<GraphCard
				eyeLabel={{
					firstLine: 'OD',
					secondLine: t('app.ODEye'),
				}}
			>
				<AxialLengthGraph
					scaleY={scaleY}
					calculatedValues={calculatedAxialLength?.right}
					gender={gender}
					ethnicity={ethnicity}
				/>
			</GraphCard>
			<GraphCard
				eyeLabel={{
					firstLine: 'OS',
					secondLine: t('app.OSEye'),
				}}
			>
				<AxialLengthGraph
					scaleY={scaleY}
					calculatedValues={calculatedAxialLength?.left}
					gender={gender}
					ethnicity={ethnicity}
				/>
			</GraphCard>
			<Grid item xs={12}>
				<AxialLengthLegend />
			</Grid>
		</GraphBinoWrapper>
	)
}

export default AxialLengthGraphBino
