import { Divider } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	Badge,
	Card,
	ContentRowWrapper,
	GreyText,
	HeaderName,
	HeaderSection,
	HeaderTitle,
	UppercaseText,
} from './styles'

const ContentRow: React.FC<{ label: string; value: string }> = ({
	label,
	value,
}) => {
	return (
		<ContentRowWrapper>
			<GreyText>{label}</GreyText>
			<UppercaseText>{value}</UppercaseText>
		</ContentRowWrapper>
	)
}

type PatientAndDoctorDetailsProps = {
	isDoctor: boolean
	doctorFirstName?: string
	doctorSecondName?: string
	patientFirstName?: string
	patientSecondName?: string
	examType?: string
	patientBirthDate?: string
	numberOfVisits?: string
	storeName?: string
}

const PatientAndDoctorDetails: React.FC<PatientAndDoctorDetailsProps> = ({
	isDoctor,
	doctorFirstName,
	doctorSecondName,
	patientFirstName,
	patientSecondName,
	examType,
	patientBirthDate,
	numberOfVisits,
	storeName,
}) => {
	const { t } = useTranslation()

	return (
		<Card>
			<HeaderSection isDoctor={isDoctor}>
				<div>
					<HeaderTitle>
						{isDoctor
							? t('patientAndDoctorDetails.doctorTitle')
							: t('patientAndDoctorDetails.title')}
					</HeaderTitle>
					<HeaderName>
						{(patientFirstName || doctorFirstName || '') +
							' ' +
							(patientSecondName || doctorSecondName || '')}
					</HeaderName>
				</div>
				{!isDoctor && (
					<Badge>
						{patientFirstName ? patientFirstName[0] : ''}{' '}
						{patientSecondName ? patientSecondName[0] : ''}
					</Badge>
				)}
			</HeaderSection>
			{!isDoctor && (
				<>
					<ContentRow
						label={t('patientAndDoctorDetails.exam')}
						value={t(`patientAndDoctorDetails.${examType}`, {
							defaultValue: examType,
						})}
					/>
					<Divider />
					<ContentRow
						label={t('patientAndDoctorDetails.birthDate')}
						value={patientBirthDate || ''}
					/>
					<Divider />
					<ContentRow
						label={t('patientAndDoctorDetails.numberOfVisits')}
						value={numberOfVisits || ''}
					/>
					<Divider />
					<ContentRow
						label={t('patientAndDoctorDetails.store')}
						value={storeName || ''}
					/>
				</>
			)}
		</Card>
	)
}

export default PatientAndDoctorDetails
