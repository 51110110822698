import { FC } from 'react'
import { TitleWrapper } from '../CommonComponents'
import { MyopiaSummaryGraphCardContainer, TitleStyled } from './styles'

const MyopiaSummaryGraphCard: FC<{
	title: string
	titlePrefix?: string
	$borderBotRight?: boolean
	$borderBottom?: boolean
	$borderRight?: boolean
	$zoom?: boolean
}> = ({
	$borderBotRight,
	$borderRight,
	$borderBottom,
	$zoom,
	title,
	titlePrefix,
	children,
}) => {
	return (
		<MyopiaSummaryGraphCardContainer
			$borderBotRight={$borderBotRight}
			$borderRight={$borderRight}
			$borderBottom={$borderBottom}
			$zoom={$zoom}
		>
			<TitleWrapper>
				<TitleStyled>
					{title} <span>{titlePrefix}</span>
				</TitleStyled>
			</TitleWrapper>
			{children}
		</MyopiaSummaryGraphCardContainer>
	)
}

export default MyopiaSummaryGraphCard
