import React from 'react'

import {
	diseasesAndTreatmentsTypes,
	diseasesAndTreatmentsTypeValues,
	DisplayableContent,
	DisplayableContentType,
	myopiaTypeValues,
	prescriptionTypeOtherDataValues,
	TrendsOverTimeGraphData,
	trendsOverTimeValues,
} from '../model/model'
import {
	AnyPrescriptionType,
	PrescriptionContactLenses,
} from '../model/prescription'
import { CrystallineLens } from './content/CrystallineLens'
import Prescription, {
	PrescriptionCL,
	PrescriptionCLType,
} from './content/Prescription'
import PrescriptionOtherData from './content/PrescriptionOtherData'
import RetinalImaging from './content/RetinalImaging'
import Tonometry from './content/Tonometry'
import Topography from './content/Topography'
import VideoSlitLamp from './content/VideoSlitLamp'
import VisionSimulation from './content/VisionSimulation'
import VisualFields from './content/VisualFields'
import DiseaseTreatment from './content/DiseaseTreatment'
import SphereEquivalentGraphRightLeft from './content/graphs/SphereEquivalentGraph/SphereEquivalentGraphRightLeft'
import {
	CalculatedAxialLength,
	CalculatedSphereEquivalent,
	MyopiaSummaryZoomType,
} from './content/graphs/interfaces'
import AxialLengthGraphRightLeft from './content/graphs/AxialLengthGraph/AxialLengthGraphRightLeft'
import Refraction from './content/Refraction'
import { PhoropterDataPart } from '../model/refraction'
import { ClinicalContentSummary } from './content/clinicalContentSummary/ClinicalContentSummary'
import { ClinicalContentSummaryData } from './content/clinicalContentSummary/types'
import MyopiaSummaryGraphs from './content/MyopiaSummary/MyopiaSummaryGraphs'
import MyopiaSummaryZoom from './content/MyopiaSummary/MyopiaSummaryZoom'
import TrendsOverTimeGraphs from './content/TrendOverTime/TrendsOverTimeGraphs'
import TrendsOverTimeGraphZoom from './content/TrendOverTime/TrendsOverTimeGraphZoom'

const isPrescriptionData = (
	data?: any,
): data is { content: AnyPrescriptionType } =>
	data &&
	[
		'prescription-general-use',
		'prescription-sunglasses',
		'prescription-computer',
		'prescription-reading',
		'prescription-night',
		'prescription-sport',
		'prescription-safety',
		...prescriptionTypeOtherDataValues,
	].includes(data.section)

const isContactLensesData = (
	data?: any,
): data is { content: PrescriptionContactLenses } =>
	data &&
	[
		'prescription-contact-lenses',
		'prescription-contact-lenses-select',
	].includes(data.section)

const isMyopiaCareSolutionData = (
	data?: any,
): data is { content: CalculatedSphereEquivalent | CalculatedAxialLength } =>
	data && [...myopiaTypeValues].includes(data.section)

const isMyopiRefractionData = (
	data?: any,
): data is { content: PhoropterDataPart } =>
	data && ['myopia-refraction'].includes(data.section)

const isMyopiaSummaryZoom = (
	data?: any,
): data is { content: MyopiaSummaryZoomType } =>
	data && [...myopiaTypeValues].includes(data.section)

const isTrendsOverTimeData = (
	data?: any,
): data is { content: TrendsOverTimeGraphData } =>
	data &&
	!data?.content?.instrument &&
	[...trendsOverTimeValues].includes(data.section)
const isTrendsOverTimeZoomData = (
	data?: any,
): data is { content: TrendsOverTimeGraphData } =>
	data &&
	!!data?.content?.instrument &&
	[...trendsOverTimeValues].includes(data.section)

const ContentDisplay: React.FC<{
	type?: DisplayableContentType
	data?: DisplayableContent
}> = ({ type, data }) => {
	if (!type) return null
	if (
		diseasesAndTreatmentsTypes.includes(type) ||
		diseasesAndTreatmentsTypeValues.includes(type)
	) {
		return <DiseaseTreatment type={type} />
	}
	switch (type) {
		case 'prescription-general-use':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'GeneralUse'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'prescription-contact-lenses':
			return isContactLensesData(data) ? (
				<PrescriptionCL
					prescriptionType={'app.contactLenses'}
					data={data.content}
				/>
			) : null
		case 'prescription-contact-lenses-select':
			return isContactLensesData(data) ? (
				<PrescriptionCLType
					prescriptionType={'app.contactLenses'}
					data={data.content}
				/>
			) : null
		case 'prescription-sunglasses':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'Sunglasses'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'prescription-computer':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'Computer'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'prescription-reading':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'Reading'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'prescription-night':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'Night'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'prescription-sport':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'Sport'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'prescription-safety':
			return isPrescriptionData(data) ? (
				<Prescription
					prescriptionType={'Safety'}
					accuracy={data.content.accuracy}
					lensData={data.content.LensData}
				/>
			) : null
		case 'topography':
			return <Topography />
		case 'crystallineLens':
			return <CrystallineLens />
		case 'tonometer':
			return <Tonometry />
		case 'videoSlitLamp':
			return <VideoSlitLamp />
		case 'retinalImaging':
			return <RetinalImaging />
		case 'visualFields':
			return <VisualFields />
		case 'visionSimulation':
			return <VisionSimulation />
		case 'prescription-general-use-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData
					prescriptionType={'GeneralUse'}
					data={data.content}
				/>
			) : null
		case 'prescription-sunglasses-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData
					prescriptionType={'Sunglasses'}
					data={data.content}
				/>
			) : null
		case 'prescription-computer-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData
					prescriptionType={'Computer'}
					data={data.content}
				/>
			) : null
		case 'prescription-reading-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData
					prescriptionType={'Reading'}
					data={data.content}
				/>
			) : null
		case 'prescription-night-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData prescriptionType={'Night'} data={data.content} />
			) : null
		case 'prescription-sport-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData prescriptionType={'Sport'} data={data.content} />
			) : null
		case 'prescription-safety-other':
			return isPrescriptionData(data) ? (
				<PrescriptionOtherData
					prescriptionType={'Safety'}
					data={data.content}
				/>
			) : null
		case 'myopia-refraction-analysis':
			return isMyopiaCareSolutionData(data) ? (
				<SphereEquivalentGraphRightLeft
					calculatedSphereEquivalent={
						data.content as CalculatedSphereEquivalent
					}
				/>
			) : null
		case 'myopia-axial-length-analysis':
			return isMyopiaCareSolutionData(data) ? (
				<AxialLengthGraphRightLeft
					calculatedAxialLength={data.content as CalculatedAxialLength}
				/>
			) : null
		case 'myopia-refraction':
			return isMyopiRefractionData(data) ? (
				<Refraction phoropter={data.content} />
			) : null
		case 'myopia-summary':
			return <MyopiaSummaryGraphs />
		case 'myopia-summary-zoom':
			return isMyopiaSummaryZoom(data) ? (
				<MyopiaSummaryZoom data={data.content as MyopiaSummaryZoomType} />
			) : null
		case 'clinical-content-summary':
			return data ? (
				<ClinicalContentSummary
					data={data.content as unknown as ClinicalContentSummaryData}
				/>
			) : null

		case 'trends-over-time':
			return isTrendsOverTimeData(data) ? (
				<TrendsOverTimeGraphs data={data.content as TrendsOverTimeGraphData} />
			) : null
		case 'trends-over-time-zoom':
			return isTrendsOverTimeZoomData(data) ? (
				<TrendsOverTimeGraphZoom
					data={data.content as TrendsOverTimeGraphData}
				/>
			) : null
		default:
			return <div>unsupported display type: {type}</div>
	}
}

export default ContentDisplay
