import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import authActions from './features/auth/actions'
import { selectAuthToken, selectUsername } from './features/auth/selectors'
import { TDispatch } from './model/model'
import LoginPage from './pages/LoginPage'
import SelectRoomPage from './pages/SelectRoomPage'
import Routes from './Routes'
import { selectRoomId, selectStoreId } from './features/settings/selectors'

const onUnload = () => {
	return
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = () => {
	const token = useSelector(selectAuthToken)
	const dispatch: TDispatch = useDispatch()
	const username = useSelector(selectUsername)
	const storeId = useSelector(selectStoreId)
	const roomId = useSelector(selectRoomId)

	useEffect(() => {
		if (!username) {
			return
		}

		dispatch(authActions.fetchUser(username))
	}, [dispatch, username])

	useEffect(() => {
		window.addEventListener('unload', onUnload)
		return () => window.removeEventListener('unload', onUnload)
	}, [])

	if (!token) {
		return <LoginPage />
	} else if (!storeId || !roomId) {
		return <SelectRoomPage />
	} else {
		return <Routes />
	}
}

export default App
