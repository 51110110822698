import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
	createSessionId,
	getIntersessionToken,
	getSessionId,
} from '../../libs/intersession'
import { AuthState } from '../../model/auth'
import { Store } from '../../model/store'
import { User } from '../../model/user'

const initialState: AuthState = {
	token: getIntersessionToken() || '',
	sessionId: getSessionId() || createSessionId(),
}

export const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAppToken: (state, { payload }: PayloadAction<string>) => {
			state.token = payload
		},
		_setUser: (state, { payload }: PayloadAction<User>) => {
			state.user = payload
		},
		resetState: () => ({
			token: '',
			user: undefined,
			sessionId: getSessionId() || createSessionId(),
		}),
		replaceStoreData: (state, { payload }: PayloadAction<Store>) => {
			const storeCoop = state.user?.stores.find(
				s => s.store._id === payload._id,
			)
			if (storeCoop) storeCoop.store = payload
		},
	},
})

export default slice.reducer
