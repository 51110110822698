import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import authActions from '../features/auth/actions'
import { pxToRem } from '../libs/style'
import Modal from '../styleguide/Modal'
import theme from '../styleguide/theme'

const Message = styled.p`
  font-family: "Ivar Display";
  font-style: italic;
  font-size: ${theme.typography.fontSizes.L};
  margin-bottom: ${pxToRem(theme.spacing(7))}rem;
`

const LogoutModal: React.FC<{ open: boolean, onClose?: () => void }> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t('login.signOut')}
      cancelLabel={t('app.cancel')}
      confirmLabel={t('app.confirm')}
      onConfirm={() => dispatch(authActions.logout())}
      Content={<Message>{t('login.logoutMessage')}</Message>}
    />
  )
}

export default LogoutModal
