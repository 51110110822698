import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'

const EyeInfoRoot = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	background-color: white;
	margin: 0 0 ${pxToRem(10)}rem 0;
`

const EyeInfoValue = styled.span`
	font-size: 1.3rem;
	background-color: white;
`

const EyeInfoLabel = styled.span`
	color: #62a5ff;
	background-color: white;
	white-space: nowrap;
	font-size: 0.8rem;
`

type EyeInfoProps = {
	className?: string
	label: string
	value?: string
}

export function EyeInfo({
	className,
	label,
	value,
}: EyeInfoProps): JSX.Element {
	return (
		<EyeInfoRoot className={className}>
			<EyeInfoValue className="eye-info-value">{value || '-'}</EyeInfoValue>
			<EyeInfoLabel className="eye-info-label">{label}</EyeInfoLabel>
		</EyeInfoRoot>
	)
}
