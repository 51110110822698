import { isSocketConnected } from '../teloSocketRegistry'
import { TeloSocket } from '../teloSocketTypes'
import { io, Socket } from 'socket.io-client'
import appConfig from '../../config'
import { Id } from '../../model/model'
import { newTeloSocketConnector } from '../teloSocketConnector'
import { SetZoomIndexFn, ShowCursorFn, WithBody } from './screenshareTypes'

const NEW_SCREENSHOT_EVENT = 'new_screenshot'
const NEW_CURSOR_EVENT = 'new_cursor_click'
const NEW_ZOOM_EVENT = 'new_zoom_click'

export type ScreenshareSocketArgs = {
	examId: Id
	showCursor: ShowCursorFn
	setZoomIndex: SetZoomIndexFn
}

export type ScreenshareSender = {
	sendScreenshot: () => void
}

export type ScreenshareSocket = TeloSocket & ScreenshareSender

const newSocket = ({
	examId,
	showCursor,
	setZoomIndex,
}: ScreenshareSocketArgs): ScreenshareSocket => {
	let socket: Socket | null = null

	const connect = () => {
		if (isSocketConnected(socket)) {
			return
		}

		socket = io(appConfig.socketUrl, {
			query: { roomName: getSocketKey(examId) },
			transports: ['websocket'],
		})

		socket.on('connect', sendScreenshot)
		socket.on('request_screenshot', sendScreenshot)
		socket.on(NEW_CURSOR_EVENT, (payload: WithBody) => {
			showCursor(payload)
		})
		socket.on(NEW_ZOOM_EVENT, (payload: WithBody) => {
			setZoomIndex(payload)
		})
	}

	const disconnect = () => {
		if (!isSocketConnected(socket)) {
			return
		}
		socket!.disconnect()
		socket = null
	}

	const sendScreenshot = () => {
		if (!isSocketConnected(socket)) {
			return
		}

		const payload = {
			head: fixPaths(removeScripts(addExplicitStyle(document.head))),
			body: fixPaths(removeScripts(document.body.outerHTML)),
		}
		socket!.emit(NEW_SCREENSHOT_EVENT, payload)
	}

	return { connect, disconnect, sendScreenshot }
}

const fixPaths = (html: string) => {
	return html.replace(
		/(href="\/|src="\/|poster="\/|url\(\/'|url\("\/|url\(\/)/g,
		substr =>
			`${substr.replace(/\/$/, '')}${window.location.origin.replace(
				/\/$/,
				'',
			)}/`,
	)
}

const removeScripts = (html: string) => {
	return html.replace(/<script>.+<\/script>/g, '')
}

const addExplicitStyle = (head: HTMLHeadElement) => {
	let html = head.outerHTML

	// in production build styled components styles are not written as plain HTML, they are injected using CSSOM API
	// they are attached to <style data-styled="active"> node, so we need to get them and write them as plain HTML
	const styledComponentStyleNode = head.querySelector<HTMLStyleElement>(
		'style[data-styled="active"]',
	)
	if (styledComponentStyleNode) {
		let stylesAsHTML = ''
		const rules = styledComponentStyleNode.sheet?.cssRules

		if (rules) {
			for (const rule of rules) {
				stylesAsHTML += `${rule.cssText}\n`
			}

			const styleNode = `<style type="text/css">${stylesAsHTML}</style>`

			html = html.replace('</head>', `${styleNode}</head>`)
		}
	}

	return html
}

function getSocketKey(examId: string): string {
	return `${examId}-cockpit-screenshare`
}

const socketConnector = newTeloSocketConnector()

export function connectToSocketScreenShare(args: ScreenshareSocketArgs): void {
	const { examId } = args
	socketConnector.connectSocket({
		socketKey: getSocketKey(examId),
		newSocket: () => newSocket(args),
	})
}

export function disconnectFromSocketScreenShare(examId: string): void {
	const key = getSocketKey(examId)
	socketConnector.disconnectSocket(key)
}

export function getScreenshareSender(): ScreenshareSender | null {
	return socketConnector.getSocket<ScreenshareSocket>()
}
