import styled, { css } from 'styled-components'

import { pxToRem } from '../libs/style'
import theme from './theme'

export const pageWrapperPadding = css`
	padding-left: ${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
	padding-right: ${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		padding-left: ${pxToRem(theme.spacing(3))}rem;
		padding-right: ${pxToRem(theme.spacing(3))}rem;
	}
`

export const pageWrapperMargin = css`
	margin-left: ${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
	margin-right: ${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
`

export const PageWrapper = styled.div`
	${pageWrapperPadding}
	height: 100%;

	background-size: cover;
	background-image: url('/assets/images/i-pad-elements-content-background-1.webp');
	background-repeat: no-repeat;
`

export const ProgressContainer = styled.div`
	width: 100%;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const PageTitle = styled.h1`
	font-size: ${theme.typography.fontSizes.XL};
	font-weight: normal;
`

export const ExternalLinkNoStyle = styled.a`
	text-decoration: none;
`

const VideoEye = styled.video`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: right center;
`

export const VideoBackground = () => {
	return (
		// see https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter for documentation on codec usage
		<VideoEye autoPlay={true} muted loop poster="/assets/images/eyebg.jpg">
			<source
				src="/assets/videos/eye_video.mp4"
				type='video/mp4; codecs="hvc1"'
			></source>
			<source
				src="/assets/videos/eye_video_h264_low_framerate_no_audio.mp4"
				type='video/mp4; codecs="avc1"'
			></source>
		</VideoEye>
	)
}
