import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import appConfig from '../config'
import authActions from '../features/auth/actions'
import { selectAuthToken } from '../features/auth/selectors'
import history from '../history'
import { pxToRem } from '../libs/style'
import { TDispatch } from '../model/model'
import Button from '../styleguide/buttons/Button'
import CircularProgress from '../styleguide/CircularProgress'
import {
	ExternalLinkNoStyle,
	PageWrapper,
	VideoBackground,
} from '../styleguide/CommonComponents'
import theme from '../styleguide/theme'

const Wrapper = styled(PageWrapper)`
	position: relative;
`

const Content = styled.div`
	position: absolute;
	top: 51%;
	transform: translateY(-50%);
	width: 28%;
	right: 20%;
	text-align: right;
`

const Logo = styled.img`
	width: 100%;
	height: auto;
	display: block;
	margin-bottom: ${pxToRem(theme.spacing(12))}rem;
`

const VersionLabel = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	width: 100%;
	bottom: ${pxToRem(theme.spacing(6))}rem;
	color: var(--content-primary, #20233d);
`

const LoginPage: React.FC = () => {
	const { t } = useTranslation()
	const loginToken = useSelector(selectAuthToken)
	const dispatch: TDispatch = useDispatch()

	const location = window.location
	const searchParams = new URLSearchParams(location.search)
	const code = searchParams.get('code')

	useEffect(() => {
		if (!code) {
			return
		}

		history.push('/')

		if (!loginToken) {
			dispatch(authActions.getToken(code))
		}
	}, [code, loginToken, dispatch])

	let appLoginUrl = ''
	if (appConfig.region === 'GEMINI') {
		appLoginUrl = `${appConfig.login.url}/?response_type=code&client_id=${
			appConfig.login.clientId
		}&redirect_uri=${window.encodeURIComponent(appConfig.login.redirectUri)}`
	} else {
		const acr =
			'urn%3Aoasis%3Anames%3Atc%3ASAML%3A2.0%3Aac%3Aclasses%3ATelemedicine'
		appLoginUrl = `${appConfig.login.url}/?response_type=code&client_id=${
			appConfig.login.clientId
		}&redirect_uri=${window.encodeURIComponent(
			appConfig.login.redirectUri,
		)}&scope=profile&acr_values=${acr}`
	}

	const logosrc =
		appConfig.region === 'GEMINI'
			? '/assets/visionx_logo.svg'
			: '/assets/Logo_Connect_Black.svg'
	return (
		<Wrapper>
			<VideoBackground />
			<Content>
				<Logo alt="Luxottica Patient Consultation logo" src={logosrc} />

				{code ? (
					<CircularProgress />
				) : (
					<ExternalLinkNoStyle href={appLoginUrl}>
						<Button data-testid="sign-in-button" variant="text">
							{t('login.signIn')}
						</Button>
					</ExternalLinkNoStyle>
				)}
			</Content>
			<VersionLabel>
				{t('login.version', {
					versionNumber: process.env.REACT_APP_VERSION,
				})}
			</VersionLabel>
		</Wrapper>
	)
}

export default LoginPage
