import styled from 'styled-components'

import { hexToRgba, pxToRem } from '../libs/style'
import IconButton from '../styleguide/buttons/IconButton'
import theme from '../styleguide/theme'

export const Wrapper = styled.div`
	height: 100%;
	width: 100%;

	video {
		width: 100%;
		height: 100%;
		flex: 1;
		/* override inline style by ACS */
		object-fit: cover !important;
	}

	opacity: 0;
	transition: opacity ${theme.transitions.duration.enteringScreen}ms;
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	overflow: hidden;

	&.fade-in {
		opacity: 1;
	}
`

export const CardWrapper = styled.div`
	display: flex;
	flex: 1;
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	animation: none;

	&.small {
		animation: none;
		position: absolute;
		width: 50%;
		right: 50%;
		flex: 1;
		z-index: 5;
		background-image: linear-gradient(to bottom, #f1f4fd, #e1eefe 106%);
		backdrop-filter: blur(${pxToRem(6)}rem);
	}
`

export const DoctorVideoAndShare = styled(CardWrapper)`
	object-fit: contain !important;
	background-color: white;

	.Video {
		flex: 1;
	}
`

export const PatientVideoAndControls = styled.div`
	&.with-doctor {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
`

export const ButtonContainer = styled.div`
	position: absolute;
	top: ${pxToRem(theme.spacing(1))}rem;
	right: ${pxToRem(theme.spacing(1))}rem;
	display: flex;
	justify-content: center;
	gap: ${pxToRem(theme.spacing(2))}rem;
`

export const ControlButton = styled(IconButton)`
	border-radius: ${pxToRem(theme.shape.borderRadius * 0.5)}rem;
	overflow: hidden;
	display: flex;
	padding: ${pxToRem(3)}rem;
	cursor: pointer;

	&.enabled {
		backdrop-filter: blur(${pxToRem(54.2)}rem);
		background-color: ${hexToRgba(theme.palette.secondary.light, 0.5)};
		color: ${theme.palette.secondary.light};
	}

	&.disabled {
		backdrop-filter: blur(${pxToRem(30)}rem);
		background-color: rgba(32, 35, 61, 0.23);
		color: ${theme.palette.common.white};
		position: relative;

		&&::after {
			content: ' ';
			position: absolute;
			display: block;
			top: ${pxToRem(12)}rem;
			left: ${pxToRem(2)}rem;
			background-color: ${theme.palette.error.main};
			height: ${pxToRem(2)}rem;
			width: ${pxToRem(20)}rem;
			transform: rotate(-45deg);
		}
	}
`

export const PatientLabel = styled.div`
	backdrop-filter: blur(${pxToRem(20)}rem);
	border-radius: ${pxToRem(theme.shape.borderRadius * 0.5)}rem;
	background-color: rgba(32, 35, 61, 0.3);
	color: ${theme.palette.common.white};
	display: flex;
	align-items: center;
	padding: ${pxToRem(theme.spacing(0.5))}rem ${pxToRem(theme.spacing(1))}rem;
	position: absolute;
	bottom: ${pxToRem(theme.spacing(2))}rem;
	left: 50%;
	transform: translateX(-50%);

	&&::before {
		content: ' ';
		border-radius: 2em;
		width: 0.5em;
		height: 0.5em;
		margin-right: 0.5em;
		background-color: ${theme.palette.success.main};
	}
`

export const VideoContainer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;

	&.ScreenSharing {
		background-color: ${theme.palette.common.black};
	}

	&.Video {
		flex: 1;
	}

	&.Video:not(:only-child) {
		display: none;
	}

	video {
		width: 100%;
		height: 100%;
		/* override inline style from ACS */
		object-fit: cover !important;
	}

	&.ScreenSharing video {
		/* override inline style from ACS */
		object-fit: contain !important;
	}
`
