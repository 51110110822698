import { useEffect, useState } from 'react'

import theme, { GRAPH_PALETE } from '../../../styleguide/theme'
import { getYear } from '../../../libs/time'
import {
	CartesianGrid,
	Line,
	LineChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts'
import TOTGraphLegend from './TOTGraphLegend'
import { BaseGraphProps, Scale } from '../graphs/interfaces'
import {
	Eye,
	instrumentType,
	TrendsOverTimeInstrument,
} from '../../../model/model'
import { PhoropterSingleEye } from '../../../model/refraction'
import { calculateSphereEqFromSphAndCyl } from '../graphs/SphereEquivalentGraph/utils'
import { returnPlusSignedValue } from '../../../libs/utils'
import { ChartWrapper } from './styles'
import { isUndefined } from 'lodash'
import { getNiceTickValues } from 'recharts-scale'

export const getInstrumentData = (
	instrumentData: {
		key: string
		OD?: PhoropterSingleEye
		OS?: PhoropterSingleEye
	}[],
	instrumentType: instrumentType,
	eye: Eye,
) => {
	const _calc: any[] = []

	const isValid = (val?: string | number | null) =>
		val !== undefined && val !== '' && val !== null && !Number.isNaN(val)

	const getValue = (
		eye: PhoropterSingleEye | undefined,
		instrumentType: instrumentType,
	) => {
		if (!eye) return undefined

		switch (instrumentType) {
			case 'spheq':
				return calculateSphereEqFromSphAndCyl(
					eye.sphere === '' ? '0' : eye.sphere || '',
					eye.cyl === '' ? '0' : eye.cyl || '',
				)
			case 'cyl':
			case 'sphere':
				return eye[instrumentType] === '' ? '0' : eye[instrumentType]
			default:
				return eye[instrumentType]
		}
	}

	;(instrumentData || []).forEach(val => {
		const el = {
			name: Number(getYear(val.key)),
			OD: getValue(val.OD, instrumentType),
			OS: getValue(val.OS, instrumentType),
		}

		if (
			(eye === '' && (isValid(el.OD) || isValid(el.OS))) ||
			(eye === 'OS' && isValid(el.OS)) ||
			(eye === 'OD' && isValid(el.OD))
		) {
			_calc.push(el)
		}
	})
	return _calc
}

const ifValuesEqual = (data: any[], eye: Eye): boolean =>
	data.map(e => Number(e[`${eye}`])).every((val, _, arr) => val === arr[0])

const CustomizedDot = (props: any) => {
	const {
		cx,
		cy,
		stroke,
		value,
		index,
		numOfDots,
		hotspotOnEnds,
		strokeFirst,
	} = props

	const endColor = stroke.replace('#', '')
	const startColor = strokeFirst.replace('#', '')
	const ratio = !index ? 0 : index / (numOfDots - 1)
	const hex = (x: any) => {
		x = x.toString(16)
		return x.length === 1 ? '0' + x : x
	}
	const r = Math.ceil(
		parseInt(startColor.substring(0, 2), 16) * ratio +
			parseInt(endColor.substring(0, 2), 16) * (1 - ratio),
	)
	const g = Math.ceil(
		parseInt(startColor.substring(2, 4), 16) * ratio +
			parseInt(endColor.substring(2, 4), 16) * (1 - ratio),
	)
	const b = Math.ceil(
		parseInt(startColor.substring(4, 6), 16) * ratio +
			parseInt(endColor.substring(4, 6), 16) * (1 - ratio),
	)
	const middleColor = '#' + hex(r) + hex(g) + hex(b)

	const isFirst = !!numOfDots && index === 0
	const isLast = !!numOfDots && index === numOfDots - 1
	const isHotspot = hotspotOnEnds && (isFirst || isLast)

	const color =
		isHotspot && isFirst && strokeFirst
			? strokeFirst
			: isLast
			? stroke
			: middleColor

	return (
		<svg>
			{!hotspotOnEnds ? (
				<>
					{isLast ? (
						<>
							<circle cx={cx} cy={cy} r={'6'} fill={stroke} />
							<rect
								x={cx - 15}
								y={5}
								width="30px"
								height="96%"
								rx="10"
								fill={'#D3E8F8'}
								fillOpacity={0.2}
							/>
						</>
					) : (
						<circle
							cx={cx}
							cy={cy}
							r={'3'}
							fill={isFirst ? strokeFirst : color}
							stroke={isFirst ? strokeFirst : color}
							strokeOpacity={1}
						/>
					)}
				</>
			) : (
				<rect
					x={isHotspot ? cx - 14 : cx - 5}
					y={isHotspot ? cy - 12 : cy - 5}
					width={isHotspot ? '30' : '10'}
					height={isHotspot ? '20' : '10'}
					rx="10"
					fill={color}
					stroke={color}
					strokeOpacity={0.5}
					strokeWidth="6"
				/>
			)}
			{isHotspot && (
				<text
					textAnchor="middle"
					x={cx}
					y={cy}
					fill={theme.palette.common.white}
				>
					{returnPlusSignedValue({
						value,
						defaultValue: '0.00',
					})}
				</text>
			)}
		</svg>
	)
}

const uniqueItems = <T,>(arr: T[]): T[] => [...new Set(arr)]

type InstrumentGraphProps = Partial<BaseGraphProps> & {
	instrumentData: TrendsOverTimeInstrument[]
	instrumentType: instrumentType
	eye: Eye
	legend?: string
	legendType?: string
}

const InstrumentGraph = ({
	instrumentData,
	instrumentType,
	eye,
	legend,
	legendType,
}: InstrumentGraphProps) => {
	const [scaleY, setScaleY] = useState<Scale>()
	const [scaleX, setScaleX] = useState<Scale>()
	const [isOdEqual, setIsOdEqual] = useState<boolean>(false)
	const [isOsEqual, setIsOsEqual] = useState<boolean>(false)

	const [calculatedData, setCalculatedData] = useState<any[]>([])

	useEffect(() => {
		setCalculatedData(getInstrumentData(instrumentData, instrumentType, eye))
	}, [instrumentData, instrumentType, eye])

	useEffect(() => {
		if (calculatedData.length) {
			setScaleY({
				min: Math.floor(
					Math.min(
						...calculatedData.flatMap((p: any) => [Number(p.OD), Number(p.OS)]),
					),
				),
				max: Math.ceil(
					Math.max(
						...calculatedData.flatMap((p: any) => [Number(p.OD), Number(p.OS)]),
					),
				),
			})
			setScaleX({
				min: Math.min(...calculatedData.map(p => Number(p.name))) - 1,
				max: Math.max(...calculatedData.map(p => Number(p.name))) + 1,
			})
			setIsOdEqual(ifValuesEqual(calculatedData, 'OD'))
			setIsOsEqual(ifValuesEqual(calculatedData, 'OS'))
		}
	}, [calculatedData])

	const yAxisDomain =
		!isUndefined(scaleY?.min) && !isUndefined(scaleY?.max)
			? [scaleY!.min, scaleY!.max]
			: []

	const yNiceTicks = yAxisDomain.length
		? getNiceTickValues([yAxisDomain[0], yAxisDomain[1]], undefined, false)
		: null

	const yAxisTicks = yNiceTicks
		? uniqueItems([0, ...yNiceTicks]).sort((a, b) => b - a)
		: undefined

	return (
		<>
			{legend && (
				<TOTGraphLegend title={legend} titleType={legendType} eye={eye} />
			)}
			<ChartWrapper>
				<ResponsiveContainer>
					<LineChart
						data={calculatedData}
						margin={{ top: 15, right: 0, left: 0, bottom: 0 }}
					>
						<defs>
							<linearGradient
								id="purple_gradient"
								x1="0"
								y1="0"
								x2="100%"
								y2="0"
							>
								<stop stop-color={GRAPH_PALETE.L_PURPLE} offset="0" />
								<stop stop-color={GRAPH_PALETE.PURPLE} offset="1" />
							</linearGradient>
						</defs>
						<defs>
							<linearGradient
								id="green_gradient"
								x1="0"
								y1="0"
								x2="100%"
								y2="0"
							>
								<stop stop-color={GRAPH_PALETE.L_GREEN} offset="0" />
								<stop stop-color={GRAPH_PALETE.GREEN} offset="1" />
							</linearGradient>
						</defs>
						<CartesianGrid strokeDasharray="3 3" />

						<XAxis
							type="category"
							allowDuplicatedCategory={true}
							dataKey="name"
							label={{ position: 'insideBottom' }}
							domain={[scaleX?.min, scaleX?.max] as number[]}
							padding={{ left: 25, right: 25 }}
							axisLine={false}
							tickLine={false}
							tick={!!calculatedData.length}
						/>
						<YAxis
							type="number"
							domain={yAxisDomain}
							padding={{ top: 25, bottom: 25 }}
							width={25}
							axisLine={false}
							ticks={yAxisTicks}
							interval={0}
						/>
						{(eye === 'OD' || !eye) && (
							<Line
								dataKey="OD"
								strokeWidth={2}
								stroke={
									isOdEqual ? GRAPH_PALETE.PURPLE : 'url(#purple_gradient)'
								}
								dot={
									<CustomizedDot
										stroke={GRAPH_PALETE.PURPLE}
										strokeFirst={
											isOdEqual ? GRAPH_PALETE.PURPLE : GRAPH_PALETE.L_PURPLE
										}
										numOfDots={calculatedData.length}
										hotspotOnEnds={!!eye}
									/>
								}
							/>
						)}
						{(eye === 'OS' || !eye) && (
							<Line
								dataKey="OS"
								strokeWidth={2}
								stroke={isOsEqual ? GRAPH_PALETE.GREEN : 'url(#green_gradient)'}
								dot={
									<CustomizedDot
										stroke={GRAPH_PALETE.GREEN}
										strokeFirst={
											isOsEqual ? GRAPH_PALETE.GREEN : GRAPH_PALETE.L_GREEN
										}
										numOfDots={calculatedData.length}
										hotspotOnEnds={!!eye}
									/>
								}
							/>
						)}
					</LineChart>
				</ResponsiveContainer>
			</ChartWrapper>
		</>
	)
}

export default InstrumentGraph
