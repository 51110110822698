import styled, { css } from 'styled-components'
import {
	LegendItemWrapper,
	LegendText,
	LegendWrapper,
} from '../graphs/graph.style'
import { LegendItemColor } from './../graphs/graph.style'
import { FirstLine } from './../CommonComponents'
import { pxToRem } from '../../../libs/style'
import theme from '../../../styleguide/theme'

export const MyopiaSummaryGraphsContainer = styled.div`
	background-color: white;
	display: flex;
	flex-flow: row wrap;
	width: 95%;
	height: 95%;
	border-radius: 5%;
`

const graphBorder = css`
	content: '';
	position: absolute;
	border-style: solid;
	border-color: grey;
	opacity: 0.2;
`

export const MyopiaSummaryGraphCardContainer = styled.div<{
	$borderBotRight?: boolean
	$borderRight?: boolean
	$borderBottom?: boolean
	$zoom?: boolean
}>`
	${({ $zoom }) =>
		!$zoom &&
		`
    font-size: 1rem;
	max-height: 50%;
	min-height: 10rem;
	position: relative;
	max-width: 50%;
	width: 50%;
	padding: 2rem;

	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		padding: 1.25rem;
	}
    
	.recharts-wrapper {
        font-size: 0.5rem;
	}

    ${FirstLine} {
        font-size: 1.5rem;
        
        i {
            font-family: 'Ivar Display', sans-serif;
        }
    }
    
    
	${LegendWrapper} {
        ${LegendItemWrapper} {
            align-items: center;
		}
        
		${LegendItemColor} {
            height: 1rem;
		}
        
		${LegendText} {
            font-size: 0.5rem;
			line-height: inherit;
		}
	}
`}

	${({ $borderBotRight }) =>
		$borderBotRight &&
		`
            &::before {
                bottom: 0;
                left: 10%;
                width: 85%;
                ${graphBorder}
                border-bottom: 2px;
            }
            
            &::after {
                ${graphBorder}
                top: 10%;
                right: 0;
                border-right: 2px;
                height: 85%;
            }
        `}

	${({ $borderRight }) =>
		$borderRight &&
		`
        
        &::after {
                ${graphBorder}
                bottom: 10%;
                right: 0;
                border-right: 2px;
                height: 85%;
            }
    `}

    ${({ $borderBottom }) =>
		$borderBottom &&
		`
        &::before {
                ${graphBorder}
                bottom: 0;
                right: 10%;
                border-bottom: 2px;
                width: 85%;
            }
    `}
`

export const MyopiaZoomGraphContainer = styled(MyopiaSummaryGraphsContainer)`
	width: 100%;
	height: 100%;
	padding: 2rem;

	${MyopiaSummaryGraphCardContainer} {
		width: 100%;
		max-height: 100%;
	}
`

export const MyopiaZoomPortal = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 1.5rem;
`

export const TitleStyled = styled.h2`
	font-family: 'Ivar Display', sans-serif;
	font-style: italic;
	font-weight: 300;
	font-size: ${pxToRem(theme.spacing(4))}rem;
	color: ${theme.palette.background.secondary};

	@media only screen
	and (max-device-width: 1366px)
	and (orientation: landscape)
	and (-webkit-min-device-pixel-ratio: 1) {
		font-size: ${pxToRem(theme.spacing(2.8))}rem;
	}

	span {
		font-family: 'SpaceGrotesk';
		font-style: normal;
	}
`
