import React, { PropsWithChildren } from 'react'
import { Grid } from '@material-ui/core'
import { EyeLabel, EyeLabelFirstLine, EyeLabelSecondLine, GraphCardWrapper, GraphWrapper } from '../graph.style'

type Props = {
  eyeLabel: {
    firstLine: string
    secondLine : string
  }
}

const GraphCard: React.FC<PropsWithChildren<Props>> = ({eyeLabel, children}) => {
  return (
    <Grid item xs={6}>
      <GraphCardWrapper border={true}>
        <EyeLabel>
          <EyeLabelFirstLine>{eyeLabel.firstLine}</EyeLabelFirstLine>
          <EyeLabelSecondLine>{eyeLabel.secondLine}</EyeLabelSecondLine>
        </EyeLabel>
        <GraphWrapper fullHeight={false}>
          {children}
        </GraphWrapper>
      </GraphCardWrapper>
    </Grid>
  )
}

export default GraphCard