import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import {
  FirstLine, SecondLine, TitleWrapper, Wrapper
} from '../../CommonComponents'
import { useTranslation } from 'react-i18next'
import { pxToRem } from '../../../../libs/style'
import theme from '../../../../styleguide/theme'
import { isUndefined } from 'lodash'
import { calculateYScale } from '../utils'
import { CalculatedSphereEquivalent, Scale } from '../interfaces'
import { Grid } from '@material-ui/core'
import SphereEquivalentGraphMono from './SphereEquivalentGraphMono'
import SphereEquivalentGraphBino from './SphereEquivalentGraphBino'

const ScientificReferences = styled.div`
    align-items: center;
    text-align: center;
    color: gray;
    margin: ${pxToRem(theme.spacing(2))}rem;
    font-size: ${pxToRem(theme.spacing(1.5))}rem;
    @media only screen
    and (max-device-width: 1366px)
    and (orientation: landscape)
    and (-webkit-min-device-pixel-ratio: 1) {
        margin: ${pxToRem(theme.spacing(1.25))}rem ${pxToRem(theme.spacing(0.5))}rem;
        font-size: ${pxToRem(theme.spacing(1.25))}rem;
    }
`

type Props = {
  calculatedSphereEquivalent: CalculatedSphereEquivalent
}

const EquivalentSphereGraphRighLeft: React.FC<Props> = ({
	calculatedSphereEquivalent
}) => {
	const { t } = useTranslation()
	const [scaleY, setScaleY] = useState<Scale>()
  const [displayRight, setDisplayRight] = useState<boolean>(false)
  const [displayLeft, setDisplayLeft] = useState<boolean>(false)
  const [displayBoth, setDisplayBoth] = useState<boolean>(false)
  const [eyeTitle, setEyeTitle] = useState<string>('')

	useEffect(() => {
		if (!isUndefined(calculatedSphereEquivalent)) {
			setScaleY(
				calculateYScale(
					calculatedSphereEquivalent?.left.values,
					calculatedSphereEquivalent?.right.values,
          0,
          1,
				),
			)
      setDisplayRight(calculatedSphereEquivalent.eye === 'R')
      setDisplayLeft(calculatedSphereEquivalent.eye === 'L')
      setDisplayBoth(calculatedSphereEquivalent.eye === 'B')
		}
	}, [calculatedSphereEquivalent])

  useEffect(() => {
    setEyeTitle(`${t('content.myopia.refractionAnalysis.title')}${displayRight ? ' OD' : displayLeft ? ' OS' : ''}`)
  }, [displayRight, displayLeft, t])

	return (
    <Wrapper>
      <TitleWrapper>
      <FirstLine>{t('content.myopia.myopiaCareSolution')}</FirstLine>
      <SecondLine>{eyeTitle}</SecondLine>
      </TitleWrapper>
      <Grid container>
        {displayBoth && (<SphereEquivalentGraphBino scaleY={scaleY} calculatedSphereEquivalent={calculatedSphereEquivalent}/>)}
        {displayRight && (<SphereEquivalentGraphMono scaleY={scaleY} calculatedSphereEquivalentData={calculatedSphereEquivalent.right}/>)}
        {displayLeft && (<SphereEquivalentGraphMono scaleY={scaleY} calculatedSphereEquivalentData={calculatedSphereEquivalent.left}/>)}
        <Grid item xs={12}>
          <ScientificReferences>
            {t('content.myopia.refractionAnalysis.scientificReference')}
          </ScientificReferences>
        </Grid>
      </Grid>
    </Wrapper>      
	)
}

export default EquivalentSphereGraphRighLeft