import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import authActions from '../features/auth/actions'
import notificationsActions from '../features/notifications/actions'
import Button from '../styleguide/buttons/Button'

const BackToLoginBtn: React.FC<{ variant?: 'text' | 'outlined' | 'contained' }> = ({ variant }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return <Button variant={variant} onClick={() => {
    // remove any notification, for example the token expired notification
    dispatch(notificationsActions.resetNotifications())
    dispatch(authActions.logout())
  }}>{t('app.backToLogin')}</Button>
}

export default BackToLoginBtn
