export type CallEndReasonCode = {
	code: number
	message: string
	error: boolean
}

export const CALL_END_REASON_CODES: CallEndReasonCode[] = [
	{
		code: 403,
		message:
			'Forbidden / Authentication failure. Ensure that your Communication Services token is valid and not expired.',
		error: true,
	},
	{
		code: 404,
		message:
			'Call not found. Ensure that the number you re calling (or call you re joining) exists.',
		error: true,
	},
	{
		code: 408,
		message:
			'Call controller timed out. Call Controller timed out waiting for protocol messages from user endpoints. Ensure clients are connected and available.',
		error: true,
	},
	{
		code: 410,
		message:
			'Local media stack or media infrastructure error. Ensure that you re using the latest client library in a supported environment.',
		error: true,
	},
	{
		code: 430,
		message:
			'Unable to deliver message to client application. Ensure that the client application is running and available.',
		error: true,
	},
	{
		code: 480,
		message:
			'Remote client endpoint not available, ensure that the remote endpoint is correctly logged in and ready to receive calls.',
		error: true,
	},
	{
		code: 481,
		message:
			'Failed to handle incoming call. File a support request through the Azure portal.',
		error: true,
	},
	{
		code: 487,
		message:
			'Call canceled, locally declined, ended due to an endpoint mismatch issue, or failed to generate media offer. Expected behavior.',
		error: true,
	},
	{
		code: 490,
		message: 'Local endpoint network issues. Check your network.',
		error: true,
	},
	{
		code: 491,
		message: 'Local endpoint network issues. Check your network.',
		error: true,
	},
	{
		code: 496,
		message: 'Local endpoint network issues. Check your network.',
		error: true,
	},
	{
		code: 498,
		message: 'Local endpoint network issues. Check your network.',
		error: true,
	},
	{
		code: 500,
		message:
			'Communication Services infrastructure error. File a support request through the Azure portal.',
		error: true,
	},
	{
		code: 503,
		message:
			'Communication Services infrastructure error. File a support request through the Azure portal.',
		error: true,
	},
	{
		code: 504,
		message:
			'Communication Services infrastructure error. File a support request through the Azure portal.',
		error: true,
	},
	{
		code: 603,
		message: 'Call globally declined by remote participant',
		error: true,
	},
	{
		code: 701,
		message: 'Grants denied for audio or audio, or both.',
		error: true,
	},
]
