import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { pxToRem } from '../libs/style'
import CircularProgress from '../styleguide/CircularProgress'
import { PageWrapper, ProgressContainer } from '../styleguide/CommonComponents'
import theme from '../styleguide/theme'

const PageWrapperCustom = styled(PageWrapper)`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`

const Message = styled.div`
	color: ${theme.palette.grey[500]};
	font-size: ${theme.typography.fontSizes.S};
	width: 33%;
	margin: ${pxToRem(theme.spacing(5))}rem auto 0;
	text-align: center;
	&:first-letter {
		text-transform: uppercase;
	}
`
const LoadingPage: React.FC<{ message?: string; }> = ({
	message,
}) => {
	const { t } = useTranslation()

	return (
		<PageWrapperCustom>
			<ProgressContainer>
				<CircularProgress />
			</ProgressContainer>
			{message && (
				<Message>
					{t('app.loading')} {message}
				</Message>
			)}
		</PageWrapperCustom>
	)
}

export default LoadingPage
