import styled from 'styled-components'
import theme from '../../styleguide/theme'
import { pxToRem } from '../../libs/style'

export const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: linear-gradient(
		225deg,
		transparent ${pxToRem(30)}rem,
		${theme.palette.background.default} ${pxToRem(30)}rem
	);
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	border-top-right-radius: ${pxToRem(60)}rem;
	padding: ${pxToRem(theme.spacing(3))}rem ${pxToRem(theme.spacing(3))}rem;
	padding-top: ${pxToRem(theme.spacing(3.5))}rem;
	position: relative;
	width: 100%;
	margin-top: ${pxToRem(theme.spacing(3))}rem;
	transition: bottom ${theme.transitions.duration.standard}ms,
		right ${theme.transitions.duration.standard}ms,
		top ${theme.transitions.duration.standard}ms;
	&&.lateral {
		right: 0;
	}
	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		margin-top: ${pxToRem(theme.spacing(2))}rem;
		padding: ${pxToRem(theme.spacing(2))}rem;
		padding-top: ${pxToRem(theme.spacing(2))}rem;
	}
`

export const HeaderSection = styled.div<{ isDoctor: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		${props =>
			props.isDoctor ? '' : `margin-bottom: ${pxToRem(theme.spacing(3))}rem;`}
	}

	@media only screen and (min-height: 900px) and (-webkit-min-device-pixel-ratio: 1) {
		${props =>
			props.isDoctor ? '' : `margin-bottom: ${pxToRem(theme.spacing(6))}rem;`}
	}
`

export const HeaderTitle = styled.div`
	color: ${theme.palette.secondary.light};
	text-transform: uppercase;
	margin-bottom: ${pxToRem(theme.spacing(0.5))}rem;
	font-size: ${pxToRem(13)}rem;
`

export const HeaderName = styled.div`
	font-size: ${pxToRem(17)}rem;
`

export const Badge = styled.div`
	font-size: ${pxToRem(16)}rem;
	text-transform: uppercase;
	font-family: 'Ivar Display';
	padding: 1.25vh;
	border-radius: 6vh;
	font-style: italic;
	backdrop-filter: blur(${pxToRem(4.1)}rem);
	color: ${theme.palette.common.white};
	background-image: linear-gradient(to bottom, #eee8e3, #61a4ff 106%);
	background-repeat: no-repeat;
	background-size: cover;
`

export const ContentRowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	padding: ${pxToRem(theme.spacing(1))}rem 0;
`

export const GreyText = styled.div`
	color: ${theme.palette.grey[500]};
`

export const UppercaseText = styled.div`
	text-transform: uppercase;
`
