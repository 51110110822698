import { Socket } from 'socket.io-client'
import { Optional, TeloSocket } from './teloSocketTypes'

export const isSocketConnected = (socket: Optional<Socket>): boolean =>
	socket?.connected ?? false

export type ConnectTeloSocketArgs = {
	socketKey: string
	newSocket: () => TeloSocket
}

const keyToSocket = new Map<string, TeloSocket>()
export const connectTeloSocket = ({
	socketKey,
	newSocket,
}: ConnectTeloSocketArgs): void => {
	if (!keyToSocket.has(socketKey)) {
		const teloSocket = newSocket()
		teloSocket.connect()
		keyToSocket.set(socketKey, teloSocket)
	}
}

export const disconnectTeloSocket = (socketKey: string): void => {
	if (keyToSocket.has(socketKey)) {
		const socket = keyToSocket.get(socketKey)!
		socket.disconnect()
		keyToSocket.delete(socketKey)
	}
}

export const getTeloSocket = <T extends TeloSocket = TeloSocket>(
	socketKey: string,
): T | null => {
	return keyToSocket.has(socketKey) ? (keyToSocket.get(socketKey) as T) : null
}
