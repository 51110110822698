import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { EyeGrid } from './EyeGrid'
import { EyeMedia } from './EyeMedia'
import { EyeDataWithMedia } from './types'
import { pxToRem } from '../../../libs/style'

const EyePanelRoot = styled.div`
	background-color: white;
	padding: 2rem;
	border-radius: ${pxToRem(30)}rem;

	display: flex;
	flex-direction: column;
	gap: ${pxToRem(10)}rem;
	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		width: 50%;
	}
`

const EyeMediaCustom = styled(EyeMedia)`
	& .eye-topography {
		grid-area: 2 / 1;
	}

	& .eye-slit-lamp {
		grid-area: 3 / 1;
	}

	& .eye-retinal-imaging {
		grid-area: 4 / 1;
	}
`

type EyePanelProps = EyeDataWithMedia

export function EyePanel({
	add,
	axis,
	cylinder,
	eye,
	hPrism,
	hOrient,
	vPrism,
	vOrient,
	retinalImagingImgUrl,
	slitLampImgUrl,
	sphere,
	topographyImgUrl,
}: EyePanelProps): JSX.Element {
	const { t } = useTranslation()

	return (
		<EyePanelRoot>
			<EyeGrid
				eye={eye}
				add={add}
				axis={axis}
				cylinder={cylinder}
				hPrism={hPrism}
				hOrient={hOrient}
				vPrism={vPrism}
				vOrient={vOrient}
				sphere={sphere}
			/>
			<EyeMediaCustom
				imgUrl={topographyImgUrl}
				label={t('clientContentSummary.topography')}
				className="eye-topography"
			/>
			<EyeMediaCustom
				imgUrl={slitLampImgUrl}
				label={t('clientContentSummary.slitLamp')}
				className="eye-slit-lamp"
			/>
			<EyeMediaCustom
				imgUrl={retinalImagingImgUrl}
				label={t('clientContentSummary.retinalImaging')}
				className="eye-retinal-imaging"
			/>
		</EyePanelRoot>
	)
}
