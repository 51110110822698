import React from 'react'

import { Snackbar as MSnackbar, SnackbarProps } from '@material-ui/core'

const Snackbar: React.FC<SnackbarProps> = ({ children, ...props }) => {
	return (
		<MSnackbar
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
			{...props}
		>
			{children}
		</MSnackbar>
	)
}

export default Snackbar
