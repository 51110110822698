import { generateUID } from '../../libs/uuid'
import { AddNotificationPayload, AppThunk } from '../../model/model'
import { slice } from './slice'

const AUTOCLOSE_DELAY = 5000

const addNotification = (
	notification: AddNotificationPayload,
): AppThunk => dispatch => {
	const _id = notification.id || generateUID()
	dispatch(slice.actions._addNotification({ _id, ...notification }))
	if (notification.autoClose) {
		setTimeout(() => {
			dispatch(slice.actions.removeNotification(_id))
		}, AUTOCLOSE_DELAY)
	}
}

const notificationsActions = {
	...slice.actions,
	addNotification,
}

export default notificationsActions
