import { useSelector } from 'react-redux'
import { selectDateFormat } from '../features/exam/selectors'
import { selectAppLang } from '../features/settings/selectors'
import { formatDate } from '../libs/time'

type ADate = Date | string | number

export type DateFormatterFn = (date: ADate) => string

export const useDateFormatter = (): DateFormatterFn => {
	const dateFormat = useSelector(selectDateFormat)
	const lang = useSelector(selectAppLang)
	return (date: ADate) => formatDate(date, dateFormat, lang)
}
