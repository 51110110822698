import { NormativeTable } from '../components/content/graphs/interfaces'
import { ExternalSource, Id } from './model'
import { DoctorInfo } from './user'

export type AppointmentType =
	| 'CAT FU'
	| 'CL CK 14'
	| 'CL CK 7'
	| 'CL FIT'
	| 'CL TRAIN'
	| 'COMP_VIS'
	| 'CONS/PRE'
	| 'DE'
	| 'DFE'
	| 'DIAB_EX'
	| 'DMV_TEST'
	| 'DRI'
	| 'DRY_CK'
	| 'DRY_TREAT'
	| 'EC'
	| 'EP CL NW'
	| 'EP CL'
	| 'EP E'
	| 'EP ONESIGHT'
	| 'EP_DIABET'
	| 'EP_DRY'
	| 'EP_MED_CK'
	| 'EP_MED'
	| 'EX CL F'
	| 'EX CL RF'
	| 'F'
	| 'GLAUC_TEST'
	| 'INFT SEE'
	| 'IOP'
	| 'LASIK'
	| 'LOW_VIS'
	| 'LSK CONS'
	| 'MED_EX'
	| 'NP CL NW'
	| 'NP CL'
	| 'NP EEX'
	| 'NP ONESIGHT'
	| 'NP_DIABET'
	| 'NP_DRY'
	| 'NP_MED_CK'
	| 'NP_MED'
	| 'OCT'
	| 'OV'
	| 'PRK/AST'
	| 'RED EYE'
	| 'REF Sx FU'
	| 'RX CK'
	| 'SAME_D'
	| 'SPEC_CL_CK'
	| 'SPEC_CL_FIT'
	| 'VF'
	| 'VT CK'
	| 'VT EVAL'
	| 'VT EX'
	| 'WALK_IN'

export type ExamStatus =
	| 'NotShown'
	| 'Upcoming'
	| 'IntakeFormEnded'
	| 'PreTestStarted'
	| 'PreTestFinished'
	| 'PhoropterSelected'
	| 'RefractionistStarted'
	| 'RefractionistEnded'
	| 'PhoropterFollowUpSelected'
	| 'DoctorModeSelected'
	| 'PhoropterSelectedSkipRefractionist'
	| 'DoctorStarted'
	| 'DoctorEnded'
	| 'LensTrialQuit'
	| 'DoctorEndedWithLensTrial'
	| 'Ended'
	| 'Interrupted'
	| 'Uncompleted'
	| 'Paused'
	| 'Waiting'

export const offRoomExamStatus: ExamStatus[] = [
	'Ended',
	'Interrupted',
	'Uncompleted',
	'LensTrialQuit',
	'Waiting',
]

export interface InternalPatientCore {
	name: string
	surname: string
	birthDate: string
	email: string
}

export interface InternalPatient extends InternalPatientCore {
	address: string
	postalCode: string
	city: string
	province: string
	externalPatientsIds: ExternalSource[]
	_id: Id
}

export type HistoryDoctor = {
	doctor: string
	createdAt: Date
}

export interface ExamApi extends ExamBase {
	status: ExamStatus
}

export interface InstrumentsData {
	lensmeter?: Record<string, any>
	autorefraction?: Record<string, any>
	keratometer?: Record<string, any>
	tonometer?: Record<string, any>
	phoropter?: Record<string, any>
	visualFields?: Record<string, any>
	slitLamp?: Record<string, any>
	retinalImaging?: Record<string, any>
}

export interface PreTestData {
	instruments?: InstrumentsData
}

export interface Exam extends ExamBase {
	status: ExamStatus
	preTest: PreTestData
	discardPhoropterReadingsBefore: number
	phoropterIsResetting: boolean
	previousExamId?: string
	historyDoctor?: HistoryDoctor[]
}

export type DifFormPersonal = {
	firstName: string
	lastName: string
	cellPhone: string
	homePhone?: string
	email: string
	dateOfBirth?: Date
	age?: string
	address?: string
	city?: string
	state?: string
	zipCode?: string
	pmoc?: string[]
	race?: string
	interpreter?: string
	gender?: string
	genderOther?: string
	language?: string
	assistanceRequirements?: string
	assistanceRequirementsText?: string
	insurance?: string
	ethnicity?: NormativeTable
}

export type DifForm = DifFormPersonal

export type ExamDif = {
	personalInfo?: DifFormPersonal
}

interface ExamBase {
	_id: Id
	store: {
		_id: Id
		code: string
		enableCockpit: boolean
		dateFormat: string
	}
	appointmentId: Id
	// TODO: migrate old exams to insert the examType field and then make it mandatory
	dif?: DifForm
	examType?: AppointmentType
	internalPatient: InternalPatient
	startDateTime?: Date
	endDateTime?: Date
	interruptionReason?: string
	interruptedBy?: string
	interruptedByRole?: string
	localTechnician: string
	refractionist: string
	doctor: string
	doctorName?: string
	doctorSurname?: string
	cockpitTechnician?: string
	createdAt: Date
	updatedAt: Date
	gender: string
	occupation: string
	language: string
	patientId: string
	previousExamId?: string
	isTest?: boolean
	readonly?: boolean
	mode: 'remote' | 'store'
}

export type ExamState = {
	doctor?: DoctorInfo
	exam?: Exam
	examDif?: ExamDif
	numberOfVisits?: string
}

export type WithExamId<T> = T & {
	examId: string
}
