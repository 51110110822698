import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { pxToRem } from '../../../libs/style'
import { returnPlusSignedValue } from '../../../libs/utils'
import { Eye, instrumentType } from '../../../model/model'
import DirectionGraphIcon from '../../../styleguide/icons/DirectionGraphIcon'
import TotNoImprovingIcon from '../../../styleguide/icons/TotNoImprovingIcon'
import theme, { GRAPH_PALETE } from '../../../styleguide/theme'
import { LegendItemColor, LegendText } from '../graphs/graph.style'

const LegendTitle = styled.h3`
	font-size: ${pxToRem(theme.spacing(3))}rem;
	font-weight: 300;
`

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: ${pxToRem(theme.spacing(3))}rem;
	background-color: ${theme.palette.grey[100]};
	border-radius: ${pxToRem(theme.spacing(3))}rem;
	width: 30%;
`

const DiffContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const LegendItem = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`

const LegendItemColorStyled = styled(LegendItemColor)`
	height: ${pxToRem(theme.spacing(2))}rem;
	width: ${pxToRem(theme.spacing(2))}rem;
	margin-right: ${pxToRem(theme.spacing(0.5))}rem;
	margin-bottom: ${pxToRem(theme.spacing(0.375))}rem;
`

const LegendTextStyled = styled(LegendText)`
	font-size: ${pxToRem(theme.spacing(2))}rem;
`

const LegendType = styled.span<{ color: string }>(
	({ color }) => `
	text-transform: uppercase;
	color: ${color};
`,
)

const DirectionGraphIconStyled = styled(DirectionGraphIcon)<{
	rotate: boolean
}>(
	({ rotate }) => `
	${rotate ? 'transform: rotate(180deg);' : ''}
`,
)

const TotNoImprovingIconStyled = styled(TotNoImprovingIcon)`
	margin-top: ${pxToRem(9)}rem;
`

const TOTGraphZoomLegend: React.FC<{
	eye: Eye
	values: (number | string)[]
	instrumentType: instrumentType
	label: string
}> = ({ eye, values, instrumentType, label }) => {
	const { t } = useTranslation()
	const last2 = values.slice(-2).map(n => Number(n) || 0)
	const dif =
		last2.length > 1
			? (last2[1] || 0) - (last2[0] || 0)
			: (last2[0] || 0) - (last2[1] || 0)

	const graphLegends = {
		OD: {
			key: 'OD',
			value: 'OD',
			color: GRAPH_PALETE.PURPLE,
			typeColor: GRAPH_PALETE.L_PURPLE,
		},
		OS: {
			key: 'OS',
			value: 'OS',
			color: GRAPH_PALETE.GREEN,
			typeColor: GRAPH_PALETE.L_GREEN,
		},
	}

	return (
		<Container>
			<LegendItem>
				<LegendItemColorStyled {...graphLegends[eye || 'OS']} />
				<LegendTextStyled>{label}</LegendTextStyled>
			</LegendItem>
			<DiffContainer>
				<LegendTitle>
					{returnPlusSignedValue({
						value: dif.toString(),
						defaultValue: '0.00',
					})}
				</LegendTitle>
				<LegendType color={graphLegends[eye || 'OS'].typeColor}>
					{t(`tot.graphs.acronyms.${instrumentType || ''}`)}
				</LegendType>
			</DiffContainer>
			{dif ? (
				<DirectionGraphIconStyled
					fill={graphLegends[eye || 'OS'].color}
					rotate={dif < 0}
				/>
			) : dif === 0 ? (
				<TotNoImprovingIconStyled fill={graphLegends[eye || 'OS'].color} />
			) : (
				<></>
			)}
		</Container>
	)
}

export default TOTGraphZoomLegend
