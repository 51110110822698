import React from 'react'
import { SvgProps } from '../../model/model'

const DirectionGraphIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="17px"
			height="31px"
			viewBox="0 0 17 31"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g
				id="NEW-DASHBOARD_8-June"
				stroke="none"
				stroke-width="1"
				fill="none"
				fill-rule="evenodd"
			>
				<g
					id="2.1-Clinical-Dashboard"
					transform="translate(-346.000000, -711.000000)"
					fill={props.fill || '#48B6B8'}
				>
					<g id="Group-12" transform="translate(38.000000, 38.000000)">
						<g id="Group-4" transform="translate(52.000000, 650.000000)">
							<g id="Group-7" transform="translate(178.000000, 17.000000)">
								<path
									d="M79.5546413,20.0732827 C79.0023565,20.0732827 78.5546413,19.6255675 78.5546413,19.0732827 C78.5546413,18.8815311 78.6097716,18.6938224 78.7134628,18.5325251 L85.8817973,7.38178255 C86.1804495,6.91721251 86.7991633,6.7827095 87.2637334,7.08136167 C87.384236,7.15882766 87.4866882,7.2612799 87.5641542,7.38178255 L94.7324887,18.5325251 C95.0311409,18.9970952 94.8966379,19.615809 94.4320679,19.9144612 C94.2707705,20.0181523 94.0830619,20.0732827 93.8913103,20.0732827 L90.2229758,20.0732827 L90.2229758,35.0732827 C90.2229758,35.6255675 89.7752605,36.0732827 89.2229758,36.0732827 L84.2229758,36.0732827 C83.670691,36.0732827 83.2229758,35.6255675 83.2229758,35.0732827 L83.2229758,20.0732827 L79.5546413,20.0732827 Z"
									id="Combined-Shape-Copy-2"
								></path>
							</g>
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default DirectionGraphIcon
