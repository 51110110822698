import { isUndefined } from 'lodash'
import { Point, Scale } from '../interfaces'

export enum Side {
	R = 'R',
	L = 'L',
}

export const FROM_AGE = 6
export const TO_AGE = 18

export const range = (size: number, startAt = 0): ReadonlyArray<number> => {
	return [...Array(size).keys()].map(i => i + startAt)
}

export const findValueByAgeAndKey = (
	values: Point[] | undefined,
	age: number,
	key: 'x' | 'y',
	transform?: (value: number) => number,
): number | undefined => {
	const foundValue = values?.find(value => value.x === Math.ceil(age))

	if (foundValue) {
		if (!transform) {
			return foundValue[key]
		} else {
			return transform(foundValue[key])
		}
	}

	return undefined
}

export const calculateYScale = (
	rightPoints: Point[] = [],
	leftPoints: Point[] = [],
	defaultMin?: number,
	defaultMax?: number,
): Scale => {
	const filteredRightPoints = rightPoints
		.map(point => point.y)
		.filter(value => !isUndefined(value) && Number.isFinite(value))
	const filteredLeftPoints = leftPoints
		.map(point => point.y)
		.filter(value => !isUndefined(value) && Number.isFinite(value))
	const rightMin = filteredRightPoints.length
		? Math.min(...(filteredRightPoints as number[]))
		: defaultMin || 0
	const rightMax = filteredRightPoints.length
		? Math.max(...(filteredRightPoints as number[]))
		: defaultMax || 1
	const leftMin = filteredLeftPoints.length
		? Math.min(...(filteredLeftPoints as number[]))
		: defaultMin || 0
	const leftMax = filteredLeftPoints.length
		? Math.max(...(filteredLeftPoints as number[]))
		: defaultMax || 1

	return {
		min: Math.floor(Math.min(rightMin, leftMin)),
		max: Math.ceil(Math.max(rightMax, leftMax)),
	}
}

export const isBetween = (
	valueToCompare: number | null,
	from: number,
	to: number,
) => {
	if (valueToCompare) {
		return valueToCompare >= from
			? valueToCompare <= to
				? true
				: false
			: false
	}
	return false
}
