import styled from 'styled-components'
import { getAssetSrc } from '../../../libs/utils'
import { pxToRem } from '../../../libs/style'

const EyeMediaRoot = styled.div<{ bg: string }>`
	position: relative;
	background-color: ${props => props.bg};
	border-radius: ${pxToRem(15)}rem;
	width: 23rem;
	aspect-ratio: 2 / 1;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		max-width: 100%;
	}

	img:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-color: #f3f4f7;
	}

	img:after {
		content: '';
		display: block;
		font-size: 0.75rem;
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		text-align: center;
	}
`

const EyeMediaContent = styled.img`
	width: 23rem;
	aspect-ratio: 2 / 1;
	border-radius: ${pxToRem(15)}rem;
	object-fit: contain;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		max-width: 100%;
		height: auto;
	}
`

const EyeMediaLabel = styled.span`
	position: absolute;
	left: ${pxToRem(20)}rem;
	top: ${pxToRem(10)}rem;
	background-color: #6b514e;
	color: white;
	opacity: 0.7;
	padding: ${pxToRem(7)}rem;
	border-radius: ${pxToRem(10)}rem;
	font-size: 0.8rem;
	user-select: none;
`

type EyeMediaProps = {
	className?: string
	label: string
	imgUrl?: string
}

const NO_IMG_COLOR = '#f3f4f7'
const IMG_COLOR = '#000'

export function EyeMedia({
	label,
	className,
	imgUrl,
}: EyeMediaProps): JSX.Element {
	const bg = imgUrl ? IMG_COLOR : NO_IMG_COLOR
	return (
		<EyeMediaRoot className={className} bg={bg}>
			{imgUrl ? (
				<>
					<EyeMediaContent src={getAssetSrc(imgUrl)} alt={label} />
					<EyeMediaLabel>{label}</EyeMediaLabel>
				</>
			) : null}
		</EyeMediaRoot>
	)
}
