import { getInstrumentDataApi } from '../../apiCalls'
import { InstrumentType } from '../../model/instruments'
import { AppThunk, DisplayableContentState } from '../../model/model'
import { slice } from './slice'

const setDisplayContent =
	(content: DisplayableContentState): AppThunk =>
		async dispatch => {
			dispatch(slice.actions.setDisplayContent(content))
		}

const fetchInstrumentData = (examId: string, instrument: InstrumentType): AppThunk => async (dispatch) => {
	try {
		const response = await getInstrumentDataApi(examId, 'NT')
		if (response && response.examData)
			dispatch(slice.actions.setAdditionalDataForInstrument({ instrument, data: response.examData }))
	} catch (e) {
		console.error(e)
	}
}

const setContent = (content: any): AppThunk =>
	async dispatch => {
		dispatch(slice.actions.setContent(content))
	}

const displayContentActions = {
	...slice.actions,
	setDisplayContent,
	fetchInstrumentData,
	setContent
}

export default displayContentActions
