export const LABELS = {
	x: 'Age',
	y: 'Axial Length (mm)',
}

export enum Colors {
	LINE = 'rgb(140, 140, 140, 1)',
	VALUE = 'rgb(20, 136, 202, 1)',
	C2 = '#2769C2',
	C50 = '#73B0EA',
	C98 = '#AD72E5',
}
