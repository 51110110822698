import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import BackToLoginBtn from '../components/BackToLoginBtn'
import { pxToRem } from '../libs/style'
import Button from '../styleguide/buttons/Button'
import theme from '../styleguide/theme'

const Container = styled.div`
	background-color: rgba(0, 0 , 0, 0.5);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`
const Wrapper = styled.div`
	background-color: ${theme.palette.grey[50]};
	display: flex;
	flex-direction: column;
	padding: ${pxToRem(theme.spacing(3))}rem;
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	min-width: ${pxToRem(300)}rem;
	max-width: ${pxToRem(800)}rem;
`

const Error = styled.div`
	color: ${theme.palette.primary.main};
	font-size:${theme.typography.fontSizes.L};
	padding-top: ${pxToRem(theme.spacing(1))}rem;
	padding-bottom: ${pxToRem(theme.spacing(5))}rem;
	font-family: 'Ivar Display';
	font-style: italic;
	&:first-letter {
		text-transform: uppercase;
	}`

const Buttons = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	button {
		padding:${pxToRem(theme.spacing(1))}rem;
		padding-left: ${pxToRem(theme.spacing(4))}rem;;
		span{
			font-size: ${theme.typography.fontSizes.M};
			text-transform: uppercase !important;
		}
		color:${theme.palette.grey['400']};
	}
`
const ErrorHeader = styled.div`
color: ${theme.palette.secondary.light};
text-transform: uppercase;
font-size: ${theme.typography.fontSizes.S};
font-weight: bold;
`

const ErrorPage: React.FC<{ message: string }> = ({ message }) => {
	const { t } = useTranslation()

	return (
		<Container>
			<Wrapper>
				<ErrorHeader>{t('app.error')}</ErrorHeader>
				<Error>
					<div>{message}</div>
				</Error>

				<Buttons>
					<Button onClick={() => window.location.reload()}>
						{t('app.reload')}
					</Button>

					<BackToLoginBtn />
				</Buttons>
			</Wrapper>
		</Container>
	)
}

export default ErrorPage
