import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Legend from '../legend'
import { Colors } from './constant'

const SphereEquivalentLegend: React.FC = () => {
	const { t } = useTranslation()

	const graphLegends = useMemo(
		() => [
			{
				key: 'myopiaThreshold',
				value: t('content.myopia.refractionAnalysis.graph.legend.myopiaThreshold'),
				color: Colors.MYOPIA_ON_SET_TRESHOLD,
				isLine: true,
			},
			{
				key: 'noMyopiaPresent',
				value: t('content.myopia.refractionAnalysis.graph.legend.noMyopiaPresent'),
				color: Colors.NO_MYOPIA_PRESENT,
			},
			{
				key: 'preMyopiaPossible',
				value: t('content.myopia.refractionAnalysis.graph.legend.preMyopiaPossible'),
				color: Colors.PRE_MYOPIA_POSSIBLE,
			},
			{
				key: 'myopiaPresent',
				value: t('content.myopia.refractionAnalysis.graph.legend.myopiaPresent'),
				color: Colors.MYOPIA_PRESENT,
			},
		],
		[t],
	)

	return <Legend legends={graphLegends} />
}

export default SphereEquivalentLegend
