import React from 'react'

import { SvgProps } from '../../model/model'

const TotNoImprovingIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			width="23px"
			height="23px"
			viewBox="0 0 15 15"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<g>
				<path d="M18.2,3.9H1.3c-0.4,0-0.7-0.3-0.7-0.7v-2c0-0.4,0.3-0.7,0.7-0.7h16.9c0.4,0,0.7,0.3,0.7,0.7v2 C19,3.6,18.6,3.9,18.2,3.9z" />
			</g>
		</svg>
	)
}

export default TotNoImprovingIcon
