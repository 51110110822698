import React, { useMemo } from 'react'
import styled from 'styled-components'

import {
	Step,
	StepLabel,
	Stepper as MStepper,
	StepperProps,
} from '@material-ui/core'
import { StepIconProps } from '@material-ui/core/StepIcon'

import { pxToRem } from '../libs/style'
import ThickIcon from './icons/ThickIcon'
import theme from './theme'

const StepIcon: React.FC<StepIconProps> = props => {
	const { active, completed, className } = props

	const classes = `${completed ? 'completed' : 'not-completed'} ${
		active ? 'active' : ''
	} ${className}`
	return (
		<div className={classes}>
			{completed ? <ThickIcon /> : <div className="point" />}
		</div>
	)
}

const StyledStepIcon = styled(StepIcon)`
	border-radius: 1.5rem;
	border: solid 1px ${theme.palette.grey[500]};
	padding: ${pxToRem(3)}rem;
	width: 1.5rem;
	height: 1.5rem;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);

	svg {
		width: 70%;
	}

	&.active {
		color: ${theme.palette.secondary.main};

		.point {
			width: 0.5rem;
			height: 0.5rem;
			border-radius: 50%;
			background-color: ${theme.palette.secondary.main};
		}
	}
`

const StyledStepper = styled(MStepper)`
	position: relative;
	width: 100%;
	min-width: 35rem;
	z-index: ${theme.zIndex.examStepper};

	&.MuiStepper-root {
		background-color: transparent;
		padding: 0;
	}

	.MuiStepLabel-root.MuiStepLabel-alternativeLabel {
		flex-direction: column-reverse;
	}

	.MuiStepLabel-label {
		&.MuiStepLabel-alternativeLabel {
			margin-top: 0;
			margin-bottom: ${pxToRem(16)}rem;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-decoration: none;
		}

		&.MuiStepLabel-active,
		&.MuiStepIcon-root.MuiStepIcon-active {
			line-height: 1;
		}
	}

	.MuiStepConnector-line {
		display: none;
	}

	.MuiStepLabel-label {
		color: ${theme.palette.grey[900]};
	}

	.Mui-disabled .MuiStepLabel-label {
		color: ${theme.palette.grey[500]};
	}

	.MuiStepLabel-active,
	.MuiStepIcon-root.MuiStepIcon-active {
		text-transform: uppercase;
		text-decoration: none;
		font-size: ${pxToRem(14)}rem;
		font-weight: 600;
		letter-spacing: 0.7px;
		line-height: ${pxToRem(15)}rem;
		text-align: center;
	}
`

const GradientBar = styled.div`
	height: 1.5rem;
	position: absolute;
	top: 1.875rem;
	z-index: -1;
	border-radius: 1.5rem;
	background: linear-gradient(
		0.143turn,
		#e8f3fe,
		#80abec,
		#5e7dd6,
		#4c71d9,
		#5e7dd6,
		#80abec,
		#e8f3fe
	);
`

const Underlined = styled.span`
	text-decoration: underline;
	text-underline-offset: 0.2em;
`

interface Props extends Omit<StepperProps, 'children'> {
	steps: string[]
	maxSteps: number
}

const Stepper: React.FC<Props> = ({ steps, maxSteps, ...props }) => {
	const stepElements = useMemo(() => {
		const result = []
		for (let i = 0; i < maxSteps; ++i) {
			const label = steps[i]
			result.push(
				<Step
					key={`${label}_${i}`}
					color="secondary"
					active={true}
					disabled={false}
					style={{
						visibility: label ? 'visible' : 'hidden',
					}}
				>
					<StepLabel StepIconComponent={StyledStepIcon}>
						<Underlined>{label}</Underlined>
					</StepLabel>
				</Step>,
			)
		}
		return result
	}, [maxSteps, steps])

	return (
		<StyledStepper {...props} alternativeLabel>
			{stepElements}
			<GradientBar
				style={{
					width: `calc(100% * ${steps.length} / ${maxSteps})`,
				}}
			></GradientBar>
		</StyledStepper>
	)
}

export default Stepper
