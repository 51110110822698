import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { selectVideoStatus } from '../../features/displayContent/selectors'
import { pxToRem } from '../../libs/style'
import { getAssetSrc } from '../../libs/utils'
import { InstrumentRemoteMedia } from '../../model/model'
import theme from '../../styleguide/theme'

const padding = theme.spacing(3)
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	margin: 0.5rem;
	background-color: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(${pxToRem(50)}rem);
	/* https://joshnh.com/tools/get-your-nested-border-radii-right.html */
	border-radius: ${pxToRem(padding - theme.shape.borderRadius)}rem;
	overflow: hidden;

	&.horizontal {
		grid-template-columns: 1fr auto 1fr;
	}

	&.vertical {
		grid-template-rows: 1fr auto 1fr;
	}
`

export const Label = styled.div`
	margin-bottom: 0.5rem;
	width: ${pxToRem(theme.spacing(7.5))}rem;
	height: ${pxToRem(theme.spacing(7.5))}rem;
	border-radius: 5rem;
	background: linear-gradient(
		210deg,
		${theme.palette.grey[200]},
		${theme.palette.secondary.light}
	);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const EyeShort = styled.div`
	font-size: ${pxToRem(14)}rem;
	font-family: 'Ivar Display', serif;
`

export const EyeLong = styled.div`
	font-size: ${pxToRem(11)}rem;
	font-family: 'Space Grotesk', sans-serif;
`

const ImageWrapper = styled.div`
	overflow: hidden;
`

const Img = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const Video = styled.video`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
`

const extractExtension = (path?: string) => path && path.split('.').pop()

const isImage = (
	media: InstrumentRemoteMedia | null,
): media is InstrumentRemoteMedia =>
	!!media &&
	['jpg', 'jpeg', 'webp', 'png'].includes(
		(media.format || extractExtension(media.path) || '').toLowerCase(),
	)

const isVideo = (
	media: InstrumentRemoteMedia | null,
): media is InstrumentRemoteMedia =>
	!!media &&
	['mp4', 'webm', 'mov'].includes(
		(media.format || extractExtension(media.path) || '').toLowerCase(),
	)

type Props = {
	direction?: 'horizontal' | 'vertical' | 'full'
	media: InstrumentRemoteMedia | null
}

const MediaBox: React.FC<Props> = ({ media, direction = 'horizontal' }) => {
	const { t } = useTranslation()
	const videoStatus = useSelector(selectVideoStatus)
	const videoRef = useRef<HTMLVideoElement>(null)
	const isMediaImage = isImage(media)
	const isMediaVideo = isVideo(media)
	const startTime = videoStatus?.startTime
	const endTime = videoStatus?.endTime
	const playing = videoStatus?.playing
	const currentTime = videoStatus?.currentTime
	useEffect(() => {
		if (!videoRef.current) {
			return
		}

		if (currentTime !== undefined) {
			videoRef.current.currentTime = currentTime
		}
		if (playing) {
			videoRef.current.play()
		} else {
			videoRef.current.pause()
		}
	}, [playing, currentTime])

	const loopTimeVideo = (currentTime: number) => {
		const video = videoRef.current
		if (!video) {
			return
		}
		const { duration } = video
		const shouldVideoLoop =
			(!!endTime && currentTime > endTime) ||
			(!!startTime && currentTime >= duration)

		if (shouldVideoLoop) {
			resetVideoTimeToStartTime()
		}
	}

	const resetVideoTimeToStartTime = () => {
		const video = videoRef.current!
		video.pause()
		video.currentTime = startTime || 0
		setTimeout(() => {
			video.play()
		}, 500)
	}

	if (!media || !media.path) {
		return null
	}

	const { path, eye, name, format } = media
	return (
		<Wrapper className={direction}>
			{eye ? (
				<Label>
					<EyeShort>{eye}</EyeShort>
					<EyeLong>{t(`app.${eye}Eye`)}</EyeLong>
				</Label>
			) : null}
			{!!path && (
				<ImageWrapper>
					{isMediaImage && <Img src={getAssetSrc(path)} alt={name} />}
					{isMediaVideo && (
						<Video
							onTimeUpdate={ev => loopTimeVideo(ev.currentTarget.currentTime)}
							src={getAssetSrc(path)}
							ref={videoRef}
							muted
						/>
					)}
					{!isMediaImage && !isMediaVideo && (
						<div>{t('errors.formatNotRecognised', { format })}</div>
					)}
				</ImageWrapper>
			)}
		</Wrapper>
	)
}

export default MediaBox
