import { createTheme } from '@material-ui/core/styles'

const gray50 = '#f6f7f8'

// To add custom variables to theme with TS
// https://material-ui.com/guides/typescript/#customization-of-theme
const customTheme = {
	typography: {
		fontFamily: '"Space Grotesk", Arial, sans-serif',
		fontSizes: {
			XXS: '0.4rem',
			XS: '0.6rem',
			S: '0.8rem',
			M: '1rem',
			L: '1.2rem',
			XL: '1.4rem',
			XXL: '1.6rem',
		},
	},
	shape: {
		borderRadius: 18,
		borderThickness: 1,
		pageMargin: 5,
	},
	palette: {
		primary: {
			main: '#20233d',
		},
		secondary: {
			main: '#20233D',
			light: '#61A4FF',
			contrastText: '#fff',
		},
		success: {
			light: '#1E850933',
			main: '#2abd2b',
		},
		error: {
			light: '#f8bbc2',
			main: '#E61B34',
			contrastText: '#fff',
		},
		background: {
			default: '#fff',
			secondary: '#20233D',
		},
		grey: {
			50: gray50,
			100: '#f1f2f4',
			200: '#e8ebed',
			300: '#ccd1d6',
			400: '#A6A7B2',
			500: '#7b8a9a',
			600: '#535b64',
			700: '#464e54',
			800: '#2d3238',
			900: '#292e33',
			A100: '#1f2327',
			A200: '#171a1c',
			A300: '#111214',
			A400: '#f3f3f3',
			A700: '#3e444b',
		},
	},
	zIndex: {
		mobileStepper: 1000,
		speedDial: 1050,
		pageElements: 1070,
		header: 1101,
		appBar: 1100,
		drawer: 1200,
		autocompletePopper: 1250,
		snackbar: 1400,
		tooltip: 1450,
		call: 1470,
		modal: 1500,
		lightbox: 1600,
		videoFullScreen: 100,
		videoFullScreenDetails: 101,
		examStepper: 0,
	},
}

const theme = createTheme(customTheme)

export const AZURE_PALETTE = {
	GREEN: '#4eb439',
	RED: '#e61b33',
}

export const GRAPH_PALETE = {
	GREEN: '#67B4B7',
	PURPLE: '#6825B4',
	L_GREEN: '#89D0D3',
	L_PURPLE: '#C59CF2',
}

export default theme
