import React from 'react'
import { LegendWrapper, LegendItemWrapper, LegendItemColor, LegendText } from './graph.style'
import { Legends } from './interfaces'

type Prop = {
	legends: Legends[]
}
const Legend: React.FC<Prop> = ({ legends }) => (
	<LegendWrapper>
		{legends.map((legend: any, index: number) => (
			<LegendItemWrapper key={index}>
				<LegendItemColor {...legend} />
				<LegendText>{legend.value}</LegendText>
			</LegendItemWrapper>
		))}
	</LegendWrapper>
)

export default Legend
