import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
	StreamMedia,
	useSelector as useAzureSelector,
} from '@azure/communication-react'
import ContentDisplay from '../components/ContentDisplay'
import Header from '../components/Header'
import PatientAndDoctorDetails from '../components/PatientAndDoctorDetails'
import {
	selectAppStore,
	selectAzureCommunicationToken,
	selectUsername,
} from '../features/auth/selectors'
import {
	selectZoomIndex,
	selectZoomPosition,
} from '../features/cursor/selectors'
import { selectInDisplayContent } from '../features/displayContent/selectors'
import { selectExam } from '../features/exam/selectors'
import { useDateFormatter } from '../hooks/useDateFormatter'
import { usePrepareExam } from '../hooks/usePrepareExam'
import { getCompletedSteps } from '../libs/stages'
import { DisplayableContentType, prescriptionTypeValues } from '../model/model'
import { VideoBackground } from '../styleguide/CommonComponents'
import Stepper from '../styleguide/Stepper'
import useCursor from './../features/cursor/Cursor'
import {
	CallClientState,
	LocalVideoStreamState,
	useCall,
	useCallClient,
	usePropsFor,
	VideoGallery,
	VideoGalleryParticipant,
	VideoTile,
} from '@azure/communication-react'
import { LocalVideoStream } from '@azure/communication-calling'
import withAzureClient from '../components/azureCommunication/utils/withAzureClient'
import { CALL_END_REASON_CODES } from '../components/azureCommunication/utils/callEndReasonCodes'
import {
	BackgroundImage,
	ColumnsWrapper,
	HorizontalGalleryWrapper,
	LeftColumn,
	LocalVideoTileWrapper,
	RightColumn,
	StepperCard,
	StepperCardTitle,
	VideoGalleryWrapper,
	Wrapper,
	WrapperHiddenOverflow,
} from '../components/azureCommunication/styles'
import CallCard from '../components/azureCommunication/components/AzureCall'

const hasPlainBg = (type?: DisplayableContentType) => {
	if (!type || prescriptionTypeValues.find(i => i === type)) return false
	else return true
}

const localStreamSelector = (
	state: CallClientState,
	{ callId }: { callId: string },
): LocalVideoStreamState | undefined => {
	const localStreamState = state?.calls?.[callId]?.localVideoStreams.find(
		item => item.mediaStreamType === 'Video',
	)
	return localStreamState
}

const HomePage: React.FC = () => {
	const { t } = useTranslation()

	const store = useSelector(selectAppStore)
	const { exam, doctor, numberOfVisits, examDif } = useSelector(selectExam)
	const displayContent = useSelector(selectInDisplayContent)
	const displayName = useSelector(selectUsername)
	const { userId } = useSelector(selectAzureCommunicationToken)

	const renderButton = useCursor()
	const WrapperRef = useRef<HTMLDivElement>(null)
	const normalizedZoomPosition = useSelector(selectZoomPosition)
	const zoomPosition = {
		x: normalizedZoomPosition.x * 100,
		y: normalizedZoomPosition.y * 100,
	}
	const normalizedZoomIndex = useSelector(selectZoomIndex)
	const zoomIndex = (normalizedZoomIndex * 25 + 100) / 100

	const videoGalleryProps = usePropsFor(VideoGallery)

	usePrepareExam()

	const type = displayContent?.type

	const steps = useMemo(
		() => getCompletedSteps(exam).map(key => t(`stepper.${key}`)),
		[exam, t],
	)

	const dateFormatter = useDateFormatter()

	const contentTypeNotShowStepper: (string | undefined)[] = [
		'topography',
		'crystallineLens',
		'tonometer',
		'videoSlitLamp',
		'retinalImaging',
		'visualFields',
		'visionSimulation',
	]

	const showStepper =
		!!exam && !!exam?.doctor && !contentTypeNotShowStepper.includes(type)

	const patientName =
		examDif?.personalInfo?.firstName || exam?.internalPatient.name
	const patientSurname =
		examDif?.personalInfo?.lastName || exam?.internalPatient.surname

	const patientDob = examDif?.personalInfo?.dateOfBirth
		? dateFormatter(
				examDif?.personalInfo?.dateOfBirth.toString().split('T')[0] + 'T00:00',
		  )
		: exam?.internalPatient.birthDate
		? dateFormatter(
				exam?.internalPatient.birthDate.toString().split('T')[0] + 'T00:00',
		  )
		: ''

	const call = useCall()
	const callClient = useCallClient()

	const localStreamState = useAzureSelector(localStreamSelector, {
		callId: call?.id ?? '',
	})

	const localVideoStream = useMemo(() => {
		return (
			localStreamState?.view &&
			localStreamState.view?.target && (
				<StreamMedia videoStreamElement={localStreamState.view.target} />
			)
		)
	}, [localStreamState?.view])

	// This function will start the local camera
	const onStartLocalVideo = useCallback(async () => {
		const deviceManager = await callClient.getDeviceManager()
		const grants = await deviceManager.askDevicePermission({
			audio: true,
			video: true,
		})
		if (!grants.audio || !grants.video) {
			console.error(CALL_END_REASON_CODES.find(e => e.code === 701))
		}
		const cameras = await deviceManager?.getCameras()
		const videoDeviceInfo =
			cameras && cameras.length > 0 ? cameras[0] : undefined

		if (!videoDeviceInfo) {
			return
		}

		const stream = new LocalVideoStream(videoDeviceInfo)

		call && (await call.startVideo(stream))
	}, [callClient, call])

	useEffect(() => {
		if (callClient && call && !call.isLocalVideoStarted) {
			onStartLocalVideo()
		}
	}, [callClient, call, onStartLocalVideo])

	useEffect(() => {
		if (localStreamState && call?.id) {
			callClient.createView(
				call?.id,
				undefined /*undefined for local video*/,
				localStreamState,
			)
		}
	}, [callClient, call, localStreamState])

	const disposeView = useCallback(() => {
		if (call && call.state === 'Disconnected' && localStreamState) {
			callClient.disposeView(call.id, undefined, localStreamState)
		}
	}, [call, localStreamState])

	useEffect(() => {
		return () => {
			disposeView()
		}
	}, [disposeView])

	const localParticipant: VideoGalleryParticipant = {
		...videoGalleryProps.localParticipant,
		userId: userId,
		displayName: displayName,
		isMuted: false,
	}

	const sharingDoctorScreen =
		videoGalleryProps.screenShareParticipant &&
		videoGalleryProps.screenShareParticipant.isScreenSharingOn

	const sharingConnectContent = type && !sharingDoctorScreen

	return (
		<WrapperHiddenOverflow>
			<Wrapper
				ref={WrapperRef}
				zoom={zoomIndex}
				translateX={zoomPosition.x}
				translateY={zoomPosition.y}
			>
				{hasPlainBg(type) ? (
					<BackgroundImage
						src="assets/images/i-pad-elements-content-background-1@3x.webp"
						alt=""
					/>
				) : (
					<VideoBackground />
				)}

				<Header showLogo={false} />

				{!sharingConnectContent && call && (
					<VideoGalleryWrapper>
						<VideoGallery
							{...videoGalleryProps}
							layout={'floatingLocalVideo'}
							remoteVideoViewOptions={{
								scalingMode: 'Crop',
							}}
							localVideoViewOptions={{
								isMirrored: true,
								scalingMode: 'Crop',
							}}
							localParticipant={localParticipant}
							localVideoTileSize="hidden"
							onRenderLocalVideoTile={() => <></>}
							styles={{
								gridLayout: {
									root: {
										padding: 0,
									},
								},
							}}
						/>
					</VideoGalleryWrapper>
				)}
				<ColumnsWrapper>
					<LeftColumn>
						<ContentDisplay type={type} data={displayContent?.data} />
					</LeftColumn>
					<RightColumn>
						{exam?.doctor && (
							<>
								{!sharingConnectContent && (
									<LocalVideoTileWrapper>
										{call ? (
											<VideoTile
												displayName={localParticipant.displayName}
												renderElement={
													<CallCard showMic>{localVideoStream}</CallCard>
												}
												userId={localParticipant.userId}
												styles={{
													root: {
														marginTop: '20px',
													},
												}}
											/>
										) : (
											<></>
										)}
									</LocalVideoTileWrapper>
								)}

								{sharingConnectContent && videoGalleryProps && call && (
									<HorizontalGalleryWrapper>
										<VideoGallery
											{...videoGalleryProps}
											layout={'default'}
											styles={{
												root: {
													marginTop: '20px',
													display: 'flex',
													flexDirection: 'row',
													height: '100%',
													width: '100%',
												},
												gridLayout: {
													root: {
														display: 'flex',
														flexDirection: 'row-reverse',
													},
												},
											}}
											remoteVideoViewOptions={{
												scalingMode: 'Crop',
											}}
											localVideoViewOptions={{
												isMirrored: true,
												scalingMode: 'Crop',
											}}
											localParticipant={localParticipant}
											onRenderLocalVideoTile={local => (
												<VideoTile
													displayName={local.displayName}
													renderElement={
														<CallCard showMic>{localVideoStream}</CallCard>
													}
													userId={local.userId}
													showMuteIndicator={true}
												/>
											)}
										/>
									</HorizontalGalleryWrapper>
								)}

								<PatientAndDoctorDetails
									isDoctor={false}
									patientFirstName={patientName}
									patientSecondName={patientSurname}
									examType={exam?.examType}
									patientBirthDate={patientDob}
									numberOfVisits={numberOfVisits}
									storeName={store?.name}
								/>
								<PatientAndDoctorDetails
									isDoctor={true}
									doctorFirstName={doctor?.name}
									doctorSecondName={doctor?.surname}
								/>
							</>
						)}
					</RightColumn>
				</ColumnsWrapper>
				{showStepper && (
					<StepperCard>
						<StepperCardTitle>{t('app.completedEyeExams')}</StepperCardTitle>
						<Stepper steps={steps} maxSteps={5} />
					</StepperCard>
				)}
				{renderButton}
			</Wrapper>
		</WrapperHiddenOverflow>
	)
}

export default withAzureClient(HomePage)
