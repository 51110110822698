import React from 'react'
import { pxToRem } from '../../libs/style'

import { SvgProps } from '../../model/model'

const ReplacementIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={pxToRem(28) + 'rem'}
			width={pxToRem(28) + 'rem'}
			viewBox="0 0 28 28"
			fill="currentColor"
			{...props}
		>
			<path
				d="M15.1,16.4v-4.1c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v4.1c-0.4,0.2-0.6,0.6-0.6,1c0,0.6,0.5,1.1,1.1,1.1
				s1.1-0.5,1.1-1.1C15.7,16.9,15.4,16.5,15.1,16.4z"/>
			<path
				d="M24.1,10.5l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l0.8,0.8l-1.7,1.7c-1.4-1.2-3.2-2-5.1-2.1V7.5c0,0,0,0,0-0.1
				h1.2c1,0,1.8-0.8,1.8-1.8c0-1-0.8-1.8-1.8-1.8h-3.4c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8h1.2c0,0,0,0,0,0.1v1.6
				c-4.5,0.3-8,4-8,8.5c0,4.7,3.8,8.5,8.5,8.5s8.5-3.8,8.5-8.5c0-2.2-0.8-4.1-2.2-5.6l1.7-1.7l0.8,0.8c0.1,0.1,0.2,0.1,0.4,0.1
				s0.3,0,0.4-0.1C24.3,11,24.3,10.7,24.1,10.5z M12.9,6.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h3.4c0.4,0,0.8,0.3,0.8,0.8
				s-0.3,0.8-0.8,0.8H12.9z M22.1,17.6c0,4.1-3.4,7.5-7.5,7.5c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5C18.7,10.1,22.1,13.5,22.1,17.6z"/>
		</svg>
	)
}

export default ReplacementIcon
