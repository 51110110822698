import { Route, Router, Switch } from 'react-router-dom'

import history from './history'
import HomePage from './pages/HomePage'

export const paths = {
	home: '/:examId?',
}

const Routes = () => {
	return (
		<Router history={history}>
			<Switch>
				<Route path={paths.home}>
					<HomePage />
				</Route>
			</Switch>
		</Router>
	)
}

export default Routes
