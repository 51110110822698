import React, { ChangeEventHandler } from 'react'
import styled from 'styled-components'

const ICON_BORDER_THICKNES = '1px'
const ICON_WIDTH = '1.5em'
const ICON_INNER_WIDTH = '0.75em'

const Label = styled.label`
	display: flex;
	align-items: center;

	input {
		display: none;
	}

	.radio-icon {
		border: solid ${ICON_BORDER_THICKNES} currentColor;
		border-radius: 3em;
		margin-right: 1.5em;
		width: ${ICON_WIDTH};
		height: ${ICON_WIDTH};
	}

	input:checked + .radio-icon::before {
		content: ' ';
		display: block;
		border-radius: 3em;
		margin-right: 0.5em;
		width: ${ICON_INNER_WIDTH};
		height: ${ICON_INNER_WIDTH};
		margin: calc(
			((${ICON_WIDTH} - ${ICON_INNER_WIDTH}) / 2) - ${ICON_BORDER_THICKNES}
		);
		background-color: currentColor;
	}
`

type Props = {
	name: string
	value: string
	onChange: ChangeEventHandler<HTMLInputElement>
	label: string
	options: { value: string; label: string; sublabel?: string }[]
	className?: string
	dataTestClassName?: string
}

const RadioGroup: React.FC<Props> = ({
	label,
	name,
	value,
	onChange,
	options,
	className,
	dataTestClassName,
}) => (
	<fieldset className={['radio-group', className].join(' ')}>
		<legend className="radio-group__label">{label}</legend>
		<div className="radio-group__options">
			{options.map(({ value: optionValue, label, sublabel }, index) => (
				<Label
					data-testid={`selection-${dataTestClassName}-option-${index}`}
					className={[
						'radio-group__option',
						optionValue === value ? 'selected' : '',
					].join(' ')}
					key={optionValue}
				>
					<input
						type="radio"
						name={name}
						value={optionValue}
						checked={optionValue === value}
						onChange={onChange}
					/>
					<div className="radio-icon"></div>
					<div className="radio-group__option__labels">
						<div className="radio-group__option__label">{label}</div>
						{!!sublabel && (
							<div className="radio-group__option__sublabel">{sublabel}</div>
						)}
					</div>
				</Label>
			))}
		</div>
	</fieldset>
)

export default RadioGroup
