import { configureStore } from '@reduxjs/toolkit'

import auth from './features/auth/slice'
import displayContent from './features/displayContent/slice'
import exam from './features/exam/slice'
import notifications from './features/notifications/slice'
import settings from './features/settings/slice'
import cursor from './features/cursor/slice'
import features from './features/feature/slice'
import coremedia from './features/coremedia/slice'
import { RootState } from './model/model'

const reducer = {
	auth,
	cursor,
	displayContent,
	exam,
	features,
	notifications,
	settings,
	coremedia,
}

export const getTestStore = (preloadedState: Partial<RootState>) =>
	configureStore({
		reducer,
		preloadedState,
	})

const store = configureStore({
	reducer,
})

export type TeloDispatch = typeof store.dispatch
export type TeloGetState = typeof store.getState
export type TeloRootState = ReturnType<TeloGetState>

export default store
