import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import TrendsOverTimeGraphCard from './TrendsOverTimeGraphCard'
import { TotSummaryGraphsContainer } from './styles'
import { GraphSummaryWrapper } from '../graphs/graph.style'
import { TrendsOverTimeGraphData } from '../../../model/model'
import InstrumentGraph from './InstrumentGraph'

type Props = {
	data: TrendsOverTimeGraphData
}

const TrendsOverTimeGraphs: FC<Props> = ({ data }) => {
	const { tot: examData } = data
	const { t } = useTranslation()

	return (
		<TotSummaryGraphsContainer>
			<TrendsOverTimeGraphCard $borderBotRight>
				<InstrumentGraph
					instrumentData={examData}
					instrumentType="sphere"
					eye=""
					legend={t('tot.graphs.legends.sphere')}
					legendType={t('tot.graphs.acronyms.sphere')}
				/>
			</TrendsOverTimeGraphCard>
			<TrendsOverTimeGraphCard $borderBottom>
				<InstrumentGraph
					instrumentData={examData}
					instrumentType="cyl"
					eye=""
					legend={t('tot.graphs.legends.cyl')}
					legendType={t('tot.graphs.acronyms.cyl')}
				/>
			</TrendsOverTimeGraphCard>

			<TrendsOverTimeGraphCard $borderRight>
				<GraphSummaryWrapper addPaddingTop={true}>
					<InstrumentGraph
						instrumentData={examData}
						instrumentType="spheq"
						eye=""
						legend={t('tot.graphs.legends.spheq')}
						legendType={t('tot.graphs.acronyms.spheq')}
					/>
				</GraphSummaryWrapper>
			</TrendsOverTimeGraphCard>
			<TrendsOverTimeGraphCard>
				<GraphSummaryWrapper addPaddingTop={true}>
					<InstrumentGraph
						instrumentData={examData}
						instrumentType="add"
						eye=""
						legend={t('tot.graphs.legends.add')}
					/>
				</GraphSummaryWrapper>
			</TrendsOverTimeGraphCard>
		</TotSummaryGraphsContainer>
	)
}

export default TrendsOverTimeGraphs
