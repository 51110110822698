import React from 'react'
import { useTranslation } from 'react-i18next'
import {
	EyeDataRow,
	EyeTableBox,
	FirstLine,
	SecondLine,
	TableTitle,
	TitleWrapper,
	Wrapper,
} from './CommonComponents'
import styled from 'styled-components'
import { PhoropterDataPart, PhoropterSingleEye } from '../../model/refraction'
import {
	returnFormattedValueForAxis,
	returnPlusSignedValue,
} from '../../libs/utils'
import { pxToRem } from '../../libs/style'

export const LenseImage = styled.img<{ src: string }>`
	src: ${props => props.src};
	width: ${pxToRem(342)}rem;
	height: auto;
	margin: ${pxToRem(11)}rem ${pxToRem(267)}rem ${pxToRem(42)}rem
		${pxToRem(245)}rem;
	object-fit: contain;
`

const Refraction: React.FC<{
	phoropter: PhoropterDataPart
}> = ({ phoropter }) => {
	const { t } = useTranslation()
	if (!phoropter) return null

	const mapData = (data: PhoropterSingleEye, sensitivity?: number) => {
		return [
			{
				label: 'app.Sphere',
				value: data?.sphere
					? returnPlusSignedValue({ value: data?.sphere, defaultValue: 'PL' })
					: 'PL',
			},
			{
				label: 'app.Cylinder',
				value: data?.cyl
					? returnPlusSignedValue({
							value: data?.cyl,
							defaultValue: 'DS',
							applyDecimalValues: true,
					  })
					: 'DS',
			},
			{
				label: 'app.Axis',
				value: data?.axis ? returnFormattedValueForAxis(data?.axis) : '-',
			},
			{
				label: data?.hOrient
					? data?.hOrient === 'BI'
						? 'app.PrismBI'
						: 'app.PrismBO'
					: 'app.Prism',
				value: data?.hPrism
					? returnPlusSignedValue({
							value: data?.hPrism,
							defaultValue: '-',
							omitPlusSign: true,
					  })
					: '-',
			},
			{
				label: data?.vOrient
					? data?.vOrient === 'BD'
						? 'app.PrismBD'
						: 'app.PrismBU'
					: 'app.Prism',
				value: data?.vPrism
					? returnPlusSignedValue({
							value: data?.vPrism,
							defaultValue: '-',
							omitPlusSign: true,
					  })
					: '-',
			},
			{
				label: 'app.Add',
				value: data?.add
					? returnPlusSignedValue({ value: data?.add, defaultValue: '-' })
					: '-',
			},
			{
				label: 'app.sensitivity',
				value: sensitivity ? sensitivity.toString() : '-',
			},
		]
	}

	return (
		<Wrapper>
			<TitleWrapper>
				<FirstLine>{t('content.myopia.myopiaCareSolution')}</FirstLine>
				<SecondLine>{t('content.myopia.refractionValues')}</SecondLine>
			</TitleWrapper>
			<EyeTableBox>
				<TableTitle>{t('app.prescriptionTitle')}</TableTitle>
				<EyeDataRow
					eye={'OD'}
					data={mapData(
						phoropter?.day?.default.eyes.OD,
						phoropter?.sensitivity,
					)}
				/>
				<EyeDataRow
					eye={'OS'}
					data={mapData(
						phoropter?.day?.default.eyes.OS,
						phoropter?.sensitivity,
					)}
				/>
			</EyeTableBox>
		</Wrapper>
	)
}

export default Refraction
