import { useTranslation } from 'react-i18next'
import {
	CalculatedAxialLengthData,
	CalculatedSphereEqData,
} from '../graphs/interfaces/graph.interface'
import { SphereEqGraph } from '../graphs/SphereEquivalentGraph'
import AxialLengthGraph from '../graphs/AxialLengthGraph'
import MyopiaSummaryGraphCard from './MyopiaSumaryGraphCard'
import { MyopiaSummaryGraphsContainer } from './styles'
import { GraphSummaryWrapper } from '../graphs/graph.style'
import { useMyopiaGraphData } from '../../../hooks/useMyopiaGraphData'

const REFRACTION_TRANSLATION_ROUTE = 'content.myopia.refractionAnalysis'
const AXIAL_TRANSLATION_ROUTE = 'content.myopia.axialLengthAnalysis'

const MyopiaSummaryGraphs = () => {
	const { t } = useTranslation()
	const data = useMyopiaGraphData()

	return (
		<MyopiaSummaryGraphsContainer>
			{data ? (
				<>
					<MyopiaSummaryGraphCard
						title={t(`${REFRACTION_TRANSLATION_ROUTE}.title`)}
						titlePrefix={t(`${REFRACTION_TRANSLATION_ROUTE}.OS`)}
						$borderBotRight
					>
						<SphereEqGraph
							scaleY={data.calculatedSphereEquivalent?.scaleY}
							calculatedValues={
								data.calculatedSphereEquivalent?.left as CalculatedSphereEqData
							}
							showLegends
						/>
					</MyopiaSummaryGraphCard>
					<MyopiaSummaryGraphCard
						title={t(`${REFRACTION_TRANSLATION_ROUTE}.title`)}
						titlePrefix={t(`${REFRACTION_TRANSLATION_ROUTE}.OD`)}
						$borderBottom
					>
						<SphereEqGraph
							scaleY={data.calculatedSphereEquivalent?.scaleY}
							calculatedValues={
								data.calculatedSphereEquivalent?.right as CalculatedSphereEqData
							}
							showLegends
						/>
					</MyopiaSummaryGraphCard>
					<MyopiaSummaryGraphCard
						title={t(`${AXIAL_TRANSLATION_ROUTE}.title`)}
						titlePrefix={t(`${REFRACTION_TRANSLATION_ROUTE}.OS`)}
						$borderRight
					>
						<GraphSummaryWrapper>
							<AxialLengthGraph
								scaleY={data.calculatedAxialLength?.scaleY}
								calculatedValues={
									data.calculatedAxialLength?.left as CalculatedAxialLengthData
								}
								showLegends
								ethnicity={data.calculatedAxialLength?.ethnicity}
								gender={data.calculatedAxialLength?.gender}
							/>
						</GraphSummaryWrapper>
					</MyopiaSummaryGraphCard>
					<MyopiaSummaryGraphCard
						title={t(`${AXIAL_TRANSLATION_ROUTE}.title`)}
						titlePrefix={t(`${REFRACTION_TRANSLATION_ROUTE}.OD`)}
					>
						<GraphSummaryWrapper>
							<AxialLengthGraph
								scaleY={data.calculatedAxialLength?.scaleY}
								calculatedValues={
									data.calculatedAxialLength?.right as CalculatedAxialLengthData
								}
								showLegends
								ethnicity={data.calculatedAxialLength?.ethnicity}
								gender={data.calculatedAxialLength?.gender}
							/>
						</GraphSummaryWrapper>
					</MyopiaSummaryGraphCard>
				</>
			) : null}
		</MyopiaSummaryGraphsContainer>
	)
}

export default MyopiaSummaryGraphs
