import { Socket } from 'socket.io-client'

type Optional<T> = T | null | undefined

const hasSessionId = (data: any): data is { sessionId: string } =>
	typeof data === 'object' &&
	'sessionId' in data &&
	typeof data.sessionId === 'string'

export const checkOrigin = <T>(sessionId: string, data: T): boolean => {
	const sameOrigin = hasSessionId(data) && data.sessionId === sessionId
	return sameOrigin
}

type SocketArg = Optional<Socket>

export const isSocketConnected = (socket: SocketArg): boolean =>
	socket?.connected ?? false

export const basicDisconnectSocket = (socket: SocketArg): void => {
	if (!isSocketConnected(socket)) {
		return
	}
	socket!.disconnect()
}
