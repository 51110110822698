import React from 'react'
import { Grid } from '@material-ui/core'
import { GraphBinoWrapper } from '../graph.style'
import { CalculatedSphereEquivalent, Scale } from '../interfaces'
import { SphereEqGraph } from '.'
import { useTranslation } from 'react-i18next'
import SphereEquivalentLegend from './SphereEquivalentLegend'
import GraphCard from './GraphCard'
import GraphCardClipPath from '../GraphCardClipPath'

type Props = {
  scaleY: Scale | undefined
  calculatedSphereEquivalent: CalculatedSphereEquivalent
}

const SphereEquivalentGraphBino: React.FC<Props> = ({scaleY, calculatedSphereEquivalent}) => {
  const { t } = useTranslation()

  return (
    <GraphBinoWrapper>
      <GraphCardClipPath/>
      <GraphCard
        eyeLabel = {{
          firstLine: 'OD',
          secondLine: t('app.ODEye')
        }}
      >
        <SphereEqGraph
          scaleY={scaleY}
          calculatedValues={calculatedSphereEquivalent?.right}
        />        
      </GraphCard>
      <GraphCard
        eyeLabel = {{
          firstLine: 'OS',
          secondLine: t('app.OSEye')
        }}
      >
        <SphereEqGraph
          scaleY={scaleY}
          calculatedValues={calculatedSphereEquivalent?.left}
        />        
      </GraphCard>
      <Grid item xs={12}>
        <SphereEquivalentLegend />
      </Grid>
    </GraphBinoWrapper>
  )
}

export default SphereEquivalentGraphBino