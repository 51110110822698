import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectStageId } from '../features/auth/selectors'
import { selectRoomId, selectStoreId } from '../features/settings/selectors'
import { Id } from '../model/model'
import { connectToRoom, disconnectFromRoom } from '../socket/room/roomActions'

type UseSocketArgs = {
	roomName?: string
	storeId?: string
	examId?: string
}

const useSocket = ({ roomName, storeId, examId }: UseSocketArgs) => {
	const dispatch = useDispatch()
	useEffect(() => {
		if (!roomName) {
			return
		}
		dispatch(connectToRoom({ examId, roomName, storeId }))

		return () => {
			dispatch(disconnectFromRoom(roomName))
		}
	}, [dispatch, examId, roomName, storeId])
}

export const useSocketRoom = () => {
	const storeId = useSelector(selectStoreId)
	const roomId = useSelector(selectRoomId)
	const stageId = useSelector(selectStageId)

	useSocket({
		roomName:
			storeId && stageId && roomId
				? `store-id-${storeId}_stage-id-${stageId}_room-id-${roomId}`
				: undefined,
		storeId,
	})
}

export const useSocketShare = (examId?: string) => {
	useSocket({
		roomName: examId ? `cockpit-${examId}` : undefined,
		examId,
	})
}

export const useSocketExamById = (examId?: Id) =>
	useSocket({
		roomName: examId ? `exam-id-${examId}` : undefined,
		examId,
	})
