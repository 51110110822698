import {
	AxialLengthGraphData,
	CalculatedAxialLengthData,
	Point,
	Line as LineInterface,
} from '../interfaces/graph.interface'
import { findValueByAgeAndKey, range } from '../utils'

export const getMinMaxAxis = (
	values: Partial<Point>[],
	percentiles: LineInterface[],
	axis: 'x' | 'y',
) => {
	const axialLenghtValues = values.map(point => point[axis])
	const c2Values = percentiles
		.find(percentile => percentile.name === 'c2')
		?.data.map(point => point[axis]) as number[]
	const c95Values = percentiles
		.find(percentile => percentile.name === 'c95')
		?.data.map(point => point[axis]) as number[]

	const allValues = [
		...axialLenghtValues,
		...c2Values,
		...c95Values,
	] as number[]

	const min = Math.floor(Math.min(...allValues))
	const max = Math.ceil(Math.max(...allValues))

	return {
		min,
		max,
	}
}

export const createChartDataFromAxialLength = (
	dataToBuildGraph: CalculatedAxialLengthData,
): AxialLengthGraphData[] => {
	const values = dataToBuildGraph.values
	const minMaxAge = getMinMaxAxis(values, dataToBuildGraph.percentiles, 'x')

	const agesFromMinAgeToMaxAge = range(
		minMaxAge.max - minMaxAge.min + 1,
		minMaxAge.min,
	)

	const c2 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c2',
	)?.data as Point[]
	const c5 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c5',
	)?.data as Point[]
	const c10 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c10',
	)?.data as Point[]
	const c25 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c25',
	)?.data as Point[]
	const c50 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c50',
	)?.data as Point[]
	const c75 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c75',
	)?.data as Point[]
	const c90 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c90',
	)?.data as Point[]
	const c95 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c95',
	)?.data as Point[]
	const c98 = dataToBuildGraph.percentiles.find(
		percentile => percentile.name === 'c98',
	)?.data as Point[]

	const graphData = agesFromMinAgeToMaxAge.reduce<AxialLengthGraphData[]>(
		(array, age) => {
			array.push({
				age,
				value: findValueByAgeAndKey(values, age, 'y'),
				c2: findValueByAgeAndKey(c2, age, 'y'),
				c5: findValueByAgeAndKey(c5, age, 'y'),
				c10: findValueByAgeAndKey(c10, age, 'y'),
				c25: findValueByAgeAndKey(c25, age, 'y'),
				c50: findValueByAgeAndKey(c50, age, 'y'),
				c75: findValueByAgeAndKey(c75, age, 'y'),
				c90: findValueByAgeAndKey(c90, age, 'y'),
				c95: findValueByAgeAndKey(c95, age, 'y'),
				c98: findValueByAgeAndKey(c98, age, 'y'),
			})
			return array
		},
		[],
	)

	return graphData
}
