import React from 'react'
import { useTranslation } from 'react-i18next'

import VideoCallStateChip from '../styleguide/VideoCallStateChip'
import { useCall } from '@azure/communication-react'

const VideoCallState: React.FC = () => {
	const call = useCall()
	const { t } = useTranslation()

	return (
		<VideoCallStateChip
			label={t(`azureCommunication.${call ? 'live' : 'onHold'}`)}
			color={call ? 'success' : 'secondary'}
		/>
	)
}

export default VideoCallState
