import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Legend from '../legend'
import { Colors } from './constant'

const AxialLengthLegend: React.FC = () => {
	const { t } = useTranslation()
	const graphLegends = useMemo(
		() => [
			{
				key: 'c2',
				value: t('content.myopia.axialLengthAnalysis.graph.legend.c2'),
				color: Colors.C2,
			},
			{
				key: 'c50',
				value: t('content.myopia.axialLengthAnalysis.graph.legend.c50'),
				color: Colors.C50,
			},
			{
				key: 'c98',
				value: t('content.myopia.axialLengthAnalysis.graph.legend.c98'),
				color: Colors.C98,
			},
		],
		[t],
	)

	return <Legend legends={graphLegends} />
}

export default AxialLengthLegend
