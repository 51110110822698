import { ExamStatus } from './exam'
import { Id } from './model'

export type AccuracyValue = '0.01' | '0.25'

export type EyeApi = 'L' | 'R' | 'B'

export type ModeApi = 'day' | 'night'

export const instrumentTypes = [
	'LM',
	'AR',
	'KM',
	'NT',
	'PH',
	'VF',
	'SL',
	'RI',
] as const

export type InstrumentType = (typeof instrumentTypes)[number]

export type RemoteMediaApi = {
	name: string
	eye: EyeApi
	mode?: ModeApi
	format: string
	path?: string
	data?: string
	note?: string
	status?: ExamStatus
	createdAt?: string
	contactLensesCode?: Id
}

export type TonometerMeasureApi = {
	iop1?: string
	iop2?: string
	iopc?: string
}

export type PachymetryDataApi = {
	L: {
		media: RemoteMediaApi[]
		note?: string
	}
	R: {
		media: RemoteMediaApi[]
		note?: string
	}
}

export type TonometerDataApi = {
	L: TonometerMeasureApi
	R: TonometerMeasureApi
	acquisitionTime?: number
	examStatus?: ExamStatus
	pachymetry?: PachymetryDataApi
}

export type InstrumentDataApi = TonometerDataApi

export type GetInstrumentDataResponseApi = {
	examData?: InstrumentDataApi
	examStatus?: ExamStatus
	updatedAt?: Date
}

export type BaseValue = 'BI' | 'BO' | 'BU' | 'BD' | 'in' | 'out' | 'down' | 'up'

export type AddDescriptionValues =
	| 'HI'
	| 'HIGH'
	| 'MED'
	| 'MEDIUM'
	| 'MID'
	| 'LO'
	| 'LOW'
	| 'D'
	| 'N'
	| ''

export type Accuracy = '0.01' | '0.25'
export type PrismValueApi = {
	base: BaseValue
	value: string
}

export type PhoropterSingleEyeApi = {
	sphere: string
	cylinder: string
	axis: string
	add: string
	prism: {
		x: PrismValueApi
		y: PrismValueApi
	}
	va: string
	vaAdditional?: number
	description: AddDescriptionValues
}

export type PhoropterDataWithAccuracyApi = {
	accuracy: Accuracy
	L?: PhoropterSingleEyeApi
	R?: PhoropterSingleEyeApi
	B: {
		va: {
			near: string
			nearAdditional?: number
			distance: string
			distanceAdditional?: number
		}
	}
}

export type PhoropterDataDayOrNightApi = {
	default: PhoropterDataWithAccuracyApi
	other?: PhoropterDataWithAccuracyApi
}

export type PhoropterDataPartApi = {
	day: PhoropterDataDayOrNightApi
	night?: PhoropterDataDayOrNightApi
	updatedAt?: Date
	note: string
	ph?: {
		R: string
		L: string
		B: string
	}
	glare?: {
		R: string
		L: string
		B: string
	}
}

export type BiometerDataApi = {
	L: {
		axialLength: number
	}
	R: {
		axialLength: number
	}
}

export type PhoropterDataApi = {
	subjectiveRefraction?: PhoropterDataPartApi
	cycloplegicRefraction?: PhoropterDataPartApi
	finalRx?: PhoropterDataPartApi
	contactLenses?: PhoropterDataPartApi
	pupillaryDistance?: string
	sensitivity?: number
}
