import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
	returnFormattedValueForAxis,
	returnPlusSignedValue,
} from '../../../libs/utils'
import { EyeInfo } from './EyeInfo'
import { EyeLogo } from './EyeLogo'
import { EyeData } from './types'

const EyeGridRoot = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 1fr);
`

const EyeHeaderLogo = styled(EyeLogo)`
	grid-area: 1 / 1 / span 2 / span 1;
	display: flex;
`

const EyeInfoCustom = styled(EyeInfo)`
	& .eye-sphere {
		grid-area: 1 / 2;
	}

	& .eye-cylinder {
		grid-area: 1 / 3;
	}

	& .eye-axis {
		grid-area: 1 / 4;
	}

	& .eye-prism-bi {
		grid-area: 2 / 2;
	}

	& .eye-prism-bd {
		grid-area: 2 / 3;
	}

	& .eye-add {
		grid-area: 2 / 4;
	}
`

type EyeGridProps = EyeData

export const EyeGrid = function EyeGrid({
	add,
	axis,
	sphere,
	cylinder,
	hPrism,
	hOrient,
	vPrism,
	vOrient,
	eye,
}: EyeGridProps): JSX.Element {
	const { t } = useTranslation()
	const shpereValue =
		sphere || sphere === ''
			? returnPlusSignedValue({ value: sphere, defaultValue: 'PL' }).toString()
			: 'PL'

	const cylinderValue =
		cylinder || cylinder === ''
			? returnPlusSignedValue({
					value: cylinder,
					defaultValue: 'DS',
					applyDecimalValues: true,
			  }).toString()
			: 'DS'
	const axisValue = returnFormattedValueForAxis(axis)
	const hPrismValue = hPrism
		? returnPlusSignedValue({
				value: hPrism,
				defaultValue: '-',
				omitPlusSign: true,
		  }).toString()
		: '-'
	const vPrismValue = vPrism
		? returnPlusSignedValue({
				value: vPrism,
				defaultValue: '-',
				omitPlusSign: true,
		  }).toString()
		: '-'
	const addValue = add
		? returnPlusSignedValue({ value: add, defaultValue: '-' }).toString()
		: '-'

	return (
		<EyeGridRoot>
			<EyeHeaderLogo text={eye} />
			<EyeInfoCustom
				label={t('clientContentSummary.sphere')}
				value={shpereValue}
				className="eye-sphere"
			/>
			<EyeInfoCustom
				label={t('clientContentSummary.cylinder')}
				value={cylinderValue}
				className="eye-cylinder"
			/>
			<EyeInfoCustom
				label={t('clientContentSummary.axis')}
				value={axisValue}
				className="eye-axis"
			/>
			<EyeInfoCustom
				label={
					hOrient ? t(`clientContentSummary.prism${hOrient}`) : t('app.Prism')
				}
				value={hPrismValue}
				className="eye-prism-bi"
			/>
			<EyeInfoCustom
				label={
					vOrient ? t(`clientContentSummary.prism${vOrient}`) : t('app.Prism')
				}
				value={vPrismValue}
				className="eye-prism-bd"
			/>
			<EyeInfoCustom
				label={t('clientContentSummary.add')}
				value={addValue}
				className="eye-add"
			/>
		</EyeGridRoot>
	)
}
