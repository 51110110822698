import styled from 'styled-components'
import { PageWrapper } from '../../styleguide/CommonComponents'
import { pxToRem } from '../../libs/style'
import theme from '../../styleguide/theme'

export const WrapperHiddenOverflow = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`

export const Wrapper = styled(PageWrapper)<{
	zoom?: number
	translateX?: number
	translateY?: number
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	padding-top: ${pxToRem(theme.spacing(theme.shape.pageMargin))}rem;
	${props =>
		`transform: scale(${props.zoom}, ${props.zoom}); transform-origin: ${props.translateX}% ${props.translateY}%;`}

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		padding-top: ${pxToRem(theme.spacing(3))}rem;
	}
`

const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background-color: ${theme.palette.background.default};
	border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	padding: ${pxToRem(theme.spacing(2))}rem ${pxToRem(theme.spacing(3))}rem;
	position: absolute;
	width: 100%;
	transition: bottom ${theme.transitions.duration.standard}ms,
		right ${theme.transitions.duration.standard}ms,
		top ${theme.transitions.duration.standard}ms;
	&&.lateral {
		right: 0;
	}
	min-height: 13vh;
`

export const StepperCard = styled(Card)`
	flex-direction: row;
	align-items: center;
	position: relative;
	border: 1px solid white;
	background-color: rgba(255, 255, 255, 0.7);
	box-shadow: 0 ${pxToRem(20)}rem ${pxToRem(30)}rem 0 rgba(0, 0, 0, 0.01);
	min-width: 52rem;
`

export const StepperCardTitle = styled.div`
	width: ${pxToRem(170)}rem;
	font-size: ${pxToRem(22)}rem;
	font-weight: 600;
	letter-spacing: -0.19px;
	line-height: ${pxToRem(20.6)}rem;
	padding-right: 3rem;
`

export const LeftColumn = styled.div`
	position: relative;
	flex: 0.75;
	max-width: 75%;

	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		flex: 0.7;
		max-width: 70%;
	}
`
export const RightColumn = styled.div`
	position: relative;
	flex: 0.25;
	display: flex;
	flex-direction: column;
	justify-content: end;
	margin-bottom: ${pxToRem(theme.spacing(3))}rem;
	padding-left: ${pxToRem(theme.spacing(3))}rem;
	margin-top: ${pxToRem(theme.spacing(3))}rem;
	@media only screen and (max-device-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
		flex: 0.3;
		max-width: 30%;
		margin-bottom: ${pxToRem(theme.spacing(2))}rem;
		padding-left: ${pxToRem(theme.spacing(2))}rem;
	}
`
export const ColumnsWrapper = styled.div`
	position: relative;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	flex-direction: row;
	flex: 1;
	overflow: hidden;
`

export const BackgroundImage = styled.img`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
`

export const VideoGalleryWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 0;

	[data-ui-id='video-gallery'] > div > div.ms-Stack {
		padding: 0;
	}

	[data-ui-id='responsive-horizontal-gallery'] {
		display: none;
	}
`

export const LocalVideoTileWrapper = styled.div`
	margin-left: auto;
	margin-right: 0;

	width: 50%;
	margin-bottom: auto;

	[data-ui-id='video-tile'] {
		border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
		aspect-ratio: 10 / 16;
	}

	video {
		border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	}
`

export const HorizontalGalleryWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	background-color: transparent;
	margin-bottom: auto;

	[data-ui-id='video-tile'] {
		border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	}

	[data-ui-id='grid-layout'] {
		aspect-ratio: 1;
		height: unset;
		max-height: 100%;
		border-radius: ${pxToRem(theme.shape.borderRadius)}rem;
	}

	[data-ui-id='video-gallery'] > .ms-Stack {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	video {
		border-radius: ${pxToRem(theme.shape.borderRadius)}rem;

		width: 100%;
		height: 100%;
		flex: 1;
		/* override inline style by ACS */
		object-fit: cover !important;
	}
`
