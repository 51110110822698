import React, { useEffect, useState } from 'react'
import MicIcon from '../../../styleguide/icons/MicIcon'
import { ButtonContainer, ControlButton, Wrapper } from '../../styles'
import { useCall } from '@azure/communication-react'

type CallCardProps = {
	children: React.ReactNode
	showMic?: boolean
}

const CallCard: React.FC<CallCardProps> = ({ children, showMic = false }) => {
	const [fadeInClass, setFadeInClass] = useState('')
	const [audioEnabled, setAudioEnabled] = useState<boolean>(true)

	const call = useCall()
	const disableButtons = !call

	const handleMicOnOff = async () => {
		try {
			if (!call) {
				return
			}
			if (!call.isMuted) {
				await call.mute()
			} else {
				await call.unmute()
			}
			setAudioEnabled(!call.isMuted)
		} catch (error: any) {
			console.error(
				'Videocall, error during muting microphone: ' + error.message,
			)
		}
	}

	useEffect(() => {
		setFadeInClass('fade-in')
	}, [])

	return (
		<Wrapper className={fadeInClass}>
			{call ? (
				<>
					{children}

					{showMic && (
						<ButtonContainer>
							<ControlButton
								aria-label="toggle audio"
								disabled={disableButtons}
								onClick={() => handleMicOnOff()}
								className={audioEnabled ? 'enabled' : 'disabled'}
							>
								<MicIcon />
							</ControlButton>
						</ButtonContainer>
					)}
				</>
			) : (
				<></>
				// <DoctorPlaceholderCard small={somethingInDisplay} />
			)}
		</Wrapper>
	)
}

export default CallCard
