import { differenceInYears } from 'date-fns'
import { isUndefined } from 'lodash'
import { calculateSphereEqFromSphAndCyl } from '../components/content/graphs/SphereEquivalentGraph/utils'
import {
	isBetween,
	FROM_AGE,
	TO_AGE,
	Side,
} from '../components/content/graphs/utils'
import { InternalPatient, WithExamId } from '../model/exam'
import {
	BiometerDataApi,
	GetInstrumentDataResponseApi,
	PhoropterDataApi,
} from '../model/instruments'

export const getPatientAge = (
	patient?: InternalPatient,
	fromDate = new Date(),
) => {
	if (!patient?.birthDate) {
		return null
	}

	let birthDate: Date | string = patient.birthDate
	if (typeof birthDate === 'string') {
		birthDate = new Date(
			(birthDate as string).includes('T') ? birthDate : birthDate + 'T00:00:00',
		)
	}
	return differenceInYears(fromDate, birthDate)
}

export const formatExamDataWithAge = <
	T extends BiometerDataApi | PhoropterDataApi,
>(
	internalPatient: InternalPatient,
	exams: WithExamId<GetInstrumentDataResponseApi>[],
	type: 'BI' | 'PH',
	examId: string | undefined = undefined,
) => {
	const seenAge = new Set()
	return exams
		.filter(
			exam =>
				!isUndefined(exam.updatedAt) &&
				(type !== 'PH' || !examId || exam.examId === examId),
		)
		.map(exam => {
			return {
				examData: exam.examData as WithExamId<T>,
				age: getPatientAge(
					internalPatient,
					new Date(exam.updatedAt!),
				) as number,
				timestamp: exam.updatedAt!,
			}
		})
		.filter(ageExam => isBetween(ageExam.age, FROM_AGE, TO_AGE))
		.sort(
			(a, b) =>
				Date.parse(b.timestamp.toString()) - Date.parse(a.timestamp.toString()),
		)
		.filter(ageExam => {
			const duplicate = seenAge.has(ageExam.age)
			seenAge.add(ageExam.age)
			return !duplicate
		})
}

export const retrieveFinalRefractionValueAndAge = (
	side: Side,
	data: { age: number; examData?: PhoropterDataApi }[],
) => {
	return data.map(exam => {
		const finalRefractionSphere = (
			exam.examData?.finalRx?.day.default[side]?.sphere ?? ''
		).toString()
		const finalRefractionCylinder = (
			exam.examData?.finalRx?.day.default[side]?.cylinder ?? ''
		).toString()

		return {
			age: exam.age,
			value: calculateSphereEqFromSphAndCyl(
				finalRefractionSphere,
				finalRefractionCylinder,
			),
		}
	})
}

export const retrieveAxialLengthValueAndAge = (
	side: Side,
	data: { age: number; examData: BiometerDataApi }[],
) => {
	return data.map(exam => {
		const axialLenghtValue = exam.examData[side]?.axialLength

		return {
			age: exam.age,
			value: axialLenghtValue,
		}
	})
}
