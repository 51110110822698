import { FC } from 'react'
import { TitleWrapper } from '../CommonComponents'
import { TrendsOverTimeGraphCardContainer, TitleStyled } from './styles'
import { LegendType } from './TOTGraphLegend'

const TrendsOverTimeGraphCard: FC<{
	title?: string
	titleSuffix?: string
	$borderBotRight?: boolean
	$borderBottom?: boolean
	$borderRight?: boolean
	$zoom?: boolean
}> = ({
	$borderBotRight,
	$borderRight,
	$borderBottom,
	$zoom,
	title,
	titleSuffix,
	children,
}) => {
		return (
			<TrendsOverTimeGraphCardContainer
				$borderBotRight={$borderBotRight}
				$borderRight={$borderRight}
				$borderBottom={$borderBottom}
				$zoom={$zoom}
			>
				{title && (
					<TitleWrapper>
						<TitleStyled>
							{title}
							{!!titleSuffix && (
								<LegendType as="span">
									{'\xa0(' + titleSuffix + ')'}
								</LegendType>
							)}
							<span> { }</span>
						</TitleStyled>
					</TitleWrapper>
				)}
				{children}
			</TrendsOverTimeGraphCardContainer>
		)
	}

export default TrendsOverTimeGraphCard
