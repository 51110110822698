import { format } from 'date-fns'
import * as Locales from 'date-fns/locale'

export const getYear = (time: string | number | Date): string =>
	new Date(time).getFullYear().toString()

export const formatDate = (
	time: Date | string | number,
	dateFormat: string,
	localeCode: string,
): string => {
	const date = new Date(time)
	const locale = (Locales as any)[localeCode.replace('-', '')]
	return format(date, dateFormat, { locale })
}

export const isValidDateFormat = (
	formatStr: string | undefined | null,
): boolean => {
	if (!formatStr) {
		return false
	}
	let err = false
	try {
		format(new Date(), formatStr)
	} catch {
		err = true
	}
	return !err
}
