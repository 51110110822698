import React from 'react'
import { pxToRem } from '../../libs/style'

import { SvgProps } from '../../model/model'

const DropsIcon: React.FC<SvgProps> = props => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			height={pxToRem(28) + 'rem'}
			width={pxToRem(28) + 'rem'}
			viewBox="0 0 28 28"
			fill="currentColor"
			{...props}
		>
			<path d="M12.4,6.9C12.1,7.2,6,15.4,6,20.5c0,3.7,3,6.8,6.8,6.8s6.8-3,6.8-6.8c0-5.1-6.1-13.2-6.4-13.6C13,6.6,12.5,6.6,12.4,6.9z
				M12.8,26.2c-3.2,0-5.8-2.6-5.8-5.8c0-4,4.4-10.5,5.8-12.4c1.4,1.9,5.8,8.4,5.8,12.4C18.5,23.6,15.9,26.2,12.8,26.2z"/>
			<path d="M21.8,8.3c-0.2-0.3-0.6-0.3-0.8,0c-0.1,0.1-1.5,2-2.9,4.5c-0.1,0.2,0,0.5,0.2,0.7c0.2,0.1,0.5,0,0.7-0.2
				c0.9-1.6,1.9-3.1,2.4-3.8c1.2,1.7,4.6,6.8,4.6,10C26,22,24,24,21.4,24c-0.3,0-0.6,0-0.9-0.1c-0.3-0.1-0.5,0.1-0.6,0.4
				c-0.1,0.3,0.1,0.5,0.4,0.6C20.7,25,21,25,21.4,25c3.1,0,5.6-2.5,5.6-5.6C27,15.3,22,8.6,21.8,8.3z"/>
		</svg>
	)
}

export default DropsIcon
