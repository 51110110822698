import { isUndefined } from 'lodash'
import {
	Point,
	Line as LineInterface,
	CalculatedSphereEqData,
	SphereEqGraphData,
} from '../interfaces'
import { range, findValueByAgeAndKey } from '../utils'

export const getMinMaxAxis = (
	values: Partial<Point>[],
	standard: Point[],
	axis: 'x' | 'y',
) => {
	const equivalentSphereValue = values.map(point => point[axis])
	const standardValue = standard
		.map(point => point[axis])
		.filter(value => !isUndefined(value))

	const allValues = [...equivalentSphereValue, ...standardValue] as number[]

	const min = Math.floor(Math.min(...allValues))
	const max = Math.ceil(Math.max(...allValues))

	return {
		min,
		max,
	}
}

export const getSphereEqMinMax = (values: Point[]) => {
	const sphereEqValues = values.map(point => point.y)

	const sphereEqMin = Math.floor(Math.min(...sphereEqValues))
	const sphereEqMax = Math.ceil(Math.max(...sphereEqValues))

	return {
		min: sphereEqMin,
		max: sphereEqMax,
	}
}

export const setMyopiaY = (zones: LineInterface[], minY: number) => {
	const minZones = zones.find(zone => zone.name === 'min')?.data as Point[]
	const myopiaPresentZones = zones.find(zone => zone.name === 'myopiaPresent')
		?.data as Point[]
	const maxMyopa = myopiaPresentZones[0].y
	const minMyopia = minZones[0].y > minY ? minZones[0].y : minY

	return {
		min: minMyopia,
		max: maxMyopa,
	}
}

export const setPreMyopiaY = (zones: LineInterface[]) => {
	const preMyopiaPossibleZones = zones.find(
		zone => zone.name === 'preMyopiaPossible',
	)?.data as Point[]
	const myopiaPresentZones = zones.find(zone => zone.name === 'myopiaPresent')
		?.data as Point[]

	return {
		max: preMyopiaPossibleZones[0].y,
		min: myopiaPresentZones[0].y,
	}
}

export const createChartDataFromSphereEquivalent = (
	dataToBuildGraph: CalculatedSphereEqData,
): SphereEqGraphData[] => {
	const values = dataToBuildGraph.values
	const minMaxAge = getMinMaxAxis(values, dataToBuildGraph.standard, 'x')

	const agesFromMinAgeToMaxAge = range(
		minMaxAge.max - minMaxAge.min + 1,
		minMaxAge.min,
	)
	const standards = dataToBuildGraph.standard

	const graphData = agesFromMinAgeToMaxAge.reduce<SphereEqGraphData[]>(
		(array, age) => {
			array.push({
				age,
				value: findValueByAgeAndKey(values, age, 'y'),
				standard: findValueByAgeAndKey(standards, age, 'y'),
				horizontalZero: 0,
			})
			return array
		},
		[],
	)

	return graphData
}

export const calculateSphereEqFromSphAndCyl = (
	sph: string,
	cyl: string,
): number => {
	const sphereNum = parseFloat(sph)

	if (Number.isNaN(sphereNum)) {
		return NaN
	}

	const cylinderNum = parseFloat(cyl)

	if (Number.isNaN(cylinderNum)) {
		return sphereNum
	}

	return sphereNum + 0.5 * cylinderNum
}
