import { ThemeProvider } from '@material-ui/core'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import ErrorBoundary from './ErrorBoundary'
import NotificationArea from './features/notifications/NotificationArea'
import './i18n'
import reportWebVitals from './reportWebVitals'
import store from './store'
import CircularProgress from './styleguide/CircularProgress'
import GlobalStyle from './styleguide/globalStyle'
import theme from './styleguide/theme'

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<GlobalStyle />
				<Suspense fallback={<CircularProgress />}>
					<ErrorBoundary>
						<App />
						<NotificationArea />
					</ErrorBoundary>
				</Suspense>
			</ThemeProvider>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
