import cursorActions from '../../features/cursor/actions'
import { CursorPosition, Id } from '../../model/model'
import { TeloDispatch } from '../../store'
import {
	connectToSocketScreenShare,
	disconnectFromSocketScreenShare,
	getScreenshareSender,
} from './screenshareSocket'
import { SetZoomIndexFn, ShowCursorFn, ZoomPosition } from './screenshareTypes'

export const connectoToScreenshare =
	(examId: Id) => (dispatch: TeloDispatch) => {
		const setZoomIndex: SetZoomIndexFn = ({ body }) => {
			const zoomPosition: ZoomPosition = JSON.parse(body)
			dispatch(cursorActions.setZoomIndex(zoomPosition))
		}

		const showCursor: ShowCursorFn = ({ body }) => {
			const position: CursorPosition = JSON.parse(body)
			dispatch(cursorActions.setCursorPosition(position))
			dispatch(cursorActions.showCursor())
		}

		connectToSocketScreenShare({
			examId,
			setZoomIndex,
			showCursor,
		})
	}

export const disconnectFromScreenshare = (examId: string) => () => {
	disconnectFromSocketScreenShare(examId)
}

export const sendScreenshot = () => () => {
	const sender = getScreenshareSender()
	sender?.sendScreenshot()
}
