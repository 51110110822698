import { AppThunk, CursorPosition } from '../../model/model'
import { slice } from './slice'

const showCursor = (): AppThunk => dispatch => {
	dispatch(slice.actions._showCursor())
}

const setCursorPosition = (position: CursorPosition): AppThunk => dispatch => {
	dispatch(slice.actions._setKey())
	dispatch(slice.actions._setPosition(position))
}

const setZoomIndex = ({ x, y, zoomIndex }: { x: number, y: number, zoomIndex: number }): AppThunk => dispatch => {
	dispatch(slice.actions._setZoomPosition({ x: x, y: y }))
	dispatch(slice.actions._setZoomIndex(zoomIndex))
}

const cursorActions = {
	...slice.actions,
	showCursor,
	setCursorPosition,
	setZoomIndex,
}

export default cursorActions
