import { io, Socket } from 'socket.io-client'
import appConfig from '../../config'
import { SocketMessagesMap } from '../../model/socket'
import { connectTeloSocket, isSocketConnected } from '../teloSocketRegistry'
import {
	AddNotificationFn,
	ExamIdData,
	FindNotificationFn,
	RemoveNotificationFn,
	TeloSocket,
} from '../teloSocketTypes'
import { onConnect, onConnectError } from './roomSocketConnectionMessages'
import { onExamMessage, onInstrumentInRoomMessage } from './roomSocketListeners'
import {
	CheckExamInRoomFn,
	ClearDisplayContentFn,
	FetchInstrumentDataFn,
	InstrumentInRoomMessageData,
	LoopTimesData,
	SetContentFn,
	SetDisplayContentFn,
	SetDisplayFullscreenFn,
	SetExamDataFn,
	SetLoopTimesFn,
	SetPlayingFn,
	StartSharingDataArgs,
	StartSharingSectionData,
	UpdateDataArgs,
	VideoData,
} from './roomSocketTypes'

export type RoomSocketArgs = {
	roomName: string
	storeId?: string
	examId?: string
	addNotification: AddNotificationFn
	findNotification: FindNotificationFn
	removeNotification: RemoveNotificationFn
	setExamData: SetExamDataFn
	checkExamInRoom: CheckExamInRoomFn
	setContent: SetContentFn
	fetchInstrumentData: FetchInstrumentDataFn
	setDisplayFullscreen: SetDisplayFullscreenFn
	setLoopTimes: SetLoopTimesFn
	setPlaying: SetPlayingFn
	setDisplayContent: SetDisplayContentFn
	clearDisplayContent: ClearDisplayContentFn
}

const newSocket = (args: RoomSocketArgs): TeloSocket => {
	let socket: Socket | null = null
	const {
		roomName,
		addNotification,
		findNotification,
		removeNotification,
		checkExamInRoom,
		examId,
		setExamData,
		storeId,
		setContent,
		fetchInstrumentData,
		setDisplayFullscreen,
		setLoopTimes,
		setPlaying,
		setDisplayContent,
		clearDisplayContent,
	} = args

	const validateRooms = (messagesMap: SocketMessagesMap): void => {
		const roomNamesRegExpValidation = Object.values(messagesMap).map(
			({ roomNameRegExpValidation }) => roomNameRegExpValidation,
		)

		if (
			!roomNamesRegExpValidation.some(roomNameRegExpValidation =>
				new RegExp(roomNameRegExpValidation).test(roomName),
			)
		) {
			throw new Error(`bad room name ${roomName}`)
		}
	}

	const onSocketMessagesMap = (messagesMap: SocketMessagesMap): void => {
		if (!isSocketConnected(socket)) {
			return
		}
		validateRooms(messagesMap)

		const { exam, instrumentInRoom } = messagesMap

		socket!.on(exam.msgToFe, (data: ExamIdData) => {
			onExamMessage({ examId: data.examId, setExamData })
		})

		socket!.on(
			instrumentInRoom.msgToFe,
			(data: InstrumentInRoomMessageData) => {
				onInstrumentInRoomMessage({
					checkExamInRoom,
					setExamData,
					examId: data.examId,
					storeId: data.storeId,
				})
			},
		)
	}

	const connect = () => {
		if (isSocketConnected(socket)) {
			return
		}

		socket = io(appConfig.socketUrl, {
			query: { roomName, isCockpit: 'true' },
			transports: ['websocket'],
		})

		socket.on('connect_error', () => {
			if (isSocketConnected(socket)) {
				onConnectError({
					addNotification,
					findNotification,
					isSocketConnected: () => isSocketConnected(socket),
				})
			}
		})

		socket.on('connect', () => {
			if (isSocketConnected(socket)) {
				onConnect(removeNotification)
			}
		})
		socket.once('socket-messages-map', (messagesMap: SocketMessagesMap) => {
			onSocketMessagesMap(messagesMap)
		})
		socket.on('additional-data', ({ body }) => {
			setContent(body)
		})
		socket.on('update-data', (args: UpdateDataArgs) => {
			if (examId) {
				fetchInstrumentData(examId, args?.body)
			}
		})
		socket.on('open-fullscreen', () => {
			setDisplayFullscreen(true)
		})
		socket.on('exit-fullscreen', () => {
			setDisplayFullscreen(false)
		})
		socket.on('loop-times', (args: LoopTimesData) => {
			const startTime = args?.body?.startTime
			const endTime = args?.body?.endTime
			setLoopTimes({ startTime, endTime })
		})
		socket.on('pause-video', (args: VideoData) => {
			const currentTime = args?.body?.currentTime
			const isFullscreen = args?.body?.isFullscreen
			setPlaying({ playing: false, currentTime, isFullscreen })
		})
		socket.on('play-video', (args: VideoData) => {
			const currentTime = args?.body?.currentTime
			const isFullscreen = args?.body?.isFullscreen
			setPlaying({ playing: true, currentTime, isFullscreen })
		})
		socket.on('start-sharing-data', (payload: StartSharingDataArgs) => {
			const data = payload?.body
			setDisplayContent({ type: data?.section, data })
		})
		socket.on('stop-sharing-data', () => {
			clearDisplayContent()
		})
		socket.on('start-sharing-section', (payload: StartSharingSectionData) => {
			setDisplayContent({
				type: payload?.body?.name,
				data: undefined,
			})
		})
		socket.on('stop-sharing-section', () => {
			clearDisplayContent()
		})
		socket.on('store-updated', () => {
			checkExamInRoom(storeId)
		})
	}

	const disconnect = () => {
		if (!isSocketConnected(socket)) {
			return
		}
		socket?.disconnect()
		socket?.close()
		socket = null
		clearDisplayContent()
	}

	return { connect, disconnect }
}

export function connectToSocketRoom(args: RoomSocketArgs): void {
	connectTeloSocket({
		socketKey: args.roomName,
		newSocket: () => newSocket(args),
	})
}
