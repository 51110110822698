import React from 'react'
import { Grid } from '@material-ui/core'
import { GraphCardWrapper } from '../graph.style'
import { CalculatedSphereEqData, Scale } from '../interfaces'
import { SphereEqGraph } from '.'
import SphereEquivalentLegend from './SphereEquivalentLegend'

type Props = {
  scaleY: Scale | undefined
  calculatedSphereEquivalentData: CalculatedSphereEqData
  zoomGraph?: boolean
}

const SphereEquivalentGraphMono: React.FC<Props> = ({
  scaleY,
  calculatedSphereEquivalentData,
  zoomGraph = false,
}) => {
  const renderGraph = (
    <GraphCardWrapper border={false} $zoomgraph={zoomGraph}>
      <SphereEqGraph
        scaleY={scaleY}
        calculatedValues={calculatedSphereEquivalentData}
      />
    </GraphCardWrapper>
  )

  const renderLegend = <SphereEquivalentLegend />

  return !zoomGraph ? (
    <>
      <Grid item xs={12}>
        {renderGraph}
      </Grid>
      <Grid item xs={12}>
        {renderLegend}
      </Grid>
    </>
  ) : (
    <>
      {renderGraph}
      {renderLegend}
    </>
  )
}

export default SphereEquivalentGraphMono
